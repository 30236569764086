import { PlacementOptionDto, ShippingPartner } from "@deliverr/replenishment-client";
import { getTransportationOptionsData } from "../../commons/FbaV3Selectors";
import {
  resetSelectedTransportationConfiguration,
  setSelectedTransportationConfiguration,
} from "../../transportationOptions/actions/TransportationOptionActions";
import { isEmpty, map } from "lodash";
import { Dispatch } from "react";
import { RootState } from "RootReducer";
import { batch } from "react-redux";

export const onPlacementOptionsLoadSuccess = (
  placementOptions: PlacementOptionDto[],
  dispatch: Dispatch<unknown>,
  getState: () => RootState
) => {
  const { selectedTransportationConfigurations, transportationEstimates } = getTransportationOptionsData(getState());

  for (const placementOption of placementOptions) {
    if (isEmpty(transportationEstimates[placementOption.id])) {
      batch(() => {
        dispatch(resetSelectedTransportationConfiguration(placementOption.id, ShippingPartner.FBA));
        dispatch(resetSelectedTransportationConfiguration(placementOption.id, ShippingPartner.DELIVERR));
      });
    }

    if (isEmpty(selectedTransportationConfigurations[placementOption.id])) {
      map(placementOption?.shipmentList?.temporaryShipments, (temporaryShipment) => {
        dispatch(
          setSelectedTransportationConfiguration(placementOption.id, temporaryShipment.id, {
            shippingPartner: ShippingPartner.DELIVERR,
          })
        );
      });
    }
  }
};
