import React, { useState } from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { Button, defaultTheme, Icon, Modal, Text, ThemeProps } from "common/components/ui";
import { KITTING_COST_TABLE_LABELS } from "../PrepCostTable/KittingCostTabel.label";
import { CostTable } from "common/components/CostTable";

const ModalContent = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.S5};
  `
);

const TitleContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.S4};
`
);

const SeePriceSummary = styled.div<ThemeProps>(
  ({ theme }) => `
    cursor: pointer;
    appearance: none;
    border: 0;
    background: none;
    color: ${theme.colors.BLUE["300"]};
  `
);

const CloseButton = styled.button<ThemeProps>(
  ({ theme }) => `
    cursor: pointer;
    appearance: none;
    border: 0;
    background: none;
    color: ${theme.colors.BLUE["300"]};
  `
);

const ButtonContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  `
);

export const KittingPriceSummaryModal = ({ costTableProps }: { costTableProps }) => {
  const [isSummaryModalDisplayed, setIsSummaryModalDisplayed] = useState<boolean>(false);
  const toggleSummaryModal = () => {
    setIsSummaryModalDisplayed(!isSummaryModalDisplayed);
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleSummaryModal();
  };

  return (
    <SeePriceSummary onClick={handleButtonClick}>
      <Text bold>
        <FormattedMessage {...KITTING_COST_TABLE_LABELS.modalButtonText} />
      </Text>
      <Modal
        show={isSummaryModalDisplayed}
        width="50rem"
        borderRadius={defaultTheme.border.radius.R5}
        hasCloseButton={false}
      >
        <ModalContent>
          <TitleContainer>
            <CloseButton onClick={toggleSummaryModal} aria-label="Close">
              <Icon type="times" className="fa-w-16" />
            </CloseButton>
            <Text bold size="bodyLarge">
              <FormattedMessage {...KITTING_COST_TABLE_LABELS.modalTitle} />
            </Text>
          </TitleContainer>
          <CostTable {...costTableProps} />
          <ButtonContainer>
            <Button onClick={toggleSummaryModal}>
              <FormattedMessage {...KITTING_COST_TABLE_LABELS.modalDoneButtonText} />
            </Button>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </SeePriceSummary>
  );
};
