import { Product } from "@deliverr/commons-clients";
import {
  Asn,
  InboundPackage,
  InboundShipment,
  ReceivingInfo,
  ShippingPlan,
  ShippingPlanItem,
  CasePackDefault,
} from "@deliverr/legacy-inbound-client";
import { asyncHash } from "@deliverr/commons-utils/lib/AsyncUtils";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { InboundShipmentReceiptEta } from "common/clients/inboundEstimation/InboundShipmentReceiptEta";
import { Dictionary, uniq } from "lodash";
import { compact, flatten, fromPairs, groupBy, isEmpty, keyBy, pickBy } from "lodash/fp";
import { batch } from "react-redux";
import { toast } from "common/components/ui";
import history from "BrowserHistory";
import { inboundClient, productClient } from "Clients";
import { inboundEstimationClient } from "common/clients/instances";
import { withLoader } from "common/components/WithLoader/LoadingActions";
import { skipRedirect } from "common/Config";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import { notifyUserOfError } from "common/ErrorToast";
import { SPThunkAction } from "common/ReduxUtils";
import { refreshUser } from "common/user/UserActions";
import log, { logStart } from "Logger";
import { Path } from "paths";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { InboundStep, PlannedShipment } from "inbounds/InboundTypes";
import {
  isCompletedShipmentStatus,
  isConfirmedShipmentStatus,
  isNonCompliantWithPackages,
  SHIPMENT_STATUSES_WITH_PACKAGE_DETAILS,
} from "inbounds/ShipmentStatus";
import {
  createExistingDraftShipment,
  createNewDraftShipment,
  updateSavedDraftShipment,
} from "inbounds/steps/CreateDraftShipment";
import {
  getLocalInbound,
  getLocalPlannedPackages,
  getLocalPlannedShipment,
  SavedInbound,
} from "inbounds/steps/InboundLocalStorage";
import {
  getInboundWarehouseIds,
  isShipToOneDispersalMethod,
  isShipToOnePlan,
  separateAsnsByCrossDock,
} from "inbounds/steps/ship/InboundUtils";
import { fetchBoxLabelUrls } from "inbounds/store/actions/boxLabels/fetchBoxLabelUrls";
import { fetchFreightTrackingInfo } from "inbounds/store/actions/freight/fetchFreightTrackingInfo";
import { loadCrossdockInboundObjects } from "../crossdock/store/actions";
import { loadLtlState } from "inbounds/store/actions/ltl/loadLtlState";
import { initLtlById } from "inbounds/store/util/initLtlById";
import { parseFreightDeliverrShipmentIds } from "inbounds/utils/parseFreightDeliverrShipmentIds";
import { raceTimeout } from "common/PromiseUtils";
import { goToInboundStep } from "inbounds/store/actions/navigation/goToInboundStep";
import { getIsFreightExternal } from "inbounds/utils/shippingMethodUtils";
import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { fetchShippingPlan } from "inbounds/store/actions/shippingPlan/fetchShippingPlan";
import { loadHasInactiveLabels } from "../store/actions/shippingPlan/loadHasInactiveLabels";
import { getNormalizedShippingPlanItems } from "inbounds/utils/shippingPlan";
import { loadPrepByShippingPlanId } from "prep/store";
import { goToCreateInbound } from "inbounds/createShipment/store/actions";
import { setDomesticEcommIPBState } from "inbounds/createShipment/store/actions/setDomesticEcommIPBState";

export const CLEAR_INBOUND = "CLEAR_INBOUND";
export const LOAD_INBOUND = "LOAD_INBOUND";

const flattenAndGroupByShipmentId = <T>(items: T[][]) => groupBy("shipmentId", flatten(items));

const savedInboundIsOutOfDate = (
  { step, fromAddress }: SavedInbound,
  shipments: InboundShipment[],
  packagesByShipmentId: Dictionary<InboundPackage[]>
): boolean => {
  const hasPackages = !Object.values(packagesByShipmentId).every(isEmpty);
  const hasShipments = !isEmpty(shipments);
  const hasNotReachedBarcodeInputStep = hasShipments && step === InboundStep.BARCODE_INPUT;
  const hasNotReachedFromAddressStep =
    hasShipments && !hasPackages && !fromAddress && step !== InboundStep.FROM_ADDRESS;
  const hasNotReachedShipStep = hasPackages && step !== InboundStep.SHIP;

  return hasNotReachedBarcodeInputStep || hasNotReachedFromAddressStep || hasNotReachedShipStep;
};

export const loadAsns = async (
  shipments: InboundShipment[],
  isForwarding?: boolean
): Promise<{ asnsByShipmentId: Dictionary<Asn[]>; crossDockAsn?: Asn }> => {
  const ctx = { fn: "loadAsns" };
  if (shipments.length === 0) {
    return { asnsByShipmentId: {} };
  }

  const crossDockShipment = shipments.find(({ warehouseId, crossDockWarehouseId }) =>
    Boolean(crossDockWarehouseId && warehouseId === crossDockWarehouseId)
  );
  const asns = await Promise.all(
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    shipments.map(({ id }) => inboundClient.getAsnsByShipmentId(shipments[0].sellerId, id))
  );
  const asnsByShipmentId = flattenAndGroupByShipmentId(asns);

  const { sellerId, shippingPlanId } = shipments[0];
  let crossDockAsn: Asn | undefined;
  try {
    if (isForwarding) {
      crossDockAsn = await inboundClient.getCrossDockAsn(sellerId, shippingPlanId);
    }
  } catch (err) {
    log.info(ctx, "no cd asn found");
  }

  if (crossDockShipment) {
    const { normalAsns } = separateAsnsByCrossDock(asnsByShipmentId[crossDockShipment.id]);

    const updatedAsnsByShipmentId = {
      ...asnsByShipmentId,
      [crossDockShipment.id]: normalAsns,
    };

    try {
      log.info({ ...ctx, crossDockAsn }, "retrieved crossDockAsn");
      return {
        asnsByShipmentId: updatedAsnsByShipmentId,
        crossDockAsn,
      };
    } catch (err) {
      log.info({ ...ctx, shippingPlanId, err }, "cross dock asn not found");
      return { asnsByShipmentId: updatedAsnsByShipmentId };
    }
  } else {
    return { asnsByShipmentId, crossDockAsn };
  }
};

export const loadReceivingInfo = async (shipments: InboundShipment[]): Promise<Dictionary<ReceivingInfo>> => {
  const hasReceivingInfo = (shipment: InboundShipment) =>
    isConfirmedShipmentStatus(shipment.status) || isNonCompliantWithPackages(shipment);

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const getShipmentReceivingInfo = ({ sellerId, id: shipmentId }) =>
    inboundClient.getReceivingInfo(sellerId, shipmentId.toString());

  const receivingInfo = await Promise.all(shipments.filter(hasReceivingInfo).map(getShipmentReceivingInfo));

  return keyBy("shipmentId", compact(receivingInfo));
};

export function throwIfCompleteShipmentMissingBarcodes(
  shipments: InboundShipment[],
  productDetailsCache: Dictionary<Product>
): void {
  if (!shipments.some(({ status }) => isCompletedShipmentStatus(status))) {
    return;
  }

  const productsWithoutBarcodes = Object.values(productDetailsCache)
    .filter(({ barcodes }) => !barcodes || isEmpty(barcodes))
    .map(({ dsku }) => dsku);

  if (!isEmpty(productsWithoutBarcodes)) {
    log.error({ fn: "loadShipmentDetails", productsWithoutBarcodes }, "complete shipment missing barcodes");
    throw new Error("MISSING_PRODUCT_BARCODES");
  }
}

export const loadCasePackDefaults = async (
  items: ShippingPlanItem[]
): Promise<{ [dsku: string]: Partial<CasePackDefault> }> => {
  const ctx = logStart({ fn: "loadCasePackDefaults", items });

  const hasCasePacks = items.some((item) => item.caseQty > 1);

  if (hasCasePacks) {
    let allDefaults: { [dsku: string]: Partial<CasePackDefault> } = {};

    try {
      allDefaults = await inboundClient.getCasePackDefaults(items.map(({ dsku }) => dsku));
    } catch (err) {
      log.warn({ ...ctx, err }, "couldn't fetch case pack info");
    }

    const matchingDefaults = pickBy(
      (caseDefault) =>
        items.find((item) => item.dsku === caseDefault.dsku && item.caseQty === caseDefault.unitsPerCase),
      allDefaults
    );

    const partialCasePackInfo = fromPairs(
      items
        .filter((item) => !matchingDefaults[item.dsku])
        .map((item) => [item.dsku, { unitsPerCase: item.caseQty, width: 0, height: 0, length: 0, weight: 0 }])
    );

    log.info({ ...ctx, matchingDefaults, allDefaults }, "got case pack defaults back");
    return { ...matchingDefaults, ...partialCasePackInfo };
  }

  return {};
};

export const loadShipmentDetails = async (plan: ShippingPlan) => {
  const ctx = logStart({ fn: "loadShipmentDetails", plan });
  try {
    const unfilteredPlanDskus = plan.items.map(({ dsku }) => dsku);
    const shipments = await inboundClient.getShipments(plan.sellerId, plan.id, true);
    const { asnsByShipmentId, crossDockAsn } = await loadAsns(shipments, isShipToOnePlan(plan));
    const shipmentToReceivingInfo = !isShipToOnePlan(plan) || crossDockAsn ? await loadReceivingInfo(shipments) : {};
    shipments.forEach(
      ({ id }) =>
        shipmentToReceivingInfo[id]?.items.forEach(({ dsku }) => {
          unfilteredPlanDskus.push(dsku);
        })
    );

    //
    // only check for crossdock plans, we will want to extend this in the future
    // I added a 10s timeout to this, it was originally 2s but the warm up time
    //  on the BE service has been around 3-4 seconds.  This check is only in there
    //  for exceptional catatonic cases.
    //
    let shipmentEtaDetails: InboundShipmentReceiptEta | undefined;
    try {
      if (isShipToOneDispersalMethod(plan.dispersalMethod) && shipments.length >= 1) {
        const { eta } = await raceTimeout(
          asyncHash({
            eta: inboundEstimationClient.getShipmentEta(shipments[0].id),
          }),
          10000,
          { eta: undefined }
        );
        shipmentEtaDetails = eta;
      }
    } catch (e) {
      log.info({ ...ctx, shipments, e }, "error getting receipt estimated dates");
    }

    const planDskus = uniq(unfilteredPlanDskus);

    const productDetailsCache = await productClient.getUnifiedProducts(planDskus, {
      includeCustomsInformation: true,
      includeHazmatInformation: true,
      includeProductPreparation: true,
      includeKitComponents: true,
    });

    log.info({ ...ctx, shipments, productDetailsCache }, "retrieved shipments and products");

    const productsWithNoData = planDskus.filter((dsku) => productDetailsCache[dsku] === undefined);

    // check for instances where plan item no longer exists in product table
    if (productsWithNoData.length > 0) {
      log.error({ ...ctx, productsWithNoData }, "missing plan dskus");
      throw new Error("MISSING_PLAN_DSKU_FROM_PRODUCT_DATA");
    }

    throwIfCompleteShipmentMissingBarcodes(shipments, productDetailsCache);

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const packages = await Promise.all(shipments.map(({ id }) => inboundClient.getActivePackages(plan.sellerId, id)));
    const packagesByShipmentId = flattenAndGroupByShipmentId(packages);

    const flatPackages = flatten(packages);
    const hasPackages = flatPackages.length > 0;

    log.info({ ...ctx, packages, asnsByShipmentId, crossDockAsn }, "retrieved packages and asns");

    return {
      productDetailsCache,
      shipments,
      packagesByShipmentId,
      asnsByShipmentId,
      crossDockAsn,
      packageFromAddress: hasPackages ? flatPackages[0].fromAddress : undefined,
      shipmentToReceivingInfo,
      shipmentEtaDetails,
    };
  } catch (err) {
    log.error({ ...ctx, err }, "error retrieving shipments or products");
    notifyUserOfError({ err, toastId: "retrieveShipmentsOrProductsError" });
    history.push(Path.inboundsList);
    return;
  }
};

const createPlannedShipment = (
  packagesByShipmentId: Dictionary<InboundPackage[]>,
  shipment: InboundShipment,
  casePackDefaults: { [dsku: string]: Partial<CasePackDefault> },
  plan: ShippingPlan,
  crossdockInboundQuote?: CrossdockInboundQuote
): PlannedShipment => {
  const packages = packagesByShipmentId[shipment.id] ?? [];
  return SHIPMENT_STATUSES_WITH_PACKAGE_DETAILS.includes(shipment.status)
    ? createExistingDraftShipment(shipment, packages, plan, crossdockInboundQuote)
    : createNewDraftShipment(shipment, casePackDefaults);
};

// prevents loading inactive shipment
const getLoadedShipmentId = (shipments: InboundShipment[], shipmentId?: number): number | undefined => {
  const isOldShipmentId = shipmentId && !shipments.some(({ id }) => id === shipmentId);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return isOldShipmentId || (shipments.length > 0 && !shipmentId) ? shipments[0].id : shipmentId;
};

const getInboundStep = (hasShipments: boolean, fromAddress?: DeliverrAddress): InboundStep => {
  if (!skipRedirect() && !hasShipments) {
    return InboundStep.SHIPPING_PLAN_PRODUCTS;
  }
  return fromAddress ? InboundStep.SHIP : InboundStep.FROM_ADDRESS;
};

export const loadPackagingTypes =
  (shippingPlanId: number): SPThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      user: { sellerId },
    } = state;
    const plan = await fetchShippingPlan(sellerId, shippingPlanId);
    if (!plan) {
      return;
    }

    const shipmentDetails = await loadShipmentDetails(plan);
    if (!shipmentDetails) {
      return;
    }
    const { productDetailsCache } = shipmentDetails;
    const planItems = getNormalizedShippingPlanItems(plan);
    dispatch({
      type: LOAD_INBOUND,
      plan,
      planItems,
      productDetailsCache,
    });
    return;
  };

export const loadInboundWithoutLoader =
  (shippingPlanId: number, shipmentId?: number, replace?: boolean): SPThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      user: { sellerId },
    } = state;

    const ctx = { fn: "loadInbound", shippingPlanId, shipmentId };
    log.info(ctx, `loading shipping plan ${shippingPlanId}`);
    dispatch({ type: "CLEAR_INBOUND" });
    dispatch(refreshUser());

    // sometimes shippingPlanId not defined in algolia record, not sure why exactly
    if (!shippingPlanId) {
      history.push(Path.inboundsList);
      log.error(ctx, "attempted to load shipping plan when missing shipping plan id");
      toast.error("Oops, there was a problem loading this shipping plan. Please refresh the page and try again.", {
        autoClose: 5000,
        toastId: "loadShippingPlanError",
      });
      return;
    }

    const plan = await fetchShippingPlan(sellerId, shippingPlanId);
    if (!plan) {
      return;
    }

    const shipmentDetails = await loadShipmentDetails(plan);
    if (!shipmentDetails) {
      return;
    }

    dispatch(loadHasInactiveLabels(sellerId, shippingPlanId));

    const {
      productDetailsCache,
      shipments,
      packagesByShipmentId,
      asnsByShipmentId,
      crossDockAsn,
      packageFromAddress,
      shipmentToReceivingInfo,
      shipmentEtaDetails,
    } = shipmentDetails;

    const warehouseIds = getInboundWarehouseIds(shipments, packagesByShipmentId);
    await dispatch(loadWarehouses(warehouseIds.map((id) => id.toUpperCase())));

    const savedInbound = getLocalInbound(plan.id);
    const hasShipments = !isEmpty(shipments);

    const loadedShipmentId = getLoadedShipmentId(shipments, shipmentId);
    const casePackDefaults = await loadCasePackDefaults(plan.items);

    const fromAddress = plan.fromAddress ?? packageFromAddress;

    const baseAction = {
      type: LOAD_INBOUND,
      productDetailsCache,
      shipments,
      loadedShipmentId,
      packagesByShipmentId,
      asnsByShipmentId,
      crossDockAsn,
      fromAddress,
      casePackDefaults,
      shipmentToReceivingInfo,
      shipmentEtaDetails,
    };

    const step = getInboundStep(hasShipments, fromAddress);

    const planItems = getNormalizedShippingPlanItems(plan);

    const handleLoadedShipmentAndStep = async (plannedShipments: PlannedShipment[]) => {
      await batch(async () => {
        if (loadedShipmentId) {
          void dispatch(fetchBoxLabelUrls());

          // Only get freight tracking info for completed, freight shipments
          plannedShipments.forEach(({ id, shippingMethod }) => {
            const shipment = shipments.find((currentShipment) => currentShipment.id === id);
            if (getIsFreightExternal(shippingMethod) && shipment && isCompletedShipmentStatus(shipment.status)) {
              void dispatch(fetchFreightTrackingInfo(id, true));
            }
          });

          // Prep must be loaded after Shipments and needs to happen whether or not their is a saved (local) Inbound.
          await dispatch(loadPrepByShippingPlanId({ sellerId, shippingPlanId }));
        }
        if (step !== InboundStep.SHIP) {
          // set state for IPB steps
          dispatch(setDomesticEcommIPBState());

          dispatch(goToCreateInbound({ replace }));
        } else {
          dispatch(goToInboundStep(step, replace));
        }
      });
    };

    // Since Simple Prep selection is an early step, we must load the PrepRequest early on
    await dispatch(loadPrepByShippingPlanId({ sellerId, shippingPlanId }));

    // If it's a Ship to One ShippingPlan, load related objects into state
    let crossdockInboundQuote: CrossdockInboundQuote | undefined;
    if (isShipToOnePlan(plan)) {
      const loadedShipment = shipments.find(({ id }) => id === loadedShipmentId);
      crossdockInboundQuote = (await dispatch(loadCrossdockInboundObjects(sellerId, plan, loadedShipment)))
        .crossdockInboundQuote;
    }

    const { freightDeliverrShipmentIds, nonFreightDeliverrShipmentIds } = parseFreightDeliverrShipmentIds(shipments);
    const ltlState = {
      ...(await loadLtlState(freightDeliverrShipmentIds, sellerId)),
      ...initLtlById(nonFreightDeliverrShipmentIds),
    };

    if (!savedInbound || savedInboundIsOutOfDate(savedInbound, shipments, packagesByShipmentId)) {
      log.info({ ...ctx, savedInbound }, `no inbound saved, sending to ${step}`);

      const plannedShipments = shipments.map((shipment) =>
        createPlannedShipment(packagesByShipmentId, shipment, casePackDefaults, plan, crossdockInboundQuote)
      );

      dispatch({
        ...baseAction,
        plan,
        planItems,
        step,
        plannedShipments,
        ltl: ltlState,
      });
      await handleLoadedShipmentAndStep(plannedShipments);

      return;
    }

    const plannedShipments = hasShipments
      ? shipments.map((shipment) => {
          const localPlannedShipment = getLocalPlannedShipment(shipment.id);
          if (localPlannedShipment) {
            const { shippingOption } = shipment;
            return {
              ...updateSavedDraftShipment(
                localPlannedShipment,
                shipment.status,
                plan,
                shippingOption,
                crossdockInboundQuote
              ),
              hasDownloadedPackingList: false,
            };
          }
          return createPlannedShipment(packagesByShipmentId, shipment, casePackDefaults, plan, crossdockInboundQuote);
        })
      : [];

    const plannedPackages = getLocalPlannedPackages(plan.id);

    log.info({ ...ctx, savedInbound, hasShipments }, "found saved inbound");
    dispatch({
      ...baseAction,
      ...savedInbound,
      plan,
      planItems,
      plannedShipments,
      plannedPackages,
      ltl: ltlState,
    });
    await handleLoadedShipmentAndStep(plannedShipments);
  };

export const loadInbound = withLoader(InboundLoaderId.loadingInbound, loadInboundWithoutLoader);
