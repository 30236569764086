import { CheckableTileGroup } from "common/components/ui";
import React from "react";
import { usePackingOptions } from "./usePackingOptions";
import { Direction } from "common/components/ui/shared";
import { isEmpty } from "lodash";
import { FbaV3LoadingAndErrorDisplay } from "../commons/FbaV3LoadingAndErrorDisplay";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { ManagedStep } from "common/components/StepContainer";
import { PackingOptionsLabels } from "./PackingOptionsLabels";
import { PackingGroupsDetailsModal } from "./PackingGroupDetailsModal";
import { FbaV3OptionExpiryMessage } from "../commons/FbaV3OptionExpiryMessage";
import { FbaV3OptionsLearnMoreButton } from "../commons/FbaV3LearnMoreButton";

export const PackingOptions: React.FC = () => {
  const {
    packingOptionTiles,
    isNextDisabled,
    isBackDisabled,
    packingOptionsErrors,
    selectedPackingOptionId,
    arePackingOptionsLoading,
    isPackingOptionSelectDisabled,
    selectedModalPackingOption,
    hasRetryLimitReached,
    shouldShowExpiryNotification,
    onRetryAfterErrorClick,
    onModalCloseClick,
    handlePackingOptionChange,
    onRetryAfterRetryLimitReachedClick,
    formatMessage,
    isRetryOperationLoading,
  } = usePackingOptions();
  return (
    <ManagedStep
      data-testid="fbav3-create-transfer-packing-option-select"
      title={!isEmpty(packingOptionTiles) ? formatMessage(PackingOptionsLabels.packingOptionSelectTitle) : undefined}
      isNextDisabled={isNextDisabled}
      isBackDisabled={isBackDisabled}
    >
      <PackingGroupsDetailsModal onCloseClick={onModalCloseClick} packingOption={selectedModalPackingOption} />

      <FbaV3OptionExpiryMessage
        showExpiryNotification={shouldShowExpiryNotification}
        entityName={FbaV3EntityName.PACKING_OPTION}
      />

      <FbaV3LoadingAndErrorDisplay
        operationErrors={packingOptionsErrors}
        entityName={FbaV3EntityName.PACKING_OPTION}
        isOperationLoading={arePackingOptionsLoading}
        onRetryAfterErrorClick={onRetryAfterErrorClick}
        onRetryAfterRetryLimitReachedClick={onRetryAfterRetryLimitReachedClick}
        hasRetryLimitReached={hasRetryLimitReached}
        isRetryOperationLoading={isRetryOperationLoading}
      />

      {!isEmpty(packingOptionTiles) && (
        <>
          <FbaV3OptionsLearnMoreButton entityName={FbaV3EntityName.PACKING_OPTION} width={"80%"} />

          <CheckableTileGroup
            options={packingOptionTiles}
            type="radio"
            currentValues={selectedPackingOptionId ? [selectedPackingOptionId] : []}
            direction={Direction.VERTICAL}
            disabled={isPackingOptionSelectDisabled}
            onChange={handlePackingOptionChange}
            tileWidth="98%"
          />
        </>
      )}
    </ManagedStep>
  );
};
