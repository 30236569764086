import { defineMessages, useIntl } from "react-intl";
import {
  replenishmentOrderShipmentPartnerMap,
  replenishmentOrderShipmentTypeMap,
} from "../../common/replenishment/confirm/ReplenishmentOrderConfirmationMessages";
import { chain } from "lodash";
import { PackingOptionStatus, ShippingConfiguration } from "@deliverr/replenishment-client";

export const PackingOptionsLabels = defineMessages({
  packingOptionName: {
    id: "fbaV3.packingOptions.option.name",
    defaultMessage: "Option {index}",
  },
  packingOptionSelectTitle: {
    id: "fbaV3.packingOptions.select.title",
    defaultMessage: "Select a packing option",
  },
  viewPackingOptionDetails: {
    id: "fbaV3.packingOptions.view.details",
    defaultMessage: "View Details",
  },
  amazonPackingOptionIdDisplay: {
    id: "fbaV3.packingOptions.amazonPackingOptionId",
    defaultMessage: "FBA Packing Option ID: {packingOptionId}",
  },
  shippingConfigurationsDisplay: {
    id: "fbaV3.packingOptions.shippingConfigurationsDisplay",
    defaultMessage: "Supported Shipping: {shippingConfigurationsDisplayText}",
  },
  packingGroupModalTitle: {
    id: "fbaV3.packingOptions.modal.title",
    defaultMessage: "Packing Groups",
  },
  packingGroupTitle: {
    id: "fbaV3.packingOptions.packingGroup.title",
    defaultMessage: "Packing Group {index}",
  },
  packingGroupItemTitle: {
    id: "fbaV3.packingOptions.packingGroup.item.title",
    defaultMessage: "View Item Details",
  },
  packingGroupItemLoading: {
    id: "fbaV3.packingOptions.packingGroup.item.dsku",
    defaultMessage: "Loading item details...",
  },
  boxQuantity: {
    id: "fbaV3.packingOptions.packingGroup.boxQuantity",
    defaultMessage: `{caseCount, plural, one {# box} other {# boxes}}`,
  },
  unitQuantity: {
    id: "fbaV3.packingOptions.packingGroup.unitQuantity",
    defaultMessage: `{numOfUnits, plural, one {# unit} other {# units}}`,
  },
  statusLabels: defineMessages({
    [PackingOptionStatus.ACCEPTED]: {
      id: "fbaV3.packingOptions.statusPill.accepted",
      defaultMessage: "Confirmed",
    },
    [PackingOptionStatus.EXPIRED]: {
      id: "fbaV3.packingOptions.statusPill.expired",
      defaultMessage: "Expired",
    },
    [PackingOptionStatus.OFFERED]: {
      id: "fbaV3.packingOptions.statusPill.offered",
      defaultMessage: "Offered",
    },
  }),
  packingOptionConfirmationFailure: {
    id: "fbaV3.packingOptions.confirmation.failure",
    defaultMessage: "Failed to confirm packing option. Please try again",
  },
});

const getShippingConfigurationDescription = (
  shippingConfiguration: ShippingConfiguration,
  formatMessage: ReturnType<typeof useIntl>["formatMessage"]
): string =>
  formatMessage(replenishmentOrderShipmentPartnerMap[shippingConfiguration.shippingPartner]) +
  " " +
  formatMessage(replenishmentOrderShipmentTypeMap[shippingConfiguration.shippingType]);

export const getSupportedShippingConfigurationsDescription = (
  shippingConfigurations: ShippingConfiguration[],
  formatMessage: ReturnType<typeof useIntl>["formatMessage"]
): string => {
  return chain(shippingConfigurations)
    .map((shippingConfiguration) => getShippingConfigurationDescription(shippingConfiguration, formatMessage))
    .uniq()
    .join(", ")
    .value();
};
