import { User } from "../User/User";
import { OnboardingStage } from "./OnboardingStage";

export enum OrganizationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}
export interface Organization {
  readonly sellerId: string;
  readonly createdBy: string;
  readonly createdAt: string;
  readonly isCatalogImported?: boolean;
  readonly onboardingStage?: OnboardingStage;
  readonly dealStage?: string;
  readonly tags?: string;
  readonly isArchived?: boolean;
  readonly hasChargebeeAccount?: boolean;
  readonly showChecklist?: boolean;
  readonly businessName?: string;
  readonly publicEmail?: string;
  readonly logisticsAccountId?: string;
  readonly shopDomain?: string;
  readonly isSfnSupportAccount: boolean;
  readonly isTestAccount?: boolean;
  readonly status: OrganizationStatus;
  users?: User[];
}
