export enum CreateTransferLoader {
  CreateTransferOrderLoader = "CreateTransferOrderLoader",
  UpdateTransferOrderLoader = "UpdateTransferOrderLoader",
  FetchStorageInventoryLoader = "FetchStorageInventoryLoader",
  FetchStorageToEcomRatesLoader = "FetchStorageToEcomRatesLoader",
  FetchStorageProductsCaseInfoLoader = "FetchStorageProductsCaseInfoLoader",
  FetchWholesaleDraftOrderLoader = "FetchWholesaleDraftOrderLoader",
  FetchEstimatesLoader = "FetchEstimatesLoader",
  NextStepLoader = "NextStepLoader",
}
