import React from "react";
import { useIntl } from "react-intl";
import { useWholesaleConfirmCostsColumns } from "wholesale/create/steps/useWholesaleConfirmCostsColumns";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { ChargeRows } from "transfers/create/actions/replenishmentOrder/utils/help";
import { sumBy } from "lodash";
import { BasicTableColumn } from "common/components/BasicTable";
import { CostTableProps } from "common/components/CostTable";
import { useReplenishmentOrderKittingPricingSummary } from "./useReplenishmentOrderKittingPricingSummary";
import { PREP_TYPE_LABELS } from "prep/labels";
import { PrepType } from "@deliverr/prep-service-client";
import { PrepCostTableRowLabel } from "prep/create/PrepCostTable/PrepCostTableRowLabel";
import { transferToEcomConfirmStepLabels } from "transfers/create/steps/ecom/confirm/transferToEcomConfirmStep.labels";

export const useReplenishmentOrderPricingSummary = (
  replenishmentOrderEstimates?: ChargeRows,
  totalAggregationAltText?: string
): CostTableProps => {
  const { formatMessage } = useIntl();
  const aggregatorOptions = { totalRowLabel: formatMessage(wholesaleCreateLabels.steps.confirm.totalRowLabel) };
  const columns = useWholesaleConfirmCostsColumns({
    tableTitle: formatMessage(transferToEcomConfirmStepLabels.feesTitle),
  });
  const kittingCostTableProps = useReplenishmentOrderKittingPricingSummary(replenishmentOrderEstimates?.kittingCharges);

  const data: (BasicTableColumn | undefined | 0)[] = [
    ...(replenishmentOrderEstimates?.charges.map((charge): BasicTableColumn => {
      const rowLabel =
        charge.feeDescription === PREP_TYPE_LABELS[PrepType.KITTING] ? (
          <PrepCostTableRowLabel prepType={PrepType.KITTING} kittingCostTableProps={kittingCostTableProps} />
        ) : (
          formatMessage(charge.feeDescription)
        );
      return {
        rowLabel,
        quantity: charge.unitCount,
        unitLabel: charge.unitOfMeasure,
        ...(charge.unitCost > 0 ? { unitCost: charge.unitCost } : {}),
        amount: charge.totalCost,
      };
    }) ?? []),
    {},
  ];

  const costTableProps: CostTableProps = {
    subTables: [
      {
        data: data as BasicTableColumn[],
        columns,
        aggregatorOptions,
      },
    ],
    totalAggregation: totalAggregationAltText ?? sumBy(replenishmentOrderEstimates?.charges, (x) => x.totalCost),
  };
  return costTableProps;
};
