import { PrepType } from "@deliverr/prep-service-client";
import { Box, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { InfoIcon } from "common/components/icons";
import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import { css } from "emotion";
import { FLEXPORT_PREP_TYPE_DESCRIPTION_LABELS, PREP_TYPE_LABELS } from "prep/labels";
import { commonMsgs } from "inbounds/utils/commonMsgs";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SuperPrepType } from "prep/types";
import { KittingPriceSummaryModal } from "prep/create/KittingPriceSummaryModal";
import { CostTableProps } from "common/components/CostTable";

const infoIconTooltipClass = css`
  /** value from designs */
  width: 240px;
`;

const StyledInfoIcon = styled(InfoIcon)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["300"]};
`
);

const LabelContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  column-gap: ${theme.spacing.S2};
`
);

const StyledElevioBorderlessButton = styled(ElevioBorderlessButton)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["00"]};
    text-decoration: underline;
`
);

export interface PrepCostTableRowLabelProps {
  prepType: SuperPrepType;
  kittingCostTableProps?: CostTableProps;
}

export const PrepCostTableRowLabel: React.FC<PrepCostTableRowLabelProps> = ({ prepType, kittingCostTableProps }) => {
  return (
    <LabelContainer>
      <Text>
        <FormattedMessage {...PREP_TYPE_LABELS[prepType]} />
      </Text>
      {prepType === PrepType.KITTING ? (
        <KittingPriceSummaryModal costTableProps={kittingCostTableProps} />
      ) : (
        <StyledInfoIcon
          placement="top"
          tooltipProps={{
            overlayClassName: infoIconTooltipClass,
          }}
          message={
            <div>
              <Text>
                <FormattedMessage {...FLEXPORT_PREP_TYPE_DESCRIPTION_LABELS[prepType]} />
              </Text>
              <Box paddingTop="S1">
                <StyledElevioBorderlessButton
                  articleId={
                    prepType === PrepType.FLOOR_LOADED_CONTAINER
                      ? ElevioArticleId.PrepFLCUnloading
                      : prepType === PrepType.BOX_CONTENT_LABELS
                      ? ElevioArticleId.PrepBcl
                      : ElevioArticleId.SimplePrep
                  }
                >
                  <Text bold>
                    <FormattedMessage {...commonMsgs.learnMore} />
                  </Text>
                </StyledElevioBorderlessButton>
              </Box>
            </div>
          }
        />
      )}
    </LabelContainer>
  );
};
