import React from "react";
import { Button, Box, defaultTheme, IconSizes, IconV2, Text } from "common/components/ui";
import { PackingOptionStatusPill } from "./PackingOptionStatusPill";
import styled from "@emotion/styled";
import { FlexDiv } from "common/components/FlexDiv";
import { getSupportedShippingConfigurationsDescription, PackingOptionsLabels } from "./PackingOptionsLabels";
import { FormattedMessage, useIntl } from "react-intl";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { LightText } from "returns/create/step/ReturnsText";
import { FbaV3Costs } from "../commons/FbaV3Costs";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { PackingOption, PackingOptionStatus } from "@deliverr/replenishment-client";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { getShowAdminFeatures } from "common/user/UserSelectors";

export const ViewPackingOptionDetailsButton = styled(Button)`
  color: ${defaultTheme.colors.BLUE["300"]};
  border: none;
  background-color: transparent;
  height: fit-content;
  padding: 0.5rem 0;

  :enabled {
    :active,
    :hover {
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
    }
  }
`;

const TooltipIconContainer = styled.div`
  display: flex;
  padding-left: ${defaultTheme.spacing.S1};
`;

interface PackingOptionTileProps {
  packingOption: PackingOption;
  onViewDetailsClick: (packingOptionId: number) => void;
  index: number;
}

export const PackingOptionTile: React.FC<PackingOptionTileProps> = ({ packingOption, onViewDetailsClick, index }) => {
  const { packingOptionId, packingOptionStatus, shippingConfigurations, costs } = packingOption;
  const shouldShowPackingOptionStatus = packingOptionStatus !== PackingOptionStatus.OFFERED;
  const shouldShowAdminFeatures = useSelector(getShowAdminFeatures);
  const { formatMessage } = useIntl();

  return (
    <Box width={"450px"}>
      <FlexDiv>
        <FlexDiv justifyContent="none">
          <Text bold>
            <FormattedMessage {...PackingOptionsLabels.packingOptionName} values={{ index }}></FormattedMessage>
          </Text>

          {shouldShowAdminFeatures && (
            <TooltipIconContainer>
              <TooltipWithIcon
                placement="top"
                message={
                  <FormattedMessage
                    {...PackingOptionsLabels.amazonPackingOptionIdDisplay}
                    values={{
                      packingOptionId,
                    }}
                  />
                }
              >
                <IconV2 type="info-circle" color={defaultTheme.colors.NEUTRAL["200"]} size={IconSizes.small} />
              </TooltipWithIcon>
            </TooltipIconContainer>
          )}
        </FlexDiv>

        {shouldShowPackingOptionStatus && <PackingOptionStatusPill packingOptionStatus={packingOptionStatus} />}
      </FlexDiv>

      {!isEmpty(shippingConfigurations) && (
        <Box paddingTop={"S3"} paddingBottom={"S1"}>
          <LightText>
            <FormattedMessage
              {...PackingOptionsLabels.shippingConfigurationsDisplay}
              values={{
                shippingConfigurationsDisplayText: getSupportedShippingConfigurationsDescription(
                  shippingConfigurations,
                  formatMessage
                ),
              }}
            />
          </LightText>
        </Box>
      )}

      <FbaV3Costs costs={costs} entityName={FbaV3EntityName.PACKING_OPTION} />

      <ViewPackingOptionDetailsButton onClick={() => onViewDetailsClick(packingOption.id)}>
        <FormattedMessage {...PackingOptionsLabels.viewPackingOptionDetails} />
      </ViewPackingOptionDetailsButton>
    </Box>
  );
};
