import { SellerProblemsDetailView, SellerProblemType } from "@deliverr/legacy-inbound-client";

import { SUPPORTED_SELLER_PROBLEM_TYPES } from "common/problems/SellerProblemType";

import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { NonComplianceListActionTypes } from "./actions/NonComplianceListActionTypes";
import {
  NonComplianceProductWithPlanName,
  MissingBarcodeCaseWithPlanName,
  UnexpectedBarcodeCaseWithPlanName,
} from "./types";

export interface NonComplianceListSearchFilters {
  problemTypes: SellerProblemType[];
}

export interface NonComplianceListState {
  ncProducts: NonComplianceProductWithPlanName[];
  missingBarcodeCases: MissingBarcodeCaseWithPlanName[];
  unexpectedBarcodeCases: UnexpectedBarcodeCaseWithPlanName[];
  unexpectedSkuCases: SellerProblemsDetailView[];
  searchFilters: NonComplianceListSearchFilters;
}

export const nonComplianceListInitialState: NonComplianceListState = {
  ncProducts: [],
  missingBarcodeCases: [],
  unexpectedBarcodeCases: [],
  unexpectedSkuCases: [],
  searchFilters: {
    problemTypes: SUPPORTED_SELLER_PROBLEM_TYPES,
  },
};

const reducers: ReducerDictionary<NonComplianceListState> = {
  ...handleSimpleReducerUpdates([
    NonComplianceListActionTypes.SET_UNKNOWN_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_MISSING_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_SKU_NC_CASES,
    NonComplianceListActionTypes.SET_SEARCH_FILTERS,
  ]),
};

export const nonComplianceListReducer = createReducer(nonComplianceListInitialState, reducers);
