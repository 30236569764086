import { Box, IconV2, defaultTheme, IconSizes, Text } from "common/components/ui";
import React, { FC } from "react";
import { LightText } from "returns/create/step/ReturnsText";
import { getFbaV3NetCost } from "./FbaV3Utils";
import { FormattedMessage } from "react-intl";
import { FbaV3CostsLabels, FbaV3Labels } from "./FbaV3Labels";
import { FbaV3EntityName } from "./FbaV3Constants";
import styled from "@emotion/styled";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { isEmpty } from "lodash";
import { FlexDiv } from "common/components/FlexDiv";
import { Costs } from "@deliverr/replenishment-client";

const FeeContainer = styled.div`
  display: flex;
  margin-top: ${defaultTheme.spacing.S1};
`;

const TooltipIconContainer = styled(FlexDiv)`
  padding-left: ${defaultTheme.spacing.S1};
`;

export interface FbaV3CostsProps {
  costs: Costs;
  entityName: FbaV3EntityName.PACKING_OPTION | FbaV3EntityName.PLACEMENT_OPTION;
}

export const FbaV3Costs: FC<FbaV3CostsProps> = ({ costs, entityName }) => {
  const netCost = getFbaV3NetCost(costs);
  return (
    <FeeContainer>
      <LightText>
        <FormattedMessage {...FbaV3Labels[entityName].netOptionCost} values={{ netCost }} />
      </LightText>

      {!isEmpty(costs.discounts) || !isEmpty(costs.fees) ? (
        <TooltipIconContainer justifyContent="none">
          <TooltipWithIcon placement="top" message={<TooltipFeeBreakdown costs={costs} />}>
            <IconV2 type="info-circle" color={defaultTheme.colors.NEUTRAL["200"]} size={IconSizes.small} />
          </TooltipWithIcon>
        </TooltipIconContainer>
      ) : null}
    </FeeContainer>
  );
};

const TooltipFeeBreakdown: React.FC<{ costs: Costs }> = ({ costs }) => {
  const { fees, discounts } = costs;
  const sortedFees = fees.sort((a, b) => a.description.localeCompare(b.description));
  const sortedDiscounts = discounts.sort((a, b) => a.description.localeCompare(b.description));

  return (
    <Box>
      {!isEmpty(sortedFees) && (
        <Box paddingBottom={"S2"}>
          <Text bold>
            <FormattedMessage {...FbaV3CostsLabels.fees} />
          </Text>

          {sortedFees.map((fee) => (
            <Text key={fee.description}>{`${fee.description}: $${fee.value.amount}`}</Text>
          ))}
        </Box>
      )}

      {!isEmpty(sortedDiscounts) && (
        <Box paddingBottom={"S2"}>
          <Text bold>
            <FormattedMessage {...FbaV3CostsLabels.discounts} />
          </Text>

          {sortedDiscounts.map((discount) => (
            <Text key={discount.description}>{`${discount.description}: -$${discount.value.amount}`}</Text>
          ))}
        </Box>
      )}

      <Text bold>
        <FormattedMessage
          {...FbaV3CostsLabels.netCost}
          values={{
            netCost: getFbaV3NetCost(costs),
          }}
        />
      </Text>
    </Box>
  );
};
