import { createActionCreator } from "common/ReduxUtils";
import { EntityError, OperationStatus, PlacementOptionDto } from "@deliverr/replenishment-client";
import { PlacementOptionsActionTypes } from "../store/PlacementOptionsActionTypes";

export const setPlacementOptions = createActionCreator<PlacementOptionDto[], OperationStatus, number>(
  PlacementOptionsActionTypes.PLACEMENT_OPTIONS_FETCH_SUCCESS,
  "placementOptions",
  "operationStatus",
  "operationId"
);

export const setPlacementOptionsError = createActionCreator<EntityError[], OperationStatus, number | undefined>(
  PlacementOptionsActionTypes.PLACEMENT_OPTIONS_FETCH_ERROR,
  "errors",
  "operationStatus",
  "operationId"
);

export const resetPlacementOptionsState = createActionCreator(
  PlacementOptionsActionTypes.PLACEMENT_OPTIONS_RESET_STATE
);

export const setSelectedPlacementOptionId = createActionCreator<number>(
  PlacementOptionsActionTypes.PLACEMENT_OPTIONS_SET_SELECTED_PLACEMENT_OPTION_ID,
  "selectedPlacementOptionId"
);

export const resetSinglePlacementOptionState = createActionCreator<number>(
  PlacementOptionsActionTypes.PLACEMENT_OPTIONS_RESET_SINGLE_PLACEMENT_OPTION,
  "placementOptionId"
);
