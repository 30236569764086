import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { getPlacementOptionsData, getTransportationOptionsData } from "../commons/FbaV3Selectors";
import { replenishmentClient } from "Clients";
import { chain, find, reduce } from "lodash";
import { ShippingPreference } from "@deliverr/replenishment-client";

export const getFbaV3ShipmentShippingPreferences =
  () =>
  async (_dispatch, getState): Promise<ShippingPreference[]> => {
    const { replenishmentOrder } = selectTransferCreate(getState());
    const { selectedPlacementOptionId } = getPlacementOptionsData(getState());
    const { selectedTransportationConfigurations } = getTransportationOptionsData(getState());

    const confirmedAmazonShipmentIdsByShipmentId: Record<string, string> = reduce(
      replenishmentOrder?.shipments,
      (acc, shipment) => {
        acc[shipment.shipmentId!] = shipment.distributionChannelShipmentId!;
        return acc;
      },
      {}
    );

    const temporaryShipmentIdToTransportationConfiguration =
      selectedTransportationConfigurations[selectedPlacementOptionId!];

    const { value: placementOptionsResponse } = await replenishmentClient.getPlacementOptions(
      replenishmentOrder!.orderId
    );

    const amazonShipmentIdToTemporaryShipmentId: Record<string, number> = reduce(
      find(
        placementOptionsResponse?.placement?.placementOptions,
        (placementOption) => placementOption.id === selectedPlacementOptionId
      )?.shipmentList?.temporaryShipments ?? [],
      (acc, temporaryShipment) => {
        acc[temporaryShipment.distributionChannelShipmentId!] = temporaryShipment.id;
        return acc;
      },
      {}
    );

    const shipmentShippingPreferences = chain(confirmedAmazonShipmentIdsByShipmentId)
      .entries()
      .map(([shipmentId, amazonShipmentId]) => {
        const temporaryShipmentId = amazonShipmentIdToTemporaryShipmentId[amazonShipmentId];
        const transportationConfiguration = temporaryShipmentIdToTransportationConfiguration[temporaryShipmentId];

        return {
          shipmentId,
          shippingPartner: transportationConfiguration!.shippingPartner!,
          shippingType: transportationConfiguration!.shippingType!,
        };
      })
      .value();

    return shipmentShippingPreferences;
  };
