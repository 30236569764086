import { ShippingPartner } from "@deliverr/replenishment-client";
import { createActionCreator } from "common/ReduxUtils";
import { TransportationOptionsActionTypes } from "../state/TransportationOptionActionTypes";
import { ShipmentTransportationConfiguration, TransportationOptionSummary } from "../state/TransportationOptionsState";

export const setTransportationOptions = createActionCreator<ShippingPartner, number, TransportationOptionSummary>(
  TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_FETCH_SUCCESS,
  "shippingPartner",
  "placementOptionId",
  "estimateOperationResponse"
);

export const resetTransportationOptionsState = createActionCreator(
  TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_RESET_STATE
);

export const setSelectedTransportationConfiguration = createActionCreator<
  number,
  number,
  ShipmentTransportationConfiguration
>(
  TransportationOptionsActionTypes.SET_TRANSPORTATION_CONFIGURATION,
  "placementOptionId",
  "temporaryShipmentId",
  "transportationConfiguration"
);

export const resetSelectedTransportationConfiguration = createActionCreator<number, ShippingPartner>(
  TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_RESET_PLACEMENT_STATE,
  "placementOptionId",
  "shippingPartner"
);
