import { DistributionChannel } from "@deliverr/replenishment-client";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";

export const getDistributionChannel = (destinationType: TransferCreationTypes | undefined, isFbaV3On: boolean) => {
  switch (destinationType) {
    case TransferCreationTypes.Fba:
      return DistributionChannel.FBA_MANUAL;
    case TransferCreationTypes.FbaIntegration:
      return isFbaV3On ? DistributionChannel.FBA_V3 : DistributionChannel.FBA;
    case TransferCreationTypes.Wfs:
      return DistributionChannel.WFS;
    case TransferCreationTypes.Wholesale:
      return DistributionChannel.WHOLESALE;
    default:
      return DistributionChannel.ECOM_VIA_CROSSDOCK;
  }
};
