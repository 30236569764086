import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import { getShipmentItems } from "./utils/getShipmentItems";
import { SPThunkAction } from "common/ReduxUtils";
import { RootState } from "RootReducer";
import { replenishmentClient } from "Clients";
import {
  CreateOrderRequest,
  OrderResponse,
  OrderItemDto,
  Source,
  DistributionChannel,
  QuantityUom,
} from "@deliverr/replenishment-client";
import { getSellerId } from "common/user/UserSelectors";
import { getDistributionChannel } from "./utils/getDistributionChannel";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { FeatureName, isFeatureOn } from "common/Split";

export const convertDestinationAddress = (destinationAddress?: DeliverrAddress) => {
  return {
    name: destinationAddress?.name ?? "",
    company: destinationAddress?.company ?? "",
    street1: destinationAddress?.street1 ?? "",
    street2: destinationAddress?.street2 ?? "",
    city: destinationAddress?.city ?? "",
    state: destinationAddress?.state ?? "",
    country: destinationAddress?.country ?? "",
    zip: destinationAddress?.zip ?? "",
    phone: destinationAddress?.phone,
    email: destinationAddress?.email ?? "",
  };
};

export const createReplenishmentOrder =
  (submitDraft: boolean, createDraftOrderInChannel?: boolean): SPThunkAction<Promise<OrderResponse>> =>
  async (dispatch, getState: () => RootState) => {
    const state = getState();

    const {
      originStorageWarehouse,
      destinationType,
      destinationAddress,
      prepOption,
      selectedProductsLotNumbers,
      poNumber,
      isEachPickingOrder,
    } = selectTransferCreate(state);

    const isFbaV3On = isFeatureOn(FeatureName.ReplenishmentFbaV3);

    const distributionChannel = getDistributionChannel(destinationType, isFbaV3On);

    const selectedProductsQty = selectTransferSelectedProductsQty(state);
    const sellerId = getSellerId(state);

    const orderItems: OrderItemDto[] = await dispatch(
      getShipmentItems(selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers)
    );

    const createOrderRequest: CreateOrderRequest = {
      distributionChannel,
      createDraftOrderInChannel,
      originWarehouseId: originStorageWarehouse?.warehouse?.id,
      sellerId,
      orderItems,
      isDraft: !submitDraft,
      source: Source.SELLER_PORTAL,
      prepOption,
      externalId: destinationType === TransferCreationTypes.Wholesale ? poNumber : undefined,
      shipments:
        distributionChannel === DistributionChannel.FBA_MANUAL
          ? [
              {
                destinationWarehouseAddress: convertDestinationAddress(destinationAddress),
                destinationWarehouseId: destinationAddress?.name ?? "FBA",
              },
            ]
          : distributionChannel === DistributionChannel.WHOLESALE
          ? [{ destinationWarehouseAddress: convertDestinationAddress(destinationAddress) }]
          : undefined,
      quantityUom: isEachPickingOrder ? QuantityUom.EACH : undefined,
    };

    const response = (await replenishmentClient.createReplenishmentOrder(createOrderRequest)).value;
    return response;
  };
