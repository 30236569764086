import React, { FC } from "react";
import { Carrier, CarrierAccount } from "@deliverr/replenishment-client";
import { Text } from "common/components/ui";
import { shipmentSummaryLabels } from "transfers/detail/shipmentSummary.labels";
import { FormattedMessage, useIntl } from "react-intl";
import { getCarrierLabel } from "../carrier-account/hooks/useReplenishmentCarrierAccountForm";

interface CarrierAccountDetailProps {
  carrierAccount: CarrierAccount | undefined;
}

export const CarrierAccountDetail: FC<CarrierAccountDetailProps> = ({ carrierAccount }) => {
  const { formatMessage } = useIntl();

  // If carrierAccount is undefined, then shipping method is COLLECT FedEx
  const carrierName = getCarrierLabel(carrierAccount?.carrier ?? Carrier.FEDEX);
  const accountNumber = carrierAccount?.accountNumber ?? formatMessage(shipmentSummaryLabels.collect);

  return (
    <Text>
      <FormattedMessage {...shipmentSummaryLabels.carrierAccountDetails} values={{ carrierName, accountNumber }} />
    </Text>
  );
};
