import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { batch } from "react-redux";
import { EntityError } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { logError } from "Logger";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { FormattedMessage } from "react-intl";
import React from "react";
import { toast } from "common/components/ui";
import { PlacementOptionsLabels } from "../PlacementOptionsLabels";
import { getPlacementOptionsData } from "../../commons/FbaV3Selectors";
import { transferCreateSetReplenishmentError } from "transfers/create/actions";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";

export const confirmPlacementOption = (): SPThunkAction => async (dispatch, getState) => {
  batch(() => {
    dispatch(addLoader(FbaV3Loaders.CONFIRM_PLACEMENT_OPTION));
  });

  try {
    const { replenishmentOrder } = selectTransferCreate(getState());
    const { selectedPlacementOptionId } = getPlacementOptionsData(getState());
    await replenishmentClient.confirmPlacementOption(replenishmentOrder!.orderId, selectedPlacementOptionId!);

    return true;
  } catch (err) {
    logError({ fn: "confirmPlacementOption" }, err, "Failed to confirm placement option");
    const error = err?.response?.data?.error ?? err;

    if (error instanceof EntityError) {
      dispatch(
        transferCreateSetReplenishmentError({
          errors: getFlattenedEntityErrors([error]),
          hasError: true,
        })
      );
    } else {
      dispatch(
        transferCreateSetReplenishmentError({
          errors: [
            new EntityError({
              message: error?.message,
              code: error?.code,
            }),
          ],
          hasError: true,
        })
      );
    }

    toast.error(<FormattedMessage {...PlacementOptionsLabels.placementOptionConfirmationFailure} />, {
      autoClose: false,
      toastId: "ConfirmPlacementOptionError",
    });

    return false;
  } finally {
    dispatch(clearLoader(FbaV3Loaders.CONFIRM_PLACEMENT_OPTION));
  }
};
