import React, { FC } from "react";
import { PlacementOptionDto, TemporaryShipmentOption } from "@deliverr/replenishment-client";
import styled from "@emotion/styled";
import Table, { TableProps } from "common/components/lists/Table";
import { ConciseShipmentDetails } from "../../common/replenishment/SelectMultipleShipmentShippingType";
import { ViewShipmentDetailsModal } from "../../common/replenishment/ViewShipmentDetailsModal";
import { Box, LightDivider, Notification } from "common/components/ui";
import { useTransportationOptions } from "./useTransportationOptions";
import { TransportationOptionsSelect } from "./TransportationOptionsSelect";
import { mapTemporaryShipmentToShipment } from "../commons/FbaV3Utils";
import { FormattedMessage } from "react-intl";
import { FbaV3Labels } from "../commons/FbaV3Labels";
import { FbaV3EntityName } from "../commons/FbaV3Constants";

const StyledTable = styled(Table)<TableProps<TemporaryShipmentOption>>`
  thead {
    display: none;
  }
`;

export interface TransportationOptionsProps {
  temporaryShipmentOptions: TemporaryShipmentOption[];
  placementOption: PlacementOptionDto;
}

export const TransportationOptions: FC<TransportationOptionsProps> = ({
  temporaryShipmentOptions,
  placementOption,
}) => {
  const {
    selectedShipmentForDetailsView,
    selectedTransportationConfigurations,
    transportationOptionSummaries,
    setSelectedShipmentForDetailsView,
    handleEstimateSelection,
    onRetryAfterEstimationErrorClick,
    onRetryAfterRetryLimitReachedClick,
    isAnyConflictingEstimateSelected,
    isAnyEstimateLoading,
  } = useTransportationOptions(placementOption);

  const columns = [
    {
      header: "",
      width: "23%",
      renderCell: (shipmentOption: TemporaryShipmentOption) => (
        <ConciseShipmentDetails
          shipment={mapTemporaryShipmentToShipment(shipmentOption)}
          setSelectedShipment={setSelectedShipmentForDetailsView}
        />
      ),
    },
    {
      header: "",
      width: "70%",
      renderCell: (shipmentOption: TemporaryShipmentOption) => (
        <TransportationOptionsSelect
          shipmentOption={shipmentOption}
          shipmentConfiguration={selectedTransportationConfigurations?.[shipmentOption.id]}
          handleEstimateSelection={handleEstimateSelection}
          onRetryAfterEstimationErrorClick={onRetryAfterEstimationErrorClick}
          onRetryAfterRetryLimitReachedClick={onRetryAfterRetryLimitReachedClick}
          transportationOptionSummaries={transportationOptionSummaries}
          isAnyConflictingEstimateSelected={isAnyConflictingEstimateSelected}
        />
      ),
    },
  ];

  return (
    <>
      <ViewShipmentDetailsModal
        show={!!selectedShipmentForDetailsView}
        onClose={() => setSelectedShipmentForDetailsView(undefined)}
        shipment={selectedShipmentForDetailsView}
      />

      {isAnyEstimateLoading && (
        <Box paddingTop={"S1"} paddingBottom={"S1"}>
          <Notification
            appearance="INFO"
            content={{
              description: (
                <FormattedMessage
                  {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].transportationOptionsLoadDelay}
                />
              ),
            }}
          />
        </Box>
      )}

      <StyledTable
        columns={columns}
        items={temporaryShipmentOptions}
        page="0"
        onPageChange={() => {}}
        showCheckboxCondition={() => false}
        showAllSelectionCheckbox={false}
        allowRowSelection={false}
        hidePaginate
        hideHover
        hideBoxShadow
      />

      <LightDivider />
    </>
  );
};
