import { createActionCreator } from "common/ReduxUtils";
import { PackingOptionsActionTypes } from "../store/PackingOptionsActionTypes";
import { EntityError, OperationStatus, PackingOption } from "@deliverr/replenishment-client";

export const setPackingOptions = createActionCreator<PackingOption[], OperationStatus, number>(
  PackingOptionsActionTypes.PACKING_OPTIONS_FETCH_SUCCESS,
  "packingOptions",
  "operationStatus",
  "operationId"
);

export const setPackingOptionsError = createActionCreator<EntityError[], OperationStatus, number | undefined>(
  PackingOptionsActionTypes.PACKING_OPTIONS_FETCH_ERROR,
  "errors",
  "operationStatus",
  "operationId"
);

export const resetPackingOptionsState = createActionCreator(PackingOptionsActionTypes.PACKING_OPTIONS_RESET_STATE);

export const setSelectedPackingOptionId = createActionCreator<number>(
  PackingOptionsActionTypes.PACKING_OPTIONS_SET_SELECTED_PACKING_OPTION_ID,
  "selectedPackingOptionId"
);
