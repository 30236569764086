import { BasicTableColumn } from "common/components/BasicTable";
import { CostTableProps } from "common/components/CostTable";
import { useCostTableAggregator } from "common/components/CostTable/useCostTableAggregator";
import { KITTING_COST_TABLE_LABELS } from "prep/create/PrepCostTable/KittingCostTabel.label";
import { useIntl } from "react-intl";
import { useWholesaleConfirmCostsColumns } from "wholesale/create/steps/useWholesaleConfirmCostsColumns";

export const useReplenishmentOrderKittingPricingSummary = (
  kittingCharges: BasicTableColumn[] | undefined
): CostTableProps => {
  const { formatMessage } = useIntl();

  const columns = useWholesaleConfirmCostsColumns({ tableTitle: formatMessage(KITTING_COST_TABLE_LABELS.tableTitle) });

  const kittingPrepSubTable = {
    columns,
    data: kittingCharges ?? [],
    aggregatorOptions: { totalRowLabel: formatMessage(KITTING_COST_TABLE_LABELS.totalCostLabel) },
  };
  const costTableAggregated = useCostTableAggregator({ subTables: [kittingPrepSubTable], hideAggregatedRows: true });

  const aggregationDescription = formatMessage(KITTING_COST_TABLE_LABELS.kittingPrepClarification);

  return {
    ...costTableAggregated,
    aggregationDescription,
  };
};
