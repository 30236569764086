import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { batch } from "react-redux";
import { setPackingOptionsError } from "./PackingOptionsActions";
import { EntityError, OperationStatus } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { logError } from "Logger";
import { getPackingOptions } from "./getPackingOptions";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { FormattedMessage } from "react-intl";
import React from "react";
import { PackingOptionsLabels } from "../PackingOptionsLabels";
import { toast } from "common/components/ui";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";

export const confirmPackingOption =
  (packingOptionId: number): SPThunkAction =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(addLoader(FbaV3Loaders.CONFIRM_PACKING_OPTION));
    });

    try {
      const { replenishmentOrder } = selectTransferCreate(getState());
      await replenishmentClient.confirmPackingOption(replenishmentOrder!.orderId, packingOptionId);

      dispatch(getPackingOptions());
      return true;
    } catch (err) {
      const error = err?.response?.data?.error ?? err;

      logError({ fn: "confirmPackingOption", packingOptionId }, err, "Failed to confirm packing option");

      if (error instanceof EntityError) {
        dispatch(setPackingOptionsError(getFlattenedEntityErrors([error]), OperationStatus.FAILED, undefined));
      } else {
        dispatch(
          setPackingOptionsError(
            [
              new EntityError({
                message: error?.message,
                code: error?.code,
              }),
            ],
            OperationStatus.FAILED,
            undefined
          )
        );
      }

      toast.error(<FormattedMessage {...PackingOptionsLabels.packingOptionConfirmationFailure} />, {
        autoClose: false,
        toastId: "ConfirmPackingOptionError",
      });

      return false;
    } finally {
      dispatch(clearLoader(FbaV3Loaders.FETCH_PACKING_OPTIONS));
    }
  };
