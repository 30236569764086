import { Organization, OrganizationStatus } from "common/clients/seller/Organization/Organization";
import { User } from "common/clients/seller/User/User";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { OrganizationActionTypes } from "organization/OrganizationActions";

export interface OrganizationState extends Organization {
  createdAt: string;
  sellerId: string;
  hasChargebeeAccount: boolean;
  showChecklist: boolean;
  isArchived: boolean;
  users: User[];
  organizationId?: string;
  businessName?: string;
  senderEmail?: string;
  status: OrganizationStatus;
}

export const organizationInitialState: OrganizationState = {
  sellerId: "",
  createdBy: "",
  createdAt: "",
  isCatalogImported: false,
  hasChargebeeAccount: false,
  dealStage: "",
  onboardingStage: {},
  showChecklist: true,
  tags: "",
  isArchived: false,
  users: [],
  businessName: undefined,
  senderEmail: undefined,
  logisticsAccountId: undefined,
  shopDomain: undefined,
  isSfnSupportAccount: false,
  isTestAccount: false,
  status: OrganizationStatus.ACTIVE,
};

const reducers: ReducerDictionary<OrganizationState> = {
  ...handleSimpleReducerUpdates([OrganizationActionTypes.UPDATE_ORGANIZATION]),
};

export const organizationReducer = createReducer(organizationInitialState, reducers);
