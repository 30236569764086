import { batch } from "react-redux";
import { isEmpty } from "lodash/fp";
import { toast } from "common/components/ui";

import { inboundClient } from "Clients";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import { SPThunkAction } from "common/ReduxUtils";
import { InboundStateWithoutOldState } from "inbounds/InboundReducer";
import { PlannedShipment } from "inbounds/InboundTypes";
import { transferPlannedShipmentData, createNewDraftShipment } from "inbounds/steps/CreateDraftShipment";
import { loadCasePackDefaults } from "inbounds/steps/InboundLoadActions";
import { getWarehouseIdsFromShipments } from "inbounds/steps/ship/InboundUtils";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import log, { logError, logStart, logSuccess } from "Logger";
import { shipmentCreationFailedMessage } from "../toasts/InboundsToastMessages";
import { AsyncFunction, asyncPoll } from "common/utils/asyncPoll";

const SHIPMENT_POLL_INTERVAL_MS = 5000; // 5s
const SHIPMENT_POLL_TIMEOUT_MS = 120000; // 2 min

const getPlannedShipmentByWarehouseId = (
  warehouseId: string,
  oldState?: InboundStateWithoutOldState
): PlannedShipment | undefined => {
  if (!oldState) {
    return undefined;
  }

  const { shipments, plannedShipments } = oldState;
  const matchingShipment = Object.values(shipments.byId).find((shipment) => shipment.warehouseId === warehouseId);
  return matchingShipment ? plannedShipments.byId[matchingShipment.id] : undefined;
};

interface FetchShipmentGenerationResultProps {
  requestId: string;
  sellerId: string;
  planId: number;
}

const composeFetchDidGenerateShipments =
  ({ requestId, sellerId, planId }: FetchShipmentGenerationResultProps): AsyncFunction<boolean> =>
  async () => {
    const data = await inboundClient.generateShipmentsWithRequestId(requestId, sellerId, planId);
    const didGenerateShipments = !isEmpty(data);
    return {
      isDone: didGenerateShipments,
      data: didGenerateShipments,
    };
  };

export const createShipments = (): SPThunkAction => async (dispatch, getState) => {
  const {
    inbound: {
      plan: { id: planId, items = [], isForwarding },
      plannedPackages,
      oldState,
    },
    user: { sellerId },
  } = getState();
  const distribution = isForwarding ? "forwarding" : "direct";

  const ctx = logStart({ fn: "createShipments", sellerId, planId, items, distribution });

  try {
    if (isForwarding) {
      await inboundClient.generateShipmentsV2(sellerId, planId);
    } else {
      const { isComplete, requestId } = await inboundClient.allocateInventory(sellerId, planId);
      // requestId should always be there if isComplete is false, but let's check anyway
      if (!isComplete && requestId !== undefined) {
        const didGenerateShipments = await asyncPoll(
          composeFetchDidGenerateShipments({ requestId, sellerId, planId }),
          SHIPMENT_POLL_INTERVAL_MS,
          SHIPMENT_POLL_TIMEOUT_MS
        );

        if (!didGenerateShipments) {
          throw new Error("Shipment generation timed out");
        }
      }
    }

    const shipments = await inboundClient.getShipments(sellerId, planId, true);
    const casePackDefaults = await loadCasePackDefaults(items);

    logSuccess(ctx, "Successfully generated shipments");

    const warehouseIds = getWarehouseIdsFromShipments(shipments);
    await dispatch(loadWarehouses(warehouseIds));

    batch(() => {
      dispatch({
        type: InboundActionTypes.SET_CASE_PACK_DEFAULTS,
        casePackDefaults,
      });
      dispatch({
        type: InboundActionTypes.CREATE_SHIPMENTS_SUCCESS,
        shipmentIds: shipments.map(({ id }) => id),
        shipments,
        plannedShipments: shipments.map((shipment) => {
          const existingPlannedShipment = getPlannedShipmentByWarehouseId(shipment.warehouseId, oldState);
          return existingPlannedShipment
            ? transferPlannedShipmentData(existingPlannedShipment, shipment, casePackDefaults, plannedPackages)
            : createNewDraftShipment(shipment, casePackDefaults, shipments.length === 1 ? plannedPackages : undefined);
        }),
      });
    });
  } catch (err) {
    if (err.subcode === "MAX_UNITS_PER_DSKU_EXCEEDED") {
      log.info({ ...ctx, err }, "ship to six");
      dispatch({ type: InboundActionTypes.SHOW_MAX_UNITS_EXCEEDED, showMaxUnitsExceeded: true });
    } else if (err.subcode === "SELLER_BLACKLISTED") {
      log.info({ ...ctx, err }, "seller blacklisted");
      dispatch({ type: InboundActionTypes.SHOW_BLACKLISTED, showBlacklisted: true });
    } else {
      logError({ ...ctx, err }, "error creating shipments");
      toast.error(shipmentCreationFailedMessage, { toastId: "shipmentCreationFailedMessage" });
    }
    throw err;
  }
};
