import { batch } from "react-redux";
import { createActionCreator } from "common/ReduxUtils";
import { WholesaleListSearchFilters, WholesaleListState, WholesaleIngestionChannel } from "../store/WholesaleListState";
import { WholesaleListActionTypes } from "./WholesaleListActionTypes";
import { ReplenishmentOrderListTabFilter } from "transfers/listV2/store/ReplenishmentListState";

export const getListItemsSuccessAction = createActionCreator<
  Pick<WholesaleListState, "nbPages" | "pageNum" | "pageItems">
>(WholesaleListActionTypes.WHOLESALE_LIST_GET_SUCCESS, "listData");

export const wholesaleListError = createActionCreator<boolean>(
  WholesaleListActionTypes.WHOLESALE_LIST_GET_ERROR,
  "isError"
);

export const wholesaleListChangePageNum = createActionCreator<number>(
  WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_PAGE_NUM,
  "pageNum"
);

export const setWholesaleListTabFilter = (tabFilter: ReplenishmentOrderListTabFilter) => async (dispatch) =>
  batch(() => {
    dispatch({ type: WholesaleListActionTypes.WHOLESALE_LIST_SET_TAB_FILTER, tabFilter });
    dispatch(wholesaleListChangePageNum(0));
  });

export const wholesaleListChangeSearchTerm = createActionCreator<string>(
  WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SEARCH_TERM,
  "searchTerm"
);

export const wholesaleListChangeStatusFilter = createActionCreator<WholesaleListSearchFilters["statuses"]>(
  WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_STATUS_FILTERS,
  "statuses"
);

export const wholesaleListChangeSourceTypeFilter = createActionCreator<WholesaleIngestionChannel>(
  WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS,
  "ingestionChannel"
);
