import { Box, Text } from "common/components/ui";
import React, { FC } from "react";
import { InvalidOrderItemDto, InvalidOrderItemResolution, OrderResponse } from "@deliverr/replenishment-client";
import { SelectedProductData } from "common/components/ProductChooser";
import { TransferProductChooser } from "transfers/create/steps/fba";
import { invalidOrderItemLabel } from "../invalidOrderItemLabel";
import { useIntl } from "react-intl";
import { useUnknownItemResolution } from "./useUnknownItemResolution";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

interface UnknownItemResolutionProps {
  invalidItem: InvalidOrderItemDto;
  selectedProduct?: SelectedProductData;
  setSelectedProduct: (product: SelectedProductData | undefined) => void;
  setResolution: (resolution: InvalidOrderItemResolution | undefined) => void;
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
  replenishmentOrder?: OrderResponse;
}

export const UnknownItemResolution: FC<UnknownItemResolutionProps> = ({
  selectedProduct,
  setSelectedProduct,
  setResolution,
  formatMessage,
  invalidItem,
}) => {
  const { isMultiCasePackEnabled, addProduct, removeProduct, alreadySelectedProductDskus } = useUnknownItemResolution({
    setSelectedProduct,
    setResolution,
  });
  const { isEachPickingOrder } = useSelector(selectTransferCreate);

  return (
    <>
      <Text size="bodyLarge" bold>
        {formatMessage(invalidOrderItemLabel.blade.unknownItem)}
      </Text>
      <Box paddingTop={"S2"}>
        <TransferProductChooser
          addProduct={addProduct}
          removeProduct={removeProduct}
          selectedProducts={selectedProduct ? [selectedProduct] : []}
          shouldShowBulkUploadButton={false}
          bulkUploadButton={<></>}
          isMultiCasePackEnabled={isMultiCasePackEnabled}
          destinationType={undefined}
          shouldShowEcomDetailsForStorageProducts={false}
          storageMaximumSelectableCaseQty={isEachPickingOrder ? undefined : invalidItem.requestedTotalUnits}
          excludedPackOfSkusForStorage={alreadySelectedProductDskus}
        />
      </Box>
    </>
  );
};
