import { productClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { FormattedMessage } from "react-intl";
import React from "react";
import { setNewProductCasePack, setSelectedCasePackOption } from "../SelectProductCasePackActions";
import { ProductCasePackData } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import { updateCaseQty } from "../../selectProducts/SelectStorageProductsActions";
import { newProductCasePackLoader } from "common/components/NewProductCasePackForm/useNewProductCasePackForm";
import { hideModal } from "common/components/Modal/ModalActions";
import { getNewCasePackModalIdForSku } from "common/components/NewProductCasePackModal";
import { batch } from "react-redux";
import { toast } from "common/components/ui";
import { get } from "lodash";

export const createNewProductCasePack =
  (productCasePackData: ProductCasePackData): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch(addLoader(newProductCasePackLoader));
      const productCasePack = await productClient.createProductCasePack(productCasePackData);

      batch(() => {
        dispatch(setNewProductCasePack(productCasePack.packOf, productCasePack));
        dispatch(setSelectedCasePackOption(productCasePack.packOf, productCasePack));
        dispatch(updateCaseQty(productCasePack.packOf, productCasePack.quantity));
      });

      toast.success(
        <FormattedMessage
          id={"storage.inbounds.createProductCasePack.success"}
          defaultMessage={"Successfully created case pack template"}
        />,
        {
          autoClose: 5000,
          toastId: "createProductCasePackSuccess",
        }
      );
      dispatch(hideModal(getNewCasePackModalIdForSku(productCasePackData.packOf)));
    } catch (err) {
      logError({ dsku: productCasePackData.packOf }, err, "error creating case pack template for dsku");
      const errorMessage =
        get(err, "response.data.error", null) ?? "Failed to create case pack template, please try again!";
      toast.error(
        <FormattedMessage id={"storage.inbounds.createProductCasePack.error"} defaultMessage={errorMessage} />,
        {
          autoClose: false,
          toastId: "createProductPackError",
        }
      );
    } finally {
      dispatch(clearLoader(newProductCasePackLoader));
    }
  };
