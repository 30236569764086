import { useState } from "react";
import { InvalidOrderItemBladeProps } from "./InvalidOrderItemBlade";
import { getItemProductAndInventoryDetails } from "common/wholesale-common/getItemProductAndInventoryDetails";
import { SelectedProductData } from "common/components/ProductChooser";
import {
  InvalidOrderItemDto,
  InvalidOrderItemReason,
  InvalidOrderItemResolution,
} from "@deliverr/replenishment-client";
import { FeatureName, useFeatureOn } from "common/Split";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useAsync } from "react-use";
import { resolveInvalidItem } from "transfers/create/actions/resolveInvalidItems";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
import { setOriginWarehouseForReplenishmentInvalidItemOrder } from "./setOriginWarehouseForReplenishmentInvalidItemOrder";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const useInvalidOrderItemBlade = ({ onCloseClick, invalidItem }: InvalidOrderItemBladeProps) => {
  const [resolution, setResolution] = useState<InvalidOrderItemResolution | undefined | null>(invalidItem.resolution);
  const [selectedProduct, setSelectedProduct] = useState<SelectedProductData | undefined>(undefined);
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { isEachPickingOrder } = useSelector(selectTransferCreate);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { loading: isResolutionDataLoading } = useAsync(async () => {
    if (invalidItem.resolvedDsku) {
      const products = await getItemProductAndInventoryDetails(
        [{ dsku: invalidItem.resolvedDsku!, qty: invalidItem.requestedTotalUnits }],
        dispatch,
        isMultiCasePackEnabled,
        false
      );

      const product = products.selectedProducts[invalidItem.resolvedDsku!];
      setSelectedProduct({
        ...product,
        caseQty: isEachPickingOrder ? 1 : product.caseQty,
        qty: invalidItem.resolvedTotalUnits ?? invalidItem.requestedTotalUnits,
      });
      setResolution(InvalidOrderItemResolution.MODIFIED);
    } else {
      setSelectedProduct(undefined);
      setResolution(invalidItem.resolution);
    }
  }, [invalidItem, dispatch, isMultiCasePackEnabled]);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.value === "true") {
      setSelectedProduct(undefined);
      setResolution(InvalidOrderItemResolution.REMOVED);
    } else {
      setResolution(undefined);
    }
  };

  const onContinueClick = async () => {
    setIsContinueLoading(true);
    try {
      await dispatch(
        resolveInvalidItem({
          ...invalidItem,
          resolution,
          resolvedDsku: selectedProduct?.dsku,
          resolvedTotalUnits: selectedProduct ? getResolvedTotalUnits(selectedProduct, invalidItem) : undefined,
        })
      );
      await dispatch(setOriginWarehouseForReplenishmentInvalidItemOrder());
    } finally {
      setIsContinueLoading(false);
    }
    onCloseClick();
  };

  const getResolvedTotalUnits = (
    selectedProductData: SelectedProductData,
    invalidOrderItem: InvalidOrderItemDto
  ): number | undefined => {
    const caseQty: number = !isEachPickingOrder && selectedProductData.caseQty ? selectedProductData.caseQty : 1;
    switch (invalidOrderItem.reason) {
      case InvalidOrderItemReason.INVALID_ITEM:
      case InvalidOrderItemReason.INVALID_CASE_CONFIG:
        // Minimum of the requested total units and the reserve storage available quantity (divisible by case qty)
        return Math.min(
          Math.floor(invalidOrderItem.requestedTotalUnits / caseQty) * caseQty,
          Math.floor(calculateReserveStorageAvailableQuantity(selectedProductData) / caseQty) * caseQty
        );
      default:
        return invalidOrderItem.requestedTotalUnits;
    }
  };

  return {
    resolution,
    setResolution,
    selectedProduct,
    setSelectedProduct,
    formatMessage,
    dispatch,
    onCloseClick,
    onChange,
    onContinueClick,
    isResolutionDataLoading,
    isContinueLoading,
  };
};
