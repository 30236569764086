import React, { FC } from "react";
import { Pill, PillColors } from "common/components/Pill";
import { FormattedMessage } from "react-intl";
import { PackingOptionsLabels } from "./PackingOptionsLabels";
import { PackingOption, PackingOptionStatus } from "@deliverr/replenishment-client";

const statusToPillColor: Record<PackingOptionStatus, PillColors> = {
  [PackingOptionStatus.ACCEPTED]: PillColors.GREEN,
  [PackingOptionStatus.EXPIRED]: PillColors.RED,
  [PackingOptionStatus.OFFERED]: PillColors.BLUE,
};

export type PackingOptionStatusPillProps = Pick<PackingOption, "packingOptionStatus">;

export const PackingOptionStatusPill: FC<PackingOptionStatusPillProps> = ({ packingOptionStatus }) => {
  return (
    <Pill color={statusToPillColor[packingOptionStatus]}>
      <FormattedMessage {...PackingOptionsLabels.statusLabels[packingOptionStatus]} />
    </Pill>
  );
};
