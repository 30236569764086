import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { getReplenishmentShippingTypeFromWholesaleType } from "../../utils/getReplenishmentShippingTypeFromWholesaleType";
import { getShippingQuotes } from "transfers/create/actions/replenishmentOrder/shipping/getShippingQuotes";
import {
  DistributionChannel,
  OrderEstimateRequest,
  ShippingPartner,
  ShippingType,
} from "@deliverr/replenishment-client";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { getOrderShipments, isReplenishmentOrderWithMultipleShipments } from "../../utils/replenenishmentShipments";
import {
  replenishmentOrderShipmentTypeMap,
  replenishmentOrderShipmentPartnerMap,
  replenishmentMultipleCarrierMessage,
} from "./ReplenishmentOrderConfirmationMessages";
import { useLoader } from "common/components/WithLoader/useLoader";
import { FbaV3Loaders } from "transfers/create/steps/fbaV3/commons/FbaV3Constants";
import { getFbaV3OrderConfirmationEstimates } from "transfers/create/steps/fbaV3/commons/getFbaV3OrderConfirmationEstimates";
import { getPlacementOptionsData } from "transfers/create/steps/fbaV3/commons/FbaV3Selectors";
import { some } from "lodash";

export const useReplenishmentOrderConfirmation = () => {
  const {
    shippingMethod,
    cargoType,
    destinationType,
    replenishmentOrder,
    replenishmentOrderEstimates,
    replenishmentError,
    shipmentShippingMethodMap,
    isEachPickingOrder,
  } = useSelector(selectTransferCreate);

  const { selectedPlacementOptionId, placementOptions } = useSelector(getPlacementOptionsData);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isPlacementOptionConfirmLoading = useLoader(FbaV3Loaders.CONFIRM_PLACEMENT_OPTION);
  const arePlacementOptionsBeingFetched = useLoader(FbaV3Loaders.FETCH_PLACEMENT_OPTIONS);
  const isCreateOrSubmitOrderLoading = useLoader(CreateTransferLoader.CreateTransferOrderLoader);
  const areEstimatesLoading = useLoader(CreateTransferLoader.FetchEstimatesLoader);

  const isSubmitLoading = some([
    isPlacementOptionConfirmLoading,
    arePlacementOptionsBeingFetched,
    isCreateOrSubmitOrderLoading,
  ]);

  const shouldShowPricingSummary =
    replenishmentOrderEstimates?.charges && replenishmentOrderEstimates.charges.length > 0;
  const selectedPlacementOption = placementOptions?.find(({ id }) => id === selectedPlacementOptionId);

  const [shouldShowShipmentsModal, setShowShipmentsModal] = useState(false);

  const isMultipleShipments = isReplenishmentOrderWithMultipleShipments(replenishmentOrder, selectedPlacementOption);
  const shipments = getOrderShipments(replenishmentOrder, selectedPlacementOption);

  const onModalClose = () => {
    setShowShipmentsModal(false);
  };

  const onViewMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowShipmentsModal(true);
  };
  const shippingType = getReplenishmentShippingTypeFromWholesaleType(cargoType!);
  const shippingPartner = getReplenishmentShippingPartnerFromWholesaleType(shippingMethod!, destinationType);
  const shippingTypeMessage = formatMessage(replenishmentOrderShipmentTypeMap[shippingType]);
  const shippingPartnerMessage =
    replenishmentOrder?.distributionChannel === DistributionChannel.FBA_V3
      ? formatMessage(replenishmentMultipleCarrierMessage)
      : formatMessage(replenishmentOrderShipmentPartnerMap[shippingPartner]);

  useMount(async () => {
    if (replenishmentOrder?.distributionChannel === DistributionChannel.FBA_V3) {
      await dispatch(getFbaV3OrderConfirmationEstimates(replenishmentOrder?.orderId ?? ""));

      return;
    }

    let orderEstimateRequest: OrderEstimateRequest;
    if (replenishmentOrder?.distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK) {
      // We pass dummy shipmentId as shipments haven't yet been created
      orderEstimateRequest = {
        shipmentShippingPreferences: [
          {
            shipmentId: "ECOM_SHIPMENT",
            shippingPartner: ShippingPartner.DELIVERR,
            shippingType: ShippingType.LTL,
          },
        ],
      };
    } else {
      orderEstimateRequest = {
        shipmentShippingPreferences:
          replenishmentOrder?.shipments?.map(({ shipmentId }) => {
            const shipmentShippingType = getReplenishmentShippingTypeFromWholesaleType(
              shipmentShippingMethodMap?.[shippingMethod!]?.[shipmentId] ?? cargoType
            );

            return {
              shipmentId,
              shippingPartner,
              shippingType: shipmentShippingType,
            };
          }) ?? [],
      };
    }

    await dispatch(
      getShippingQuotes(
        orderEstimateRequest,
        replenishmentOrder?.orderId ?? "",
        replenishmentOrder?.distributionChannel
      )
    );
  });

  return {
    replenishmentOrderEstimates,
    isSubmitLoading,
    onViewMoreClick,
    shouldShowShipmentsModal,
    setShowShipmentsModal,
    onModalClose,
    formatMessage,
    shippingTypeMessage,
    shippingPartnerMessage,
    isMultipleShipments,
    replenishmentError,
    isEachPickingOrder,
    shouldShowPricingSummary,
    areEstimatesLoading,
    shipments,
  };
};
