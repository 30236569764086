import React, { FC } from "react";
import { Pill, PillColors } from "common/components/Pill";
import { FormattedMessage } from "react-intl";
import { PlacementOptionsLabels } from "./PlacementOptionsLabels";
import { PlacementOptionDto, PlacementOptionStatus } from "@deliverr/replenishment-client";

const statusToPillColor: Record<PlacementOptionStatus, PillColors> = {
  [PlacementOptionStatus.ACCEPTED]: PillColors.GREEN,
  [PlacementOptionStatus.EXPIRED]: PillColors.RED,
  [PlacementOptionStatus.OFFERED]: PillColors.BLUE,
};

export type PlacementOptionStatusPillProps = Pick<PlacementOptionDto, "placementOptionStatus">;

export const PlacementOptionStatusPill: FC<PlacementOptionStatusPillProps> = ({ placementOptionStatus }) => {
  return (
    <Pill color={statusToPillColor[placementOptionStatus]}>
      <FormattedMessage {...PlacementOptionsLabels.statusLabels[placementOptionStatus]} />
    </Pill>
  );
};
