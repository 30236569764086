import React, { FC } from "react";
import { isEmpty } from "lodash/fp";
import { ProblemsTable } from "common/components/ProblemsTable";
import { NonComplianceEmptyList } from "../components/NonComplianceEmptyList";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { NonComplianceListMobile } from "./NonComplianceListMobile";
import { NonComplianceListBlade } from "./blades";
import { useNonComplianceSpvList } from "./useNonComplianceSpvList";
import { RootState } from "RootReducer";
import { useSelector } from "react-redux";
import { useMobile } from "common/components/ui";
import { NonComplianceListTableControls } from "./NonComplianceListTableControls";

const DEFAULT_PROBLEMS_PER_PAGE = 10;

export const NonComplianceSpvList: FC = () => {
  const pageSize =
    useSelector((state: RootState) => state.user.resultsPerPage.inboundProblemsList) ?? DEFAULT_PROBLEMS_PER_PAGE;

  const { columns, rows, onRowClick, isLoading, activeCase, totalPages, currentPage, savePage } =
    useNonComplianceSpvList(pageSize);

  const onPageChange = (page: number) => {
    savePage(page);
  };

  const isMobile = useMobile();

  return (
    <>
      <NonComplianceListTableControls />
      {isLoading ? (
        <SimpleLoader />
      ) : !isEmpty(rows) ? (
        <>
          <NonComplianceListBlade nonComplianceCase={activeCase} />
          {isMobile ? (
            <NonComplianceListMobile rows={rows} onRowClick={onRowClick} />
          ) : (
            <ProblemsTable
              columns={columns}
              rows={rows}
              onRowClick={onRowClick}
              hidePaginate={false}
              pagingInfo={{
                totalPages: totalPages ?? 0,
                currentPage,
              }}
              onPageChange={onPageChange}
            />
          )}
        </>
      ) : (
        <NonComplianceEmptyList />
      )}
    </>
  );
};
