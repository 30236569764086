import { logError } from "Logger";
import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { toast } from "common/components/ui";
import { FbaV3EntityName, FbaV3Loaders } from "./FbaV3Constants";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FbaV3Labels } from "./FbaV3Labels";
import React from "react";
import { replenishmentClient } from "Clients";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

interface RetryFbaOperationProps {
  operationId: number;
  postRetryAction?: () => void;
  entityName: FbaV3EntityName;
}

export const retryFbaOperation =
  ({ operationId, postRetryAction, entityName }: RetryFbaOperationProps): SPThunkAction =>
  async (dispatch, getState) => {
    dispatch(addLoader(FbaV3Loaders.RETRY_OPERATION));

    try {
      const { replenishmentOrder } = selectTransferCreate(getState());
      await replenishmentClient.retryOperation(operationId, replenishmentOrder!.orderId);

      postRetryAction?.();
    } catch (err) {
      logError({ fn: "retryFbaOperation", operationId }, err);

      toast.error(<FormattedMessage {...FbaV3Labels[entityName].retryOperationFailure} />, {
        autoClose: 5000,
        toastId: "RetryFbaOperationError",
      });
    } finally {
      dispatch(clearLoader(FbaV3Loaders.RETRY_OPERATION));
    }
  };
