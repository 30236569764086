import {
  Costs,
  EntityError,
  Operation,
  OperationName,
  OperationStatus,
  PackingOption,
  PackingOptionStatus,
  PlacementOptionDto,
  PlacementOptionStatus,
  ShipmentItemResponse,
  ShipmentResponse,
  ShipmentStatus,
  ShippingPartner,
  ShippingType,
  TemporaryShipmentOption,
  TransportationOption,
} from "@deliverr/replenishment-client";
import { chain, entries, isEmpty, map, mapValues, some } from "lodash";
import { getDefaultDeliverrAddress } from "parcel/screens/details/helpers";
import { fetchOperationsByOrderId } from "./fetchOperationsByOrderid";
import { EligibleShippingTypesForFbaV3 } from "./FbaV3Constants";
import {
  ShipmentTransportationConfiguration,
  ShipmentTransportationOption,
} from "../transportationOptions/state/TransportationOptionsState";

export const getFbaV3NetCost = (costs: Costs): number => {
  const fees = costs.fees.reduce((acc, fee) => acc + fee.value.amount, 0);
  const discounts = costs.discounts.reduce((acc, discount) => acc + discount.value.amount, 0);

  return fees - discounts;
};

export const getDskusFromPackingOptions = (packingOptions: PackingOption[]): string[] =>
  chain(packingOptions)
    .map((packingOption) => packingOption.packingGroups)
    .flatten()
    .map((packingGroup) => packingGroup.packingGroupItems)
    .flatten()
    .map((packingGroupItem) => packingGroupItem.dsku)
    .uniq()
    .value();

export const getConfirmedPackingOption = (packingOptions: PackingOption[]): PackingOption | undefined =>
  packingOptions.find((packingOption) => packingOption.packingOptionStatus === PackingOptionStatus.ACCEPTED);

export const getConfirmedPlacementOption = (placementOptions: PlacementOptionDto[]): PlacementOptionDto | undefined =>
  placementOptions.find((placementOption) => placementOption.placementOptionStatus === PlacementOptionStatus.ACCEPTED);

export const isOperationLoading = (operationStatus: OperationStatus): boolean =>
  [OperationStatus.CREATED, OperationStatus.IN_PROGRESS].includes(operationStatus);

export const mapTemporaryShipmentToShipment = (temporaryShipment: TemporaryShipmentOption): ShipmentResponse => ({
  shipmentId: temporaryShipment.temporaryShipmentId,
  status: ShipmentStatus.DRAFT,
  destinationWarehouseId: temporaryShipment.destination?.warehouseId ?? "FBA",
  distributionChannelShipmentId: temporaryShipment.distributionChannelShipmentId,
  createdAt: temporaryShipment.createdAt,
  updatedAt: temporaryShipment.updatedAt,
  destinationWarehouseAddress: temporaryShipment.destination?.address ?? getDefaultDeliverrAddress(),
  shipmentItems: mapTemporaryShipmentItemsToShipmentItems(temporaryShipment.shipmentItems),
});

const mapTemporaryShipmentItemsToShipmentItems = (
  temporaryShipmentItems: TemporaryShipmentOption["shipmentItems"]
): ShipmentItemResponse[] =>
  map(temporaryShipmentItems, (temporaryShipmentItem) => ({
    dsku: temporaryShipmentItem.dsku,
    unitsPerPack: temporaryShipmentItem.unitsPerPack,
    totalUnits: temporaryShipmentItem.totalUnits,
    packOf: temporaryShipmentItem.packOf,
    distributionChannelItemId: temporaryShipmentItem.distributionChannelItemId ?? temporaryShipmentItem.dsku,
  }));

export const augmentTransportationOptionsByShipmentId = (
  transportationOptions: {
    [temporaryShipmentId: string]: TransportationOption[];
  },
  shippingPartner: ShippingPartner
): {
  [temporaryShipmentId: number]: ShipmentTransportationOption[];
} =>
  mapValues(transportationOptions, (options) => {
    const optionByShippingType = chain(options)
      .map((option) => ({
        ...option,
        shippingType: [ShippingType.FTL, ShippingType.LTL].includes(option.shippingType)
          ? ShippingType.LTL
          : ShippingType.PARCEL,
      }))
      .keyBy((option) => option.shippingType)
      .value();

    return map(EligibleShippingTypesForFbaV3[shippingPartner], (shippingType) => ({
      shippingType,
      isSupported: !!optionByShippingType[shippingType],
      transportationOption: optionByShippingType[shippingType],
    }));
  });

export const getIsFbaV3ShippingPlanCreated = async (replenishmentOrderId: string, dispatch): Promise<boolean> => {
  const orderOperations: Operation<{
    orderId: string;
  }>[] = await dispatch(
    fetchOperationsByOrderId({
      orderId: replenishmentOrderId,
      operationNames: [OperationName.CREATE_PLAN_AND_FETCH_PACKING_OPTIONS],
    })
  );

  return !(isEmpty(orderOperations) || orderOperations[0]?.operationStatus === OperationStatus.FAILED);
};

export const doAllShipmentsHaveTransportationSelected = (
  chosenShipmentTransportationConfiguration: {
    [temporaryShipmentId: number]: ShipmentTransportationConfiguration;
  },
  temporaryShipmentOptions?: TemporaryShipmentOption[]
): boolean =>
  !isEmpty(temporaryShipmentOptions) &&
  temporaryShipmentOptions!.every(
    (temporaryShipmentOption) =>
      !!chosenShipmentTransportationConfiguration[temporaryShipmentOption.id] &&
      !!chosenShipmentTransportationConfiguration[temporaryShipmentOption.id].shippingPartner &&
      !!chosenShipmentTransportationConfiguration[temporaryShipmentOption.id].shippingType
  );

export const getFlattenedEntityErrors = (entityErrors: EntityError[] = []): EntityError[] => {
  const errors = [...entityErrors];

  for (const error of entityErrors) {
    if (!isEmpty(error?.errors)) {
      const nestedErrors = getFlattenedEntityErrors(error.errors);
      errors.push(...nestedErrors);
    }
  }

  return errors;
};

// Do not allow selection of amazon parcel and amazon freight estimates for different shipments in the same placement option
export const isAnyConflictingFBAV3EstimateSelected = (
  selectedTransportationConfigurations: {
    [temporaryShipmentId: number]: ShipmentTransportationConfiguration;
  },
  shippingPartner: ShippingPartner,
  estimate: ShipmentTransportationOption,
  shipmentId: number
): boolean => {
  const amazonParcelShipmentIds = entries(selectedTransportationConfigurations)
    .filter(
      ([_shipmentId, shipmentConfiguration]) =>
        shipmentConfiguration.shippingPartner === ShippingPartner.FBA &&
        shipmentConfiguration.shippingType === ShippingType.PARCEL
    )
    .map(([_shipmentId]) => parseInt(_shipmentId));

  const amazonFreightShipmentIds = entries(selectedTransportationConfigurations)
    .filter(
      ([_shipmentId, shipmentConfiguration]) =>
        shipmentConfiguration.shippingPartner === ShippingPartner.FBA &&
        (shipmentConfiguration.shippingType === ShippingType.LTL ||
          shipmentConfiguration.shippingType === ShippingType.FTL)
    )
    .map(([_shipmentId]) => parseInt(_shipmentId));

  const deliverrParcelShipmentIds = entries(selectedTransportationConfigurations)
    .filter(
      ([_shipmentId, shipmentConfiguration]) =>
        shipmentConfiguration.shippingPartner === ShippingPartner.DELIVERR &&
        shipmentConfiguration.shippingType === ShippingType.PARCEL
    )
    .map(([_shipmentId]) => parseInt(_shipmentId));

  const deliverrFreightShipmentIds = entries(selectedTransportationConfigurations)
    .filter(
      ([_shipmentId, shipmentConfiguration]) =>
        shipmentConfiguration.shippingPartner === ShippingPartner.DELIVERR &&
        (shipmentConfiguration.shippingType === ShippingType.LTL ||
          shipmentConfiguration.shippingType === ShippingType.FTL)
    )
    .map(([_shipmentId]) => parseInt(_shipmentId));

  if (shippingPartner === ShippingPartner.FBA) {
    if (estimate.shippingType === ShippingType.LTL || estimate.shippingType === ShippingType.FTL) {
      return (
        some(amazonParcelShipmentIds, (parcelShipmentId) => parcelShipmentId !== shipmentId) ||
        some(deliverrFreightShipmentIds, (freightShipmentId) => freightShipmentId !== shipmentId)
      );
    }

    if (estimate.shippingType === ShippingType.PARCEL) {
      return (
        some(amazonFreightShipmentIds, (freightShipmentId) => freightShipmentId !== shipmentId) ||
        some(deliverrParcelShipmentIds, (parcelShipmentId) => parcelShipmentId !== shipmentId)
      );
    }
  } else if (shippingPartner === ShippingPartner.DELIVERR) {
    if (estimate.shippingType === ShippingType.LTL || estimate.shippingType === ShippingType.FTL) {
      return some(amazonFreightShipmentIds, (freightShipmentId) => freightShipmentId !== shipmentId);
    }

    if (estimate.shippingType === ShippingType.PARCEL) {
      return some(amazonParcelShipmentIds, (parcelShipmentId) => parcelShipmentId !== shipmentId);
    }
  }

  return false;
};
