import {
  EntityError,
  ShippingPartner,
  ShippingType,
  TemporaryShipmentOption,
  TransportationOption,
} from "@deliverr/replenishment-client";
import { FlexDiv } from "common/components/FlexDiv";
import { Box, defaultTheme, Radio, Select, Text } from "common/components/ui";
import { map } from "lodash";
import React, { FC } from "react";
import { FbaV3Labels } from "../commons/FbaV3Labels";
import { FbaV3EntityName, FbaV3ShippingPartnerOptions } from "../commons/FbaV3Constants";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { replenishmentOrderShipmentTypeMap } from "../../common/replenishment/confirm/ReplenishmentOrderConfirmationMessages";
import { FormattedMessage } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { Amount } from "../../fba/shipping/AmazonShipmentType";
import { LightText } from "returns/create/step/ReturnsText";
import { FbaV3RetryButton } from "../commons/FbaV3RetryButton";
import { ShipmentTransportationOption, TransportationOptionSummary } from "./state/TransportationOptionsState";
import { Color } from "common/components/ui/shared/theme/color";
import styled from "@emotion/styled";

const ErrorText = styled(Text)`
  color: ${defaultTheme.colors[Color.RED]["300"]};
  padding-bottom: ${defaultTheme.spacing.S1};
`;

interface TransportationOptionsSelectProps {
  shipmentOption: TemporaryShipmentOption;
  shipmentConfiguration?: {
    shippingType?: ShippingType;
    shippingPartner?: ShippingPartner;
  };
  handleEstimateSelection: (shipmentId: number, shippingPartner?: ShippingPartner, shippingType?: ShippingType) => void;
  onRetryAfterEstimationErrorClick: (shippingPartner: ShippingPartner) => void;
  onRetryAfterRetryLimitReachedClick: (shippingPartner: ShippingPartner) => void;
  isAnyConflictingEstimateSelected: (
    shippingPartner: ShippingPartner,
    estimate: ShipmentTransportationOption,
    shipmentId: number
  ) => boolean;
  transportationOptionSummaries: Record<
    ShippingPartner.DELIVERR | ShippingPartner.FBA,
    {
      isLoading: boolean;
      isRetryLimitReached: boolean;
      isRetryOperationLoading: boolean;
      hasFetchFailed: boolean;
      transportationOptions?: TransportationOptionSummary["transportationOptions"];
    }
  >;
}

export const TransportationOptionsSelect: FC<TransportationOptionsSelectProps> = ({
  shipmentOption,
  shipmentConfiguration,
  handleEstimateSelection,
  onRetryAfterEstimationErrorClick,
  onRetryAfterRetryLimitReachedClick,
  isAnyConflictingEstimateSelected,
  transportationOptionSummaries,
}) => {
  const selectedShippingOption = FbaV3ShippingPartnerOptions.find(
    (option) => option.value === shipmentConfiguration?.shippingPartner
  );

  const selectedShippingPartner = selectedShippingOption?.value as
    | ShippingPartner.DELIVERR
    | ShippingPartner.FBA
    | undefined;

  return (
    <FlexDiv justifyContent="flex-start">
      <CarrierSelectDropdown
        shipmentOption={shipmentOption}
        selectedShippingOption={selectedShippingOption}
        handleEstimateSelection={handleEstimateSelection}
      />

      <TransportLoadingDisplay
        selectedShippingPartner={selectedShippingPartner}
        transportationOptionSummaries={transportationOptionSummaries}
      />

      <RetryLimitReachedDisplay
        selectedShippingPartner={selectedShippingPartner}
        onRetryAfterRetryLimitReachedClick={onRetryAfterRetryLimitReachedClick}
        transportationOptionSummaries={transportationOptionSummaries}
      />

      <TransportationFetchError
        selectedShippingPartner={selectedShippingPartner}
        onRetryAfterEstimationErrorClick={onRetryAfterEstimationErrorClick}
        transportationOptionSummaries={transportationOptionSummaries}
      />

      {selectedShippingPartner &&
      !transportationOptionSummaries[selectedShippingPartner]?.isLoading &&
      !transportationOptionSummaries[selectedShippingPartner]?.isRetryLimitReached &&
      !transportationOptionSummaries[selectedShippingPartner]?.hasFetchFailed
        ? map(
            transportationOptionSummaries[selectedShippingPartner]?.transportationOptions?.[
              shipmentOption.temporaryShipmentId
            ],
            (estimate: ShipmentTransportationOption) => (
              <Box width={"250px"} marginLeft={"S7"} marginRight={"S4"}>
                <Radio
                  value={estimate.shippingType}
                  disabled={
                    !estimate.isSupported ||
                    isAnyConflictingEstimateSelected(selectedShippingPartner, estimate, shipmentOption.id)
                  }
                  label={getTransportationOptionLabel(estimate)}
                  onClick={(evt) =>
                    handleEstimateSelection(
                      shipmentOption.id,
                      selectedShippingPartner,
                      evt.currentTarget.value as ShippingType
                    )
                  }
                  checked={shipmentConfiguration?.shippingType === estimate.shippingType}
                />
                <LightText>{getTransportationOptionDescription(estimate)}</LightText>
              </Box>
            )
          )
        : null}
    </FlexDiv>
  );
};

const getTransportationOptionLabel = (estimate: ShipmentTransportationOption) => (
  <Text bold>
    <FormattedMessage {...replenishmentOrderShipmentTypeMap[estimate.shippingType]} />
  </Text>
);

const getTransportationOptionDescription = (estimate: {
  shippingType: ShippingType;
  isSupported?: boolean;
  error?: EntityError;
  transportationOption: TransportationOption;
}) => {
  if (!estimate.isSupported) {
    return <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].unsupportedTransportationOption} />;
  } else if (estimate.error) {
    return (
      <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].transportationOptionErrorDescription} />
    );
  } else {
    return (
      <FormattedMessage
        {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.estimatedShippingCost}
        values={{
          formattedAmount: <Amount value={estimate.transportationOption.cost?.amount ?? 0} />,
        }}
      />
    );
  }
};

const CarrierSelectDropdown: React.FC<{
  shipmentOption: TemporaryShipmentOption;
  selectedShippingOption?: { value: ShippingPartner; label: JSX.Element };
  handleEstimateSelection: (shipmentId: number, shippingPartner?: ShippingPartner) => void;
}> = ({ shipmentOption, selectedShippingOption, handleEstimateSelection }) => (
  <Box width={"150px"}>
    <Box paddingBottom={"S1"}>
      <Text bold>
        <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].chooseCarrier} />
      </Text>
    </Box>

    <Select
      onChange={(shippingOption) => handleEstimateSelection(shipmentOption.id, shippingOption.value)}
      value={selectedShippingOption}
      options={FbaV3ShippingPartnerOptions}
      isSearchable
    />
  </Box>
);

const TransportLoadingDisplay: React.FC<{
  selectedShippingPartner: ShippingPartner.FBA | ShippingPartner.DELIVERR | undefined;
  transportationOptionSummaries: Record<
    ShippingPartner.DELIVERR | ShippingPartner.FBA,
    {
      isLoading: boolean;
    }
  >;
}> = ({ selectedShippingPartner, transportationOptionSummaries }) => (
  <>
    {selectedShippingPartner && transportationOptionSummaries[selectedShippingPartner].isLoading ? (
      <Box width={"225px"} marginLeft={"S7"} marginRight={"S3"} marginTop={"S2"}>
        <FlexDiv>
          <LightText>
            <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].loadingDescription} />
          </LightText>

          <Box paddingRight={"S1"} paddingLeft={"S2"}>
            <LoadingSpinner size="medium" />
          </Box>
        </FlexDiv>
      </Box>
    ) : null}
  </>
);

const RetryLimitReachedDisplay: React.FC<{
  selectedShippingPartner: ShippingPartner.FBA | ShippingPartner.DELIVERR | undefined;
  onRetryAfterRetryLimitReachedClick: (shippingPartner: ShippingPartner) => void;
  transportationOptionSummaries: Record<
    ShippingPartner.DELIVERR | ShippingPartner.FBA,
    {
      isRetryLimitReached: boolean;
    }
  >;
}> = ({ selectedShippingPartner, onRetryAfterRetryLimitReachedClick, transportationOptionSummaries }) => (
  <>
    {selectedShippingPartner && transportationOptionSummaries[selectedShippingPartner].isRetryLimitReached ? (
      <Box marginLeft={"S5"}>
        <ErrorText>
          <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].retryExhaustedDescription} />
        </ErrorText>

        <FbaV3RetryButton
          entityName={FbaV3EntityName.TRANSPORTATION_OPTION}
          onRetryClick={() => onRetryAfterRetryLimitReachedClick(selectedShippingPartner)}
          isRetryLoading={false}
          useFlex
        />
      </Box>
    ) : null}
  </>
);

const TransportationFetchError: React.FC<{
  selectedShippingPartner: ShippingPartner.FBA | ShippingPartner.DELIVERR | undefined;
  onRetryAfterEstimationErrorClick: (shippingPartner: ShippingPartner) => void;
  transportationOptionSummaries: Record<
    ShippingPartner.DELIVERR | ShippingPartner.FBA,
    {
      hasFetchFailed: boolean;
      isRetryOperationLoading: boolean;
    }
  >;
}> = ({ selectedShippingPartner, onRetryAfterEstimationErrorClick, transportationOptionSummaries }) => (
  <>
    {selectedShippingPartner && transportationOptionSummaries[selectedShippingPartner].hasFetchFailed ? (
      <Box marginLeft={"S5"}>
        <FlexDiv>
          <ErrorText>
            <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].fetchFailureDescription} />
          </ErrorText>

          <FbaV3RetryButton
            entityName={FbaV3EntityName.TRANSPORTATION_OPTION}
            onRetryClick={() => onRetryAfterEstimationErrorClick(selectedShippingPartner)}
            isRetryLoading={transportationOptionSummaries[selectedShippingPartner]?.isRetryOperationLoading}
          />
        </FlexDiv>
      </Box>
    ) : null}
  </>
);
