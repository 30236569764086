import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getPlacementOptionsData, getTransportationOptionsData } from "../commons/FbaV3Selectors";
import { useLoader } from "common/components/WithLoader/useLoader";
import {
  FbaV3EntityName,
  FbaV3Loaders,
  PLACEMENT_OPTIONS_FETCH_MAX_RETRIES,
  PLACEMENT_OPTIONS_POLLING_INTERVAL_MS,
} from "../commons/FbaV3Constants";
import { PlacementOptionStatus } from "@deliverr/replenishment-client";
import {
  resetPlacementOptionsState,
  resetSinglePlacementOptionState,
  setSelectedPlacementOptionId,
} from "./actions/PlacementOptionsActions";
import { usePolling } from "common/hooks/usePolling";
import { getPlacementOptions } from "./actions/getPlacementOptions";
import {
  doAllShipmentsHaveTransportationSelected,
  getConfirmedPlacementOption,
  isOperationLoading,
} from "../commons/FbaV3Utils";
import { isEmpty, isNil, some } from "lodash";
import { retryFbaOperation } from "../commons/retryFbaOperation";
import { CheckableTileGroupOptionProps } from "common/components/ui";
import { PlacementOptionTile } from "./PlacementOptionTile";

export const usePlacementOptions = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isPlacementOptionSelectDisabled, setIsPlacementOptionSelectDisabled] = useState<boolean>(false);

  const {
    placementOptions,
    placementOptionsErrors,
    placementOptionsFetchOperationStatus,
    placementOptionsFetchOperationId,
    selectedPlacementOptionId,
  } = useSelector(getPlacementOptionsData);

  const { selectedTransportationConfigurations } = useSelector(getTransportationOptionsData);

  const isRetryOperationLoading = useLoader(FbaV3Loaders.RETRY_OPERATION);

  const shouldPollForPlacementOptions = useMemo(
    () => isOperationLoading(placementOptionsFetchOperationStatus),
    [placementOptionsFetchOperationStatus]
  );

  const shouldPollForShipmentDetails = useMemo(
    () =>
      some(placementOptions, (placementOption) =>
        isOperationLoading(placementOption.shipmentList.operationSummary.operationStatus)
      ),
    [placementOptions]
  );

  const shouldPoll = shouldPollForPlacementOptions || shouldPollForShipmentDetails;

  const { retries, resetStates } = usePolling(
    PLACEMENT_OPTIONS_POLLING_INTERVAL_MS,
    shouldPoll,
    () => dispatch(getPlacementOptions()),
    PLACEMENT_OPTIONS_FETCH_MAX_RETRIES
  );

  const restartPollingPlacementOptions = useCallback(() => {
    dispatch(resetPlacementOptionsState());
    resetStates();
  }, [dispatch, resetStates]);

  const restartPollingSinglePlacementOption = useCallback(
    (placementOptionId: number) => {
      dispatch(resetSinglePlacementOptionState(placementOptionId));
      resetStates();
    },
    [dispatch, resetStates]
  );

  const hasRetryLimitReached = retries >= PLACEMENT_OPTIONS_FETCH_MAX_RETRIES;

  const arePlacementOptionsLoading = shouldPollForPlacementOptions && !hasRetryLimitReached;

  const confirmedPlacementOption = useMemo(() => getConfirmedPlacementOption(placementOptions), [placementOptions]);

  const isAnyPlacementOptionExpired = useMemo(
    () =>
      !!placementOptions?.find(
        (placementOption) => placementOption.placementOptionStatus === PlacementOptionStatus.EXPIRED
      ),
    [placementOptions]
  );

  useEffect(() => {
    if (!isNil(confirmedPlacementOption)) {
      setIsPlacementOptionSelectDisabled(true);
      dispatch(setSelectedPlacementOptionId(confirmedPlacementOption.id));
    } else if (isAnyPlacementOptionExpired) {
      setIsPlacementOptionSelectDisabled(true);
    }
  }, [placementOptions, isAnyPlacementOptionExpired, confirmedPlacementOption, dispatch, selectedPlacementOptionId]);

  const handlePlacementOptionChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(setSelectedPlacementOptionId(Number(event.target.value)));
    },
    [dispatch]
  );

  const onRetryAfterErrorClick = useCallback(
    (entityName: FbaV3EntityName, operationId?: number, placementOptionId?: number) => {
      if (!isNil(operationId)) {
        dispatch(
          retryFbaOperation({
            operationId,
            postRetryAction: !isNil(placementOptionId)
              ? () => restartPollingSinglePlacementOption(placementOptionId)
              : restartPollingPlacementOptions,
            entityName,
          })
        );
      } else {
        restartPollingPlacementOptions();
      }
    },
    [dispatch, restartPollingPlacementOptions, restartPollingSinglePlacementOption]
  );

  const onRetryPlacementOptionFetch = () =>
    onRetryAfterErrorClick(FbaV3EntityName.PLACEMENT_OPTION, placementOptionsFetchOperationId);

  const placementOptionTiles: CheckableTileGroupOptionProps[] = useMemo(
    () =>
      placementOptions?.map((placementOption, idx) => ({
        id: placementOption.id.toString(),
        value: placementOption.id,
        content: <PlacementOptionTile placementOption={placementOption} key={placementOption.id} index={idx + 1} />,
      })) ?? [],
    [placementOptions]
  );

  const selectedPlacementOption = useMemo(
    () => placementOptions?.find((placementOption) => placementOption.id === selectedPlacementOptionId),
    [placementOptions, selectedPlacementOptionId]
  );

  const isShippingConfigurationSelectionComplete =
    !isNil(selectedPlacementOptionId) &&
    !isEmpty(selectedTransportationConfigurations[selectedPlacementOptionId]) &&
    doAllShipmentsHaveTransportationSelected(
      selectedTransportationConfigurations[selectedPlacementOptionId],
      selectedPlacementOption?.shipmentList.temporaryShipments
    );

  const shouldShowExpiryNotification = isAnyPlacementOptionExpired && isNil(confirmedPlacementOption);
  const isNextDisabled = !isShippingConfigurationSelectionComplete;

  return {
    placementOptionTiles: hasRetryLimitReached && shouldPoll ? [] : placementOptionTiles,
    placementOptionsErrors,
    isRetryOperationLoading,
    arePlacementOptionsLoading,
    isPlacementOptionSelectDisabled,
    selectedPlacementOptionId,
    isShippingConfigurationSelectionComplete,
    hasRetryLimitReached,
    shouldShowExpiryNotification,
    selectedPlacementOption,
    isNextDisabled,
    onRetryAfterErrorClick,
    handlePlacementOptionChange,
    onRetryAfterRetryLimitReachedClick: restartPollingPlacementOptions,
    formatMessage,
    onRetryPlacementOptionFetch,
  };
};
