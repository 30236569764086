import { compact, isEmpty, isNil, keys, some } from "lodash";
import { getPackingOptionsData, getPlacementOptionsData } from "./commons/FbaV3Selectors";
import { PackingOptions } from "./packingOptions/PackingOptions";
import { getConfirmedPackingOption } from "./commons/FbaV3Utils";
import { confirmPackingOption } from "./packingOptions/actions/confirmPackingOption";
import { Step } from "common/components/StepContainer";
import { PlacementOptions } from "./placementOptions/PlacementOptions";
import { ReplenishmentOrderConfirmation } from "../common/replenishment/confirm/ReplenishmentOrderConfirmation";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { OrderStatus, PlacementOptionStatus } from "@deliverr/replenishment-client";
import { createOrUpdateReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { submitReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/submitReplenishmentOrder";
import { TransferSelectProductQuantityStep } from "../fba/TransferSelectProductQuantityStep";
import { TransferSelectPrepTypeStep } from "prep/create/ReplenishmentPrep/TransferSelectPrepTypeStep";
import { isFNSKUOrASINBarcode } from "transfers/utils/isFNSKUBarcode";
import { ReplenishmentOrderCreationError } from "../common/replenishment/errors/ReplenishmentOrderCreationError";
import { TransferAddAmazonBarcodesForUnitPrep } from "prep/create/ReplenishmentPrep/TransferAddAmazonBarcodesForUnitPrep";
import { FbaV2Steps } from "transfers/create/fbaV2Steps";
import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { PrepOption } from "@deliverr/business-types";
import { confirmPlacementOption } from "./placementOptions/actions/confirmPlacementOption";
import { getPlacementOptions } from "./placementOptions/actions/getPlacementOptions";
import { PLACEMENT_OPTION_CONFIRMATION_FAILURE_DELAY } from "./commons/FbaV3Constants";

// Only enable unit prep for Perez and test storage warehouse
const RS_UNIT_PREP_WAREHOUSES = ["STO_IRM_PER", "STO_TST_LOG", "EWR1RS", "STO_CUS_LAX", "LAX1RS", "DFW1RS"];

export enum FbaV3IntegrationSteps {
  SelectQuantities = "fbaIntegration/v3/SelectQuantities",
  SelectPrepType = "fbaIntegration/v3/SelectPrepType",
  OrderCreationError = "fbaIntegration/v3/OrderCreationError",
  SelectPackingOption = "fbaIntegration/v3/SelectPackingOption",
  SelectPlacementOption = "fbaIntegration/v3/SelectPlacementOption",
  AddAmazonMissingBarcodesUnitPrep = "fbaIntegration/v3/AddAmazonMissingBarcodesUnitPrep",
  ConfirmOrder = "fbaIntegration/v3/ConfirmOrder",
  Confirmed = "fbaIntegration/v3/Confirmed",
}

export const fbaV3IntegrationSteps: (
  dispatch,
  getState,
  isFbaUnitPrepOn: boolean
) => Record<FbaV3IntegrationSteps, Step> = (dispatch, getState, isFbaUnitPrepOn) => ({
  [FbaV3IntegrationSteps.SelectQuantities]: {
    component: TransferSelectProductQuantityStep,
    next: async () => {
      const { originStorageWarehouse } = selectTransferCreate(getState());
      // If unit prep is on, create replenishment order after prep selection
      if (
        isFbaUnitPrepOn &&
        originStorageWarehouse?.warehouse?.id &&
        !!RS_UNIT_PREP_WAREHOUSES.includes(originStorageWarehouse?.warehouse.id)
      ) {
        return FbaV3IntegrationSteps.SelectPrepType;
      }

      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false, true)) {
        return FbaV3IntegrationSteps.SelectPackingOption;
      } else {
        return FbaV3IntegrationSteps.OrderCreationError;
      }
    },
    reassess: () => {
      const { selectedProducts } = selectTransferCreate(getState());
      if (Object.keys(selectedProducts).length === 0) {
        return FbaV2Steps.SelectProduct;
      }
      return;
    },
  },
  [FbaV3IntegrationSteps.AddAmazonMissingBarcodesUnitPrep]: {
    component: TransferAddAmazonBarcodesForUnitPrep,
    hasNoBack: true,
    next: async () => {
      return FbaV3IntegrationSteps.SelectPackingOption;
    },
  },
  [FbaV3IntegrationSteps.SelectPrepType]: {
    component: TransferSelectPrepTypeStep,
    next: async () => {
      const { selectedProducts, prepOption } = selectTransferCreate(getState());
      // call API to update FBA prep option
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false, true)) {
        return prepOption === PrepOption.UNIT_PREP &&
          Object.values(selectedProducts).some(({ barcodes }) => !barcodes?.find(isFNSKUOrASINBarcode))
          ? FbaV3IntegrationSteps.AddAmazonMissingBarcodesUnitPrep
          : FbaV3IntegrationSteps.SelectPackingOption;
      } else {
        return FbaV3IntegrationSteps.OrderCreationError;
      }
    },
  },
  [FbaV3IntegrationSteps.OrderCreationError]: {
    component: ReplenishmentOrderCreationError,
    hasNoBack: true,
    next: async () => {
      const { selectedProducts, prepOption } = selectTransferCreate(getState());

      if (isEmpty(compact(keys(selectedProducts)))) {
        return FbaV2Steps.SelectProduct;
      }

      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false, true)) {
        return prepOption === PrepOption.UNIT_PREP &&
          Object.values(selectedProducts).some(({ barcodes }) => !barcodes?.find(isFNSKUOrASINBarcode))
          ? FbaV3IntegrationSteps.AddAmazonMissingBarcodesUnitPrep
          : FbaV3IntegrationSteps.SelectPackingOption;
      }
      return;
    },
  },
  [FbaV3IntegrationSteps.SelectPackingOption]: {
    component: PackingOptions,
    next: async () => {
      const { packingOptions, selectedPackingOptionId } = getPackingOptionsData(getState());

      if (
        !isNil(getConfirmedPackingOption(packingOptions)) ||
        (await dispatch(confirmPackingOption(selectedPackingOptionId!)))
      ) {
        return FbaV3IntegrationSteps.SelectPlacementOption;
      }

      return undefined;
    },
  },
  [FbaV3IntegrationSteps.SelectPlacementOption]: {
    component: PlacementOptions,
    next: async () => {
      return FbaV3IntegrationSteps.ConfirmOrder;
    },
  },
  [FbaV3IntegrationSteps.ConfirmOrder]: {
    component: ReplenishmentOrderConfirmation,
    next: async () => {
      const { replenishmentOrder } = selectTransferCreate(getState());
      const { placementOptions } = getPlacementOptionsData(getState());

      const hasConfirmedOption = some(
        placementOptions,
        ({ placementOptionStatus }) => placementOptionStatus === PlacementOptionStatus.ACCEPTED
      );

      const submitOrder = async () => {
        if (replenishmentOrder?.orderStatus === OrderStatus.DRAFT) {
          if (!hasConfirmedOption) {
            const confirmed = await dispatch(confirmPlacementOption());

            if (!confirmed) {
              await new Promise((resolve) => setTimeout(resolve, PLACEMENT_OPTION_CONFIRMATION_FAILURE_DELAY));
              await dispatch(getPlacementOptions());
              return false;
            }

            await dispatch(getPlacementOptions());
          }

          const isOrderCreated = await createOrUpdateReplenishmentOrder(dispatch, getState, true, false);
          if (!isOrderCreated) {
            return false;
          }

          return await submitReplenishmentOrder(dispatch, getState);
        } else if (replenishmentOrder?.orderStatus === OrderStatus.CREATED) {
          return await submitReplenishmentOrder(dispatch, getState);
        }

        return false;
      };

      const isSubmitted = await submitOrder();

      if (isSubmitted) {
        return FbaV3IntegrationSteps.Confirmed;
      }

      return;
    },
  },
  [FbaV3IntegrationSteps.Confirmed]: {
    component: SharedConfirmedStep(transfersShipmentCreateLabels),
    hasNoBack: true,
  },
});
