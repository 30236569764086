import { defineMessages } from "react-intl";

export const wholesaleCreateLabels = {
  steps: {
    address: defineMessages({
      title: {
        id: "wholesale.create.steps.address.title",
        defaultMessage: "Add the destination address",
      },
      poNumberPlaceholder: {
        id: "wholesale.create.steps.address.poNumberPlaceholder",
        defaultMessage: "PO # (Optional)",
      },
      validationError: {
        id: "wholesale.create.steps.address.validationError",
        defaultMessage: "Enter a valid address",
      },
      companyNameError: {
        id: "wholesale.create.steps.address.companyNameError",
        defaultMessage: "Company name cannot be empty and must be at least 3 characters long",
      },
    }),
    products: defineMessages({
      title: {
        id: "wholesale.create.steps.products",
        defaultMessage: "Select products",
      },
      errorNotificationTitle: {
        id: "wholesale.create.steps.products.errorNotification",
        defaultMessage: "All products are not available at the same location.",
      },
      errorNotificationDescription: {
        id: "wholesale.create.steps.products.errorNotificationDescription",
        defaultMessage:
          "Order creation from multiple locations is not yet supported. Orders for products available at different locations should be created separately.",
      },
    }),
    origin: defineMessages({
      title: {
        id: "wholesale.create.steps.origin.title",
        defaultMessage: "Select origin",
      },
      subtitle: {
        id: "wholesale.create.steps.origin.subtitle",
        defaultMessage: "Select a Reserve Storage location with available inventory for your selected products.",
      },
      options: {
        productAvailable: {
          id: "wholesale.create.steps.origin.option.available",
          defaultMessage: "{qty} units available for selected products",
        },
        productUnavailable: {
          id: "wholesale.create.steps.origin.option.available",
          defaultMessage: "All selected products are not available at this location",
        },
      },
    }),
    productQuantity: defineMessages({
      title: {
        id: "wholesale.create.steps.productQuantity.title",
        defaultMessage: "Select cases",
      },
      subtitle: {
        id: "wholesale.create.steps.productQuantity.subtitle",
        defaultMessage: "Select products with available inventory in Flexport Storage",
      },
    }),
    labels: defineMessages({
      title: {
        id: "wholesale.create.steps.labels.title",
        defaultMessage: "Add any relevant shipment information",
      },
      subtitle: {
        id: "wholesale.create.steps.labels.subtitle",
        defaultMessage: "(Optional)",
      },
      boxLabels: {
        id: "wholesale.create.steps.labels.boxLabels",
        defaultMessage: "Box labels",
      },
      vendorGuidelines: {
        id: "wholesale.create.steps.labels.vendorGuidelines",
        defaultMessage: "Vendor guidelines",
      },
      palletLabels: {
        id: "wholesale.create.steps.labels.palletLabels",
        defaultMessage: "Pallet labels",
      },
      packingSlip: {
        id: "wholesale.create.steps.labels.packingSlip",
        defaultMessage: "Packing slip",
      },
      additionalNotes: {
        id: "wholesale.create.steps.labels.additionalNotes",
        defaultMessage: "Additional notes",
      },
    }),

    confirmed: defineMessages({
      title: {
        id: "wholesale.create.steps.confirmed.title",
        defaultMessage: "Order Confirmed",
      },
      subtitle: {
        id: "wholesale.create.steps.confirmed.subtitle",
        defaultMessage: "You're all set. We'll keep you updated on the status of your order.",
      },
    }),
    confirm: defineMessages({
      title: {
        id: "wholesale.create.steps.confirm.title",
        defaultMessage: "Confirm your order",
      },
      reserveStorage: {
        id: "wholesale.create.steps.confirm.reserveStorage",
        defaultMessage: "Reserve Storage",
      },
      shippingMethod: {
        id: "wholesale.create.steps.confirm.shippingMethod",
        defaultMessage: "{cargo} with {partner}",
      },
      shippingPallet: {
        id: "wholesale.create.steps.confirm.shippingPallet",
        defaultMessage: "LTL/FTL",
      },
      shippingParcel: {
        id: "wholesale.create.steps.confirm.shippingParcel",
        defaultMessage: "Small parcel",
      },
      shippingDeliverr: {
        id: "wholesale.create.steps.confirm.shippingDeliverr",
        defaultMessage: "Flexport partner",
      },
      shippingSeller: {
        id: "wholesale.create.steps.confirm.shippingSeller",
        defaultMessage: "your carrier",
      },
      nextButtonCaption: {
        id: "wholesale.create.steps.confirm.nextButtonCaption",
        defaultMessage: "Confirm",
      },
      error: {
        id: "wholesale.create.steps.confirm.error",
        defaultMessage: "An error occurred when contacting the server.",
      },
      costs: {
        shipmentQuantity: {
          id: "wholesale.create.steps.confirm.costs.shipmentQuantity",
          defaultMessage: `{qty, plural,
            one {# shipment}
            other {# shipments}
          }`,
        },
      },
      totalRowLabel: {
        id: "transfers.create.steps.confirm.totalRowLabel",
        defaultMessage: "Estimated total",
      },
    }),
    shipping: {
      ...defineMessages({
        title: {
          id: "wholesale.create.steps.shipping.title",
          defaultMessage: "Select a shipping method",
        },
        selectCarrier: {
          id: "wholesale.create.steps.shipping.selectCarrier",
          defaultMessage: "Select carrier",
        },
        selectCargoType: {
          id: "wholesale.create.steps.shipping.selectCargoType",
          defaultMessage: "Select cargo type",
        },
        estimationInProgress: {
          id: "wholesale.create.steps.shipping.estimationInProgress",
          defaultMessage: "Estimation in progress...",
        },
        estimationError: {
          id: "transfers.create.steps.shipping.estimationError",
          defaultMessage:
            "A quote could not be generated. Our support team will contact you with a final quote once you confirm your order.",
        },
        freightEstimationErrorForLessWeight: {
          id: "transfers.create.steps.shipping.freightEstimationErrorForLessWeight",
          defaultMessage: "Minimum weight should be {minimumWeight}lbs",
        },
        selectShipBy: defineMessages({
          label: {
            id: "wholesale.create.steps.shipping.selectShipBy.label",
            defaultMessage: "Ship by date",
          },
          toolTip: {
            id: "wholesale.create.steps.shipping.selectShipBy.tooltip",
            defaultMessage:
              "The order will ship on this date or one business day earlier. Select a date up to 30 business days in the future.",
          },
          suffixMessage: {
            id: "wholesale.create.steps.shipping.selectShipBy.suffixMessage",
            defaultMessage: "(As soon as possible)",
          },
        }),
      }),
      options: {
        deliverr: defineMessages({
          label: {
            id: "wholesale.create.steps.shipping.options.deliverr.label",
            defaultMessage: "Flexport Partner",
          },
          description: {
            id: "wholesale.create.steps.shipping.options.deliverr.description",
            defaultMessage: "Hassle free, transparent transportation",
          },
          disabledOptionDescription: {
            id: "wholesale.create.steps.shipping.options.deliverr.disabledOptionDescription",
            defaultMessage: "Not yet available for international addresses",
          },
        }),
        yourCarrier: defineMessages({
          label: {
            id: "wholesale.create.steps.shipping.options.yourCarrier.label",
            defaultMessage: "Your carrier",
          },
          description: {
            id: "wholesale.create.steps.shipping.options.yourCarrier.description",
            defaultMessage: "Use your own transportation",
          },
        }),
      },
      cargoOptions: {
        pallet: defineMessages({
          label: {
            id: "wholesale.create.steps.shipping.cargoOptions.pallet.label",
            defaultMessage: "LTL/FTL (Palletized)",
            disableddDescription: "Not yet available for international addresses",
          },
          disableddDescription: {
            id: "wholesale.create.steps.shipping.cargoOptions.pallet.disableddDescription",
            defaultMessage: "Not yet available for international addresses",
          },
          descriptionXEstimatedTotal: {
            id: "wholesale.create.steps.shipping.cargoOptions.pallet.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total",
          },
        }),
        parcel: defineMessages({
          label: {
            id: "wholesale.create.steps.shipping.cargoOptions.parcel.label",
            defaultMessage: "Small parcel (Individual boxes)",
          },
          descriptionXEstimatedTotal: {
            id: "wholesale.create.steps.shipping.cargoOptions.parcel.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total",
          },
          errMSg: {
            id: "wholesale.create.steps.shipping.cargoOptions.parcel.errMSg",
            defaultMessage: "Small parcel option is not available for your selected box count or weights.",
          },
        }),
      },
      parcelNotAvailable: {
        id: "wholesale.create.steps.shipping.parcelNotAvailable",
        defaultMessage: "Small parcel option is not available for your selected dims and weights.",
      },
      palletNoEstimate: {
        id: "wholesale.create.steps.shipping.palletNoEstimate",
        defaultMessage: "Our support team will contact you with a final quote once you confirm the order.",
      },
      contactInfo: {
        id: "wholesale.create.steps.shipping.contactInfo",
        defaultMessage: "We'll contact you when the shipment is ready for pickup.",
      },
      addDocuments: {
        id: "wholesale.create.steps.shipping.addDocuments",
        defaultMessage: "Add required documents",
      },
      addLabels: {
        id: "wholesale.create.steps.shipping.addLabels",
        defaultMessage: "Shipping labels",
      },
      addCommercialInvoice: {
        id: "wholesale.create.steps.shipping.addCommercialInvoice",
        defaultMessage: "Commercial invoice",
      },
      commercialInvoiceToolTip: {
        id: "wholesale.create.steps.shipping.commercialInvoiceToolTip",
        defaultMessage: "This is required for international shipments. {learnMoreLink}",
      },
      labelProvider: defineMessages({
        title: {
          id: "wholesale.create.steps.shipping.labelProvider.title",
          defaultMessage: "Select label provider",
        },
        labelUpload: defineMessages({
          title: {
            id: "wholesale.create.steps.shipping.labelProvider.labelUpload",
            defaultMessage: "Upload your own labels",
          },
          description: {
            id: "wholesale.create.steps.shipping.labelProvider.labelUpload.content",
            defaultMessage: "You can upload the labels purchased from carrier.",
          },
        }),
        carrierAccount: {
          id: "wholesale.create.steps.shipping.labelProvider.carrierAccount",
          defaultMessage: "Carrier Account or Collect",
        },
        addCarrierAccount: {
          id: "wholesale.create.steps.shipping.labelProvider.addCarrierAccount",
          defaultMessage: "Add Carrier Account",
        },
        editCarrierAccount: {
          id: "wholesale.create.steps.shipping.labelProvider.editCarrierAccount",
          defaultMessage: "Edit Carrier Account",
        },
        carrier: {
          id: "wholesale.create.steps.shipping.labelProvider.carrier",
          defaultMessage: "Carrier",
        },
        accountNumber: {
          id: "wholesale.create.steps.shipping.labelProvider.accountNumber",
          defaultMessage: "Account Number",
        },
        postalCode: {
          id: "wholesale.create.steps.shipping.labelProvider.postalCode",
          defaultMessage: "Postal Code",
        },
        displayName: {
          id: "wholesale.create.steps.shipping.labelProvider.displayName",
          defaultMessage: "Display Name",
        },
        selectCarrier: {
          id: "wholesale.create.steps.shipping.labelProvider.selectCarrier",
          defaultMessage: "Search and select",
        },
        deleteCarrierAccount: defineMessages({
          title: {
            id: "wholesale.create.steps.shipping.labelProvider.deleteCarrierAccount.title",
            defaultMessage: "Delete Carrier Account",
          },
          description: {
            id: "wholesale.create.steps.shipping.labelProvider.deleteCarrierAccount.description",
            defaultMessage: "Are you sure you want to delete carrier account for {displayName}? This can’t be undone.",
          },
          cancel: {
            id: "wholesale.create.steps.shipping.labelProvider.deleteCarrierAccount.cancel",
            defaultMessage: "Cancel",
          },
          delete: {
            id: "wholesale.create.steps.shipping.labelProvider.deleteCarrierAccount.delete",
            defaultMessage: "Delete",
          },
        }),
        collectOption: {
          id: "wholesale.create.steps.shipping.labelProvider.collectOption",
          defaultMessage: "COLLECT (FedEx only)",
        },
      }),
    },
  },
};
