import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { batch } from "react-redux";
import { resetPackingOptionsState, setPackingOptions, setPackingOptionsError } from "./PackingOptionsActions";
import { EntityError, OperationStatus } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { replenishmentClient } from "Clients";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";

export const getPackingOptions = (): SPThunkAction => async (dispatch, getState) => {
  batch(() => {
    dispatch(addLoader(FbaV3Loaders.FETCH_PACKING_OPTIONS));
    dispatch(resetPackingOptionsState());
  });

  try {
    const { replenishmentOrder } = selectTransferCreate(getState());
    const { value: packingOptionsResponse } = await replenishmentClient.getPackingOptions(replenishmentOrder!.orderId);

    if (packingOptionsResponse.operationSummary.operationStatus === OperationStatus.FAILED) {
      dispatch(
        setPackingOptionsError(
          getFlattenedEntityErrors(packingOptionsResponse.operationSummary.operationErrors),
          packingOptionsResponse.operationSummary.operationStatus,
          packingOptionsResponse.operationSummary.operationId
        )
      );
    } else {
      dispatch(
        setPackingOptions(
          packingOptionsResponse.packingOptions,
          packingOptionsResponse.operationSummary.operationStatus,
          packingOptionsResponse.operationSummary.operationId
        )
      );
    }
  } catch (err) {
    const error = err?.response?.data?.error ?? err;
    logError({ fn: "getPackingOptions" }, err, "Failed to fetch packing options");

    error instanceof EntityError
      ? dispatch(
          setPackingOptionsError(getFlattenedEntityErrors([error]), OperationStatus.FAILED, error?.payload?.operationId)
        )
      : dispatch(
          setPackingOptionsError(
            [
              new EntityError({
                message: error?.message,
                code: error?.code,
              }),
            ],
            OperationStatus.FAILED,
            undefined
          )
        );
  } finally {
    dispatch(clearLoader(FbaV3Loaders.FETCH_PACKING_OPTIONS));
  }
};
