import { defineMessages, MessageDescriptor } from "react-intl";
import { FbaV3EntityName } from "./FbaV3Constants";

export const FbaV3Labels = {
  [FbaV3EntityName.PACKING_OPTION]: defineMessages({
    loadingTitle: {
      id: "replenishment.fbaV3.packingOptions.loadingTitle",
      defaultMessage: "Generating packing options from Amazon",
    },
    loadingDescription: {
      id: "replenishment.fbaV3.packingOptions.loadingDescription",
      defaultMessage: "This may take a couple minutes.",
    },
    fetchFailureTitle: {
      id: "replenishment.fbaV3.packingOptions.fetchFailureTitle",
      defaultMessage: "Failed to fetch packing options from Amazon",
    },
    retryExhaustedDescription: {
      id: "replenishment.fbaV3.packingOptions.retryExhaustedDescription",
      defaultMessage:
        "Our system failed to fetch the packing options from Amazon after multiple attempts.{br}You can try again or check back later.",
    },
    retryButtonDescription: {
      id: "replenishment.fbaV3.packingOptions.retryButtonDescription",
      defaultMessage: "Click here to Retry",
    },
    netOptionCost: {
      id: "replenishment.fbaV3.packingOptions.netOptionCost",
      defaultMessage: "Amazon fees: ${netCost}",
    },
    expiryDescription: {
      id: "replenishment.fbaV3.packingOptions.expiryDescription",
      defaultMessage:
        "One or more packing options have expired for the order. Please reload the page or <a>Contact Support</a> if this issue persists",
    },
    expiryTitle: {
      id: "replenishment.fbaV3.packingOptions.expiryTitle",
      defaultMessage: "Packing options expired",
    },
    fetchFailureDescription: {
      id: "replenishment.fbaV3.packingOptions.fetchFailureDescription",
      defaultMessage: "Packing options could not be generated due to the reasons below. Please try again",
    },
    retryOperationFailure: {
      id: "replenishment.fbaV3.packingOptions.retryOperationFailure",
      defaultMessage: "Failed to retry fetching packing options. Please try again",
    },
    learnMore: {
      id: "replenishment.fbaV3.packingOptions.learnMore",
      defaultMessage: "{learnMore} about packing option configurations",
    },
  }),
  [FbaV3EntityName.PLACEMENT_OPTION]: defineMessages({
    loadingTitle: {
      id: "replenishment.fbaV3.placementOptions.loadingTitle",
      defaultMessage: "Generating placement options from Amazon",
    },
    loadingDescription: {
      id: "replenishment.fbaV3.placementOptions.loadingDescription",
      defaultMessage: "This may take a couple minutes.",
    },
    fetchFailureTitle: {
      id: "replenishment.fbaV3.placementOptions.fetchFailureTitle",
      defaultMessage: "Failed to fetch placement options from Amazon",
    },
    retryExhaustedDescription: {
      id: "replenishment.fbaV3.placementOptions.retryExhaustedDescription",
      defaultMessage:
        "Our system failed to fetch the placement options from Amazon after multiple attempts.{br}You can try again or check back later.",
    },
    retryButtonDescription: {
      id: "replenishment.fbaV3.placementOptions.retryButtonDescription",
      defaultMessage: "Click here to Retry",
    },
    netOptionCost: {
      id: "replenishment.fbaV3.placementOptions.netOptionCost",
      defaultMessage: "${netCost} placement fee",
    },
    expiryDescription: {
      id: "replenishment.fbaV3.placementOptions.expiryDescription",
      defaultMessage:
        "One or more placement options have expired for the order. Please reload the page or <a>Contact Support</a> if this issue persists",
    },
    expiryTitle: {
      id: "replenishment.fbaV3.placementOptions.expiryTitle",
      defaultMessage: "Placement options expired",
    },
    fetchFailureDescription: {
      id: "replenishment.fbaV3.placementOptions.fetchFailureTitle",
      defaultMessage: "Placement options could not be generated due to the reasons below. Please try again",
    },
    retryOperationFailure: {
      id: "replenishment.fbaV3.placementOptions.retryOperationFailure",
      defaultMessage: "Failed to retry fetching placement options. Please try again",
    },
    learnMore: {
      id: "replenishment.fbaV3.placementOptions.learnMore",
      defaultMessage: "{learnMore} about placement options",
    },
  }),
  [FbaV3EntityName.TEMPORARY_SHIPMENT]: defineMessages({
    loadingDescription: {
      id: "replenishment.fbaV3.temporaryShipment.loadingDescription",
      defaultMessage: "Loading shipment details",
    },
    fetchFailureDescription: {
      id: "replenishment.fbaV3.temporaryShipment.fetchFailureDescription",
      defaultMessage: "Failed to load shipment details",
    },
    retryButtonDescription: {
      id: "replenishment.fbaV3.temporaryShipment.retryButtonDescription",
      defaultMessage: "Click here to Retry",
    },
    retryOperationFailure: {
      id: "replenishment.fbaV3.temporaryShipment.retryOperationFailure",
      defaultMessage: "Failed to retry fetching shipments. Please try again",
    },
    shipmentsLoadDelay: {
      id: "replenishment.fbaV3.temporaryShipment.shipmentsLoadDelay",
      defaultMessage:
        "Please wait while we retrieve the shipments for this placement option. This may take a couple minutes.",
    },
  }),
  [FbaV3EntityName.TRANSPORTATION_OPTION]: defineMessages({
    loadingDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.loadingDescription",
      defaultMessage: "Loading transportation options",
    },
    fetchFailureDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.fetchFailureDescription",
      defaultMessage: "Failed to load transportation options",
    },
    retryButtonDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.retryButtonDescription",
      defaultMessage: "Click here to Retry",
    },
    retryOperationFailure: {
      id: "replenishment.fbaV3.transportationConfiguration.retryOperationFailure",
      defaultMessage: "Failed to retry fetching transportation options. Please try again",
    },
    retryExhaustedDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.retryExhaustedDescription",
      defaultMessage: "Our system failed to fetch the transportation options from Amazon after multiple attempts.",
    },
    chooseCarrier: {
      id: "replenishment.fbaV3.transportationConfiguration.chooseCarrier",
      defaultMessage: "Choose carrier",
    },
    shippingTypeDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.shippingTypeDescription",
      defaultMessage: "Shipping type: {shippingType}",
    },
    transportationOptionErrorDescription: {
      id: "replenishment.fbaV3.transportationConfiguration.transportationOptionErrorDescription",
      defaultMessage: "Failed to load transportation option. Please try again",
    },
    unsupportedTransportationOption: {
      id: "replenishment.fbaV3.transportationConfiguration.unsupportedTransportationOption",
      defaultMessage: "Not available",
    },
    transportationSelectionWarning: {
      id: "replenishment.fbaV3.transportationConfiguration.transportationSelectionWarning",
      defaultMessage: `Amazon's restrictions prohibit selecting a mix of the following transportation options for different shipments:<br></br>
        1. Amazon Partnered Parcel and Amazon Partnered Freight<br></br>
        2. Amazon Partnered Parcel and Flexport Partnered Parcel<br></br>
        3. Amazon Partnered Freight and Flexport Partnered Freight`,
    },
    transportationOptionsLoadDelay: {
      id: "replenishment.fbaV3.transportationConfiguration.transportationOptionsLoadDelay",
      defaultMessage:
        "Please wait while we retrieve the available transportation options. This may take a couple minutes.",
    },
  }),
};

export const FbaV3CostsLabels: Record<string, MessageDescriptor> = defineMessages({
  fees: {
    id: "replenishment.fbaV3.costs.fees",
    defaultMessage: "Fees",
  },
  discounts: {
    id: "replenishment.fbaV3.costs.discounts",
    defaultMessage: "Discounts",
  },
  netCost: {
    id: "replenishment.fbaV3.costs.netCost",
    defaultMessage: "Net cost: ${netCost}",
  },
});

export const FbaV3SubmissionMessages = defineMessages({
  submissionDelayWarning: {
    id: "replenishment.fbaV3.submissionDelayWarning",
    defaultMessage: "Please do not close the tab while we submit your order.",
  },
});
