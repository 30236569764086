import { DistributionChannel, OrderResponse, PlacementOptionDto } from "@deliverr/replenishment-client";
import { mapTemporaryShipmentToShipment } from "../../fbaV3/commons/FbaV3Utils";
import { map } from "lodash";

export function isReplenishmentOrderWithMultipleShipments(
  replenishmentOrder: OrderResponse | undefined,
  placementOption?: PlacementOptionDto
): boolean {
  return replenishmentOrder?.distributionChannel === DistributionChannel.FBA_V3
    ? (placementOption?.shipmentList?.temporaryShipments?.length ?? 0) > 1
    : (replenishmentOrder?.shipments?.length ?? 0) > 1;
}

export function getOrderShipments(replenishmentOrder: OrderResponse | undefined, placementOption?: PlacementOptionDto) {
  return replenishmentOrder?.distributionChannel === DistributionChannel.FBA_V3
    ? map(placementOption?.shipmentList?.temporaryShipments, mapTemporaryShipmentToShipment)
    : replenishmentOrder?.shipments ?? [];
}
