import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";

import { EmptySpace } from "common/components/EmptySpace";

import cls from "./SelectionCount.less";

export interface OwnProps {
  selectionCount?: number;
  onClear?: () => void;
}

export const SelectionCount: React.ComponentType<OwnProps> = React.memo(({ selectionCount, onClear }) => {
  const hideSelectionCount = !selectionCount || selectionCount === 0;
  if (hideSelectionCount) {
    return null;
  }

  return (
    <div className={cls.itemCount}>
      {selectionCount}
      <EmptySpace />
      <FormattedPlural value={selectionCount!} one="item" other="items" />
      <EmptySpace />
      <FormattedMessage id={"SelectionCount.selected"} defaultMessage={"selected"} />
      <span className={cls.icon} {...(onClear && { onClick: onClear })}>
        <FontAwesomeIcon icon="times-circle" />
      </span>
    </div>
  );
});
