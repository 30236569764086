import { SellerProblemType } from "@deliverr/legacy-inbound-client";

export const SUPPORTED_SELLER_PROBLEM_TYPES = [
  SellerProblemType.UNKNOWN_BARCODE,
  SellerProblemType.MISSING_BARCODE,
  SellerProblemType.DAMAGED_PRODUCT,
  SellerProblemType.UNEXPECTED_SKU,
  SellerProblemType.MISSING_BOXES,
  SellerProblemType.RELABELLED_BOXES,
  SellerProblemType.MISSING_CONTAINER_LABEL,
  SellerProblemType.ILLEGIBLE_LOT,
  SellerProblemType.ILLEGIBLE_EXPIRATION,
  SellerProblemType.EXPIRED_PRODUCT,
  SellerProblemType.OTHER_NON_COMPLIANCE,
  SellerProblemType.OVER_RECEIVED_UNITS,
];
