import React from "react";

import { OwnProps as SelectionCountProps, SelectionCount } from "common/components/lists/SelectionCount";
import SearchBar from "common/components/SearchBar";

import cls from "./TableControls.less";

interface OwnProps extends SelectionCountProps {
  hideSearchBar?: Boolean;
  searchPlaceholder: string;
  searchTerm: string;
  onSearchChange: (term: string) => void;
  filters?: JSX.Element; // list filters
  singleSelectionActions?: JSX.Element; // list actions that require exactly one selected item
  multiSelectionActions?: JSX.Element; // list actions that require one or more selected items
  actions?: JSX.Element; // list actions that have no selection requirements
  searchBarId: string;
}

export class TableControls extends React.Component<OwnProps, {}> {
  public render() {
    const oneSelected = this.props.selectionCount === 1;
    const multipleSelected = Boolean(this.props.selectionCount && this.props.selectionCount > 0);
    const hideSearchBar = this.props.hideSearchBar;

    return (
      <div>
        <div className={cls.topRow}>
          {hideSearchBar ? (
            <div />
          ) : (
            <SearchBar
              id={this.props.searchBarId}
              className={cls.searchBar}
              value={this.props.searchTerm}
              onChange={this.props.onSearchChange}
              placeholder={this.props.searchPlaceholder}
            />
          )}
          <div className={cls.actions}>
            <SelectionCount selectionCount={this.props.selectionCount} onClear={this.props.onClear} />
            {oneSelected && this.props.singleSelectionActions}
            {multipleSelected && this.props.multiSelectionActions}
            {this.props.actions}
          </div>
        </div>
        {this.props.filters && <div className={cls.filters}>{this.props.filters}</div>}
      </div>
    );
  }
}
