export enum ProblemType {
  UNKNOWN_BARCODE = "UNKNOWN_BARCODE",
  MISSING_BARCODE = "MISSING_BARCODE",
  UNEXPECTED_BARCODE = "UNEXPECTED_BARCODE",
  DAMAGED_PRODUCT = "DAMAGED_PRODUCT",
  RELABELED_BOX = "RELABELED_BOX",
  SHORT_SHIP_BOXES = "SHORT_SHIP_BOXES", // used for legacy non-compliance MissingBoxes
  SHORT_SHIP_UNITS = "SHORT_SHIP_UNITS", // used for the new IssueShortShip from the problem solver platform
  UNEXPECTED_SKU = "UNEXPECTED_SKU",
  FLEXPORT_SHIP = "FLEXPORT_SHIP",
  MISMATCH_CASE_QTY = "MISMATCH_CASE_QTY",
  MISSING_CONTAINER_LABEL = "MISSING_CONTAINER_LABEL",
  ILLEGIBLE_LOT = "ILLEGIBLE_LOT",
  ILLEGIBLE_EXPIRATION = "ILLEGIBLE_EXPIRATION",
  EXPIRED_PRODUCT = "EXPIRED_PRODUCT",
  OTHER_NON_COMPLIANCE = "OTHER_NON_COMPLIANCE",
  OVER_RECEIVED_UNITS = "OVER_RECEIVED_UNITS",
  UNDER_RECEIVED_UNITS = "UNDER_RECEIVED_UNITS",
}
