import React from "react";
import { Box, defaultTheme, IconSizes, IconV2, Text } from "common/components/ui";
import { PlacementOptionStatusPill } from "./PlacementOptionStatusPill";
import styled from "@emotion/styled";
import { FlexDiv } from "common/components/FlexDiv";
import { PlacementOptionsLabels } from "./PlacementOptionsLabels";
import { FormattedMessage } from "react-intl";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { LightText } from "returns/create/step/ReturnsText";
import { FbaV3Costs } from "../commons/FbaV3Costs";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { PlacementOptionDto, PlacementOptionStatus } from "@deliverr/replenishment-client";
import { useSelector } from "react-redux";
import { getShowAdminFeatures } from "common/user/UserSelectors";

const TooltipIconContainer = styled.div`
  display: flex;
  padding-left: ${defaultTheme.spacing.S1};
`;

interface PlacementOptionTileProps {
  placementOption: PlacementOptionDto;
  index: number;
}

export const PlacementOptionTile: React.FC<PlacementOptionTileProps> = ({ placementOption, index }) => {
  const { placementOptionId, placementOptionStatus, costs, shipmentList } = placementOption;
  const shouldShowPlacementOptionStatus = placementOptionStatus !== PlacementOptionStatus.OFFERED;
  const shouldShowAdminFeatures = useSelector(getShowAdminFeatures);

  return (
    <Box>
      <FlexDiv>
        <FlexDiv justifyContent="none">
          <Text bold>
            <FormattedMessage {...PlacementOptionsLabels.placementOptionName} values={{ index }}></FormattedMessage>
          </Text>

          {shouldShowAdminFeatures && (
            <TooltipIconContainer>
              <TooltipWithIcon
                placement="top"
                message={
                  <FormattedMessage
                    {...PlacementOptionsLabels.amazonPlacementOptionIdDisplay}
                    values={{
                      placementOptionId,
                    }}
                  />
                }
              >
                <IconV2 type="info-circle" color={defaultTheme.colors.NEUTRAL["200"]} size={IconSizes.small} />
              </TooltipWithIcon>
            </TooltipIconContainer>
          )}
        </FlexDiv>

        {shouldShowPlacementOptionStatus && <PlacementOptionStatusPill placementOptionStatus={placementOptionStatus} />}
      </FlexDiv>

      <Box paddingTop={"S3"} paddingBottom={"S1"}>
        <LightText>
          <FormattedMessage
            {...PlacementOptionsLabels.shipmentCount}
            values={{
              shipmentCount: shipmentList.temporaryShipments?.length,
            }}
          />
        </LightText>
      </Box>

      <FbaV3Costs costs={costs} entityName={FbaV3EntityName.PACKING_OPTION} />
    </Box>
  );
};
