import React from "react";
import { FormattedMessage } from "react-intl";
import { ProblemType } from "./ProblemType";

export const PROBLEM_TYPE_LABEL_MAP: Record<ProblemType, JSX.Element> = {
  [ProblemType.RELABELED_BOX]: (
    <FormattedMessage id="inbounds.ProblemType.relabeledBox" defaultMessage="Relabeled boxes" />
  ),
  [ProblemType.UNKNOWN_BARCODE]: (
    <FormattedMessage id="inbounds.ProblemType.unknownBarcode" defaultMessage="Unknown barcode" />
  ),
  [ProblemType.MISSING_BARCODE]: (
    <FormattedMessage id="inbounds.ProblemType.missingBarcode" defaultMessage="Missing barcode" />
  ),
  [ProblemType.SHORT_SHIP_BOXES]: (
    <FormattedMessage id="inbounds.ProblemType.shortShip" defaultMessage="Short-shipped boxes" />
  ),
  [ProblemType.SHORT_SHIP_UNITS]: (
    <FormattedMessage id="inbounds.ProblemType.shortShipV2" defaultMessage="Short-shipped units" />
  ),
  [ProblemType.UNEXPECTED_BARCODE]: (
    <FormattedMessage id="inbounds.ProblemType.unexpectedBarcode" defaultMessage="Unexpected barcode" />
  ),
  [ProblemType.DAMAGED_PRODUCT]: (
    <FormattedMessage id="inbounds.ProblemType.damagedProduct" defaultMessage="Damaged product" />
  ),
  [ProblemType.UNEXPECTED_SKU]: (
    <FormattedMessage id="inbounds.ProblemType.unexpectedSku" defaultMessage="Unexpected product" />
  ),
  [ProblemType.FLEXPORT_SHIP]: (
    <FormattedMessage id="inbounds.ProblemType.flexportShip" defaultMessage="Flexport freight exceptions" />
  ),
  [ProblemType.MISMATCH_CASE_QTY]: (
    <FormattedMessage id="inbounds.ProblemType.mismatchCaseQty" defaultMessage="Unexpected packing configuration" />
  ),
  [ProblemType.MISSING_CONTAINER_LABEL]: (
    <FormattedMessage id="inbounds.ProblemType.missingContainerLabel" defaultMessage="Missing CDSKU" />
  ),
  [ProblemType.ILLEGIBLE_LOT]: (
    <FormattedMessage id="inbounds.ProblemType.illegibleLot" defaultMessage="Illegible lot" />
  ),
  [ProblemType.ILLEGIBLE_EXPIRATION]: (
    <FormattedMessage id="inbounds.ProblemType.illegibleExpiration" defaultMessage="Illegible expiration date" />
  ),
  [ProblemType.EXPIRED_PRODUCT]: (
    <FormattedMessage id="inbounds.ProblemType.expiredProduct" defaultMessage="Expired Product" />
  ),
  [ProblemType.OTHER_NON_COMPLIANCE]: (
    <FormattedMessage id="inbounds.ProblemType.otherNonCompliance" defaultMessage="Other" />
  ),
  [ProblemType.OVER_RECEIVED_UNITS]: (
    <FormattedMessage id="inbounds.ProblemType.overReceivedUnits" defaultMessage="Over-receive" />
  ),
  [ProblemType.UNDER_RECEIVED_UNITS]: (
    <FormattedMessage id="inbounds.ProblemType.underRecievedUnits" defaultMessage="Under Recieved Units" />
  ),
};
