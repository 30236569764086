import { OperationStatus, PlacementOptionDto } from "@deliverr/replenishment-client";
import { isOperationLoading } from "../commons/FbaV3Utils";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { useMemo } from "react";

export const usePlacementOptionShipments = (
  placementOption: PlacementOptionDto,
  onRetryAfterErrorClick: (entityName: FbaV3EntityName, operationId?: number, placementOptionId?: number) => void
) => {
  const {
    temporaryShipments,
    operationSummary: { operationStatus, operationId },
  } = placementOption.shipmentList;

  const onRetryShipmentsFetch = () =>
    onRetryAfterErrorClick(FbaV3EntityName.TEMPORARY_SHIPMENT, operationId, placementOption.id);

  const areShipmentsLoading = isOperationLoading(operationStatus);

  const hasShipmentsLoadFailed = !areShipmentsLoading && operationStatus === OperationStatus.FAILED;

  const temporaryShipmentOptions = useMemo(() => {
    return areShipmentsLoading ? [] : temporaryShipments ?? [];
  }, [areShipmentsLoading, temporaryShipments]);

  return {
    areShipmentsLoading,
    hasShipmentsLoadFailed,
    onRetryShipmentsFetch,
    temporaryShipmentOptions,
  };
};
