import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getPackingOptionsData } from "../commons/FbaV3Selectors";
import { usePolling } from "common/hooks/usePolling";
import {
  FbaV3EntityName,
  FbaV3Loaders,
  PACKING_OPTION_GROUP_DETAILS_MODAL_ID,
  PACKING_OPTIONS_FETCH_MAX_RETRIES,
  PACKING_OPTIONS_POLLING_INTERVAL_MS,
} from "../commons/FbaV3Constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, isNil, some } from "lodash";
import { retryFbaOperation } from "../commons/retryFbaOperation";
import { resetPackingOptionsState, setSelectedPackingOptionId } from "./actions/PackingOptionsActions";
import { CheckableTileGroupOptionProps } from "common/components/ui";
import { getPackingOptions } from "./actions/getPackingOptions";
import { PackingOptionTile } from "./PackingOptionTile";
import { useModal } from "common/hooks/useModal";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";
import { getConfirmedPackingOption, getDskusFromPackingOptions, isOperationLoading } from "../commons/FbaV3Utils";
import { EntityError, EntityName, PackingOptionStatus } from "@deliverr/replenishment-client";
import { useLoader } from "common/components/WithLoader/useLoader";
import { useUnmount } from "react-use";
import { useStepManager } from "common/components/StepContainer";
import { FbaV3IntegrationSteps } from "../FbaV3IntegrationSteps";
import { transferCreateSetReplenishmentError } from "transfers/create/actions";

export const usePackingOptions = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { stepManager } = useStepManager();
  const [isPackingOptionSelectDisabled, setIsPackingOptionSelectDisabled] = useState<boolean>(false);
  const [selectedModalPackingOptionId, setSelectedModalPackingOptionId] = useState<number | undefined>(undefined);
  const { showModal, hideModal } = useModal(PACKING_OPTION_GROUP_DETAILS_MODAL_ID);
  const {
    packingOptions,
    packingOptionsErrors,
    packingOptionsFetchOperationStatus,
    packingOptionsFetchOperationId,
    selectedPackingOptionId,
  } = useSelector(getPackingOptionsData);

  const dskus = useMemo(() => getDskusFromPackingOptions(packingOptions), [packingOptions]);

  const isRetryOperationLoading = useLoader(FbaV3Loaders.RETRY_OPERATION);

  useEffect(() => {
    if (!isEmpty(dskus)) {
      dispatch(getUnifiedProducts(dskus));
    }
  }, [dispatch, dskus]);

  useEffect(() => {
    if (some(packingOptionsErrors, (error) => error.entity === EntityName.ORDER_ITEM && !isEmpty(error.entityId))) {
      dispatch(
        transferCreateSetReplenishmentError({
          errors: [
            new EntityError({
              errors: packingOptionsErrors,
            }),
          ],
        })
      );
      dispatch(resetPackingOptionsState());
      stepManager.reassess(FbaV3IntegrationSteps.OrderCreationError);
    }
  }, [dispatch, packingOptionsErrors, stepManager]);

  const shouldPollForPackingOptions = useMemo(
    () => isOperationLoading(packingOptionsFetchOperationStatus),
    [packingOptionsFetchOperationStatus]
  );

  const { retries, resetStates } = usePolling(
    PACKING_OPTIONS_POLLING_INTERVAL_MS,
    shouldPollForPackingOptions,
    () => dispatch(getPackingOptions()),
    PACKING_OPTIONS_FETCH_MAX_RETRIES
  );

  const restartPolling = useCallback(() => {
    dispatch(resetPackingOptionsState());
    resetStates();
  }, [dispatch, resetStates]);

  useUnmount(() => {
    hideModal();
  });

  const hasRetryLimitReached = retries >= PACKING_OPTIONS_FETCH_MAX_RETRIES;

  const arePackingOptionsLoading = !hasRetryLimitReached && shouldPollForPackingOptions;

  const confirmedPackingOption = useMemo(() => getConfirmedPackingOption(packingOptions), [packingOptions]);

  const isAnyPackingOptionExpired = useMemo(
    () => !!packingOptions?.find((packingOption) => packingOption.packingOptionStatus === PackingOptionStatus.EXPIRED),
    [packingOptions]
  );

  const isNextDisabled = useMemo(() => isNil(selectedPackingOptionId), [selectedPackingOptionId]);
  const isBackDisabled = useMemo(
    () => !some(packingOptionsErrors, (error) => error.entityId === EntityName.ORDER_ITEM),
    [packingOptions]
  );

  useEffect(() => {
    if (!isNil(confirmedPackingOption)) {
      setIsPackingOptionSelectDisabled(true);
      dispatch(setSelectedPackingOptionId(confirmedPackingOption.id));
    } else if (isAnyPackingOptionExpired) {
      setIsPackingOptionSelectDisabled(true);
    }
  }, [packingOptions, isAnyPackingOptionExpired, confirmedPackingOption, dispatch]);

  const handlePackingOptionChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(setSelectedPackingOptionId(parseInt(event.target.value)));
    },
    [dispatch]
  );

  const onRetryAfterErrorClick = () => {
    if (!isNil(packingOptionsFetchOperationId)) {
      dispatch(
        retryFbaOperation({
          operationId: packingOptionsFetchOperationId,
          postRetryAction: restartPolling,
          entityName: FbaV3EntityName.PACKING_OPTION,
        })
      );
    } else {
      restartPolling();
    }
  };

  const onViewDetailsClick = useCallback(
    (packingOptionId: number) => {
      setSelectedModalPackingOptionId(packingOptionId);
      showModal();
    },
    [showModal]
  );

  const onModalCloseClick = useCallback(() => {
    hideModal();
    setSelectedModalPackingOptionId(undefined);
  }, [hideModal]);

  const packingOptionTiles: CheckableTileGroupOptionProps[] = useMemo(
    () =>
      packingOptions?.map((packingOption, idx) => ({
        id: packingOption.id.toString(),
        value: packingOption.id,
        content: (
          <div key={packingOption.id}>
            <PackingOptionTile
              packingOption={packingOption}
              onViewDetailsClick={onViewDetailsClick}
              key={packingOption.packingOptionId}
              index={idx + 1}
            />
          </div>
        ),
      })) ?? [],
    [packingOptions, onViewDetailsClick]
  );

  const selectedModalPackingOption = packingOptions?.find(
    (packingOption) => packingOption.id === selectedModalPackingOptionId
  );

  return {
    packingOptionTiles,
    packingOptionsErrors,
    isRetryOperationLoading,
    arePackingOptionsLoading,
    isPackingOptionSelectDisabled,
    selectedPackingOptionId,
    isNextDisabled,
    hasRetryLimitReached,
    selectedModalPackingOption,
    packingOptionsFetchOperationId,
    shouldShowExpiryNotification: isAnyPackingOptionExpired && isNil(confirmedPackingOption),
    isBackDisabled,
    onRetryAfterErrorClick,
    onModalCloseClick,
    handlePackingOptionChange,
    onRetryAfterRetryLimitReachedClick: restartPolling,
    formatMessage,
  };
};
