import classNames from "classnames/dedupe";
import { debounce } from "lodash/fp";
import { default as MaterialUISearchBar } from "material-ui-search-bar";
import React from "react";

import cls from "./SearchBar.less";

const ALGOLIA_MAX_CHAR = "250";

interface OwnProps {
  placeholder: string;
  onChange: any;
  onKeyUp?: any;
  autoFocus?: boolean;
  className?: string;
  focusedClassName?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  id: string;
}

class SearchBar extends React.Component<OwnProps, {}> {
  public searchBar: HTMLDivElement | null;

  public state = {
    focused: this.props.autoFocus !== false,
    currentSearchTerm: "",
  };

  public processChangeDebounced = debounce(Number(process.env.DEFAULT_SEARCH_THROTTLE_MS), () =>
    this.props.onChange(this.state.currentSearchTerm)
  );

  public componentDidMount() {
    if (this.searchBar) {
      const searchInput = this.searchBar.querySelector("input");
      if (searchInput) {
        searchInput.addEventListener("focus", this.onSearchFocus);
        searchInput.addEventListener("blur", this.onSearchBlur);
        if (this.props.autoFocus !== false) {
          searchInput.focus();
          const valueLength = searchInput.value.length;
          searchInput.setSelectionRange(valueLength, valueLength);
        }
      }
    }
  }

  public componentWillUnmount() {
    if (this.searchBar) {
      const searchInput = this.searchBar.querySelector("input");
      if (searchInput) {
        searchInput.removeEventListener("focus", this.onSearchFocus);
        searchInput.removeEventListener("blur", this.onSearchBlur);
      }
    }
  }

  public onSearchFocus = () => {
    this.setState({ focused: true });
  };

  public onSearchBlur = () => {
    this.setState({ focused: false });
  };

  public onChange = (searchTerm: string) => {
    this.setState({ currentSearchTerm: searchTerm });
    this.processChangeDebounced();
  };

  public onRequestSearch = () => {
    this.props.onChange(this.state.currentSearchTerm);
  };

  public render() {
    const { id, className, focusedClassName, ...passthroughProps } = this.props;
    const { focused } = this.state;
    return (
      <div className={className}>
        <div
          className={classNames(
            focused ? cls.searchInputWrapFocused : cls.searchInputWrap,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            (focused && focusedClassName) || ""
          )}
          ref={(el) => {
            this.searchBar = el;
          }}
        >
          <MaterialUISearchBar
            className={cls.searchBar}
            id={id}
            hintText=""
            style={{ display: "block", position: "relative", height: "40px" }}
            {...passthroughProps}
            onChange={this.onChange}
            onRequestSearch={this.onRequestSearch}
            maxLength={ALGOLIA_MAX_CHAR}
            data-testid="search_bar"
          />
        </div>
      </div>
    );
  }
}

export default SearchBar;
