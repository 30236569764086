import { HUB_LOCATIONS } from "inbounds/ShipmentDetails/Milestones/constants/HubConstants";
import { InventoryDistributionType } from "@deliverr/business-types";
import { RootState } from "RootReducer";
import { UserReducerState } from "./UserReducer";
import { UserState } from "./UserState";
import { createSelector } from "reselect";

export const FederatedUsernamePrefix = "Auth0";
const FederatedShopifyUsernamePrefix = "Auth0Shopify";

export const getCognitoUsername = (state: RootState): string | undefined => state.cognito?.user?.username;

export const getIsFederatedUser = createSelector<RootState, any, boolean>(
  getCognitoUsername,
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (username) => username && username.startsWith(FederatedUsernamePrefix)
);

export const selectIsFederatedShopifyUser = createSelector<RootState, any, boolean>(
  getCognitoUsername,
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (username) => username && username?.startsWith(FederatedShopifyUsernamePrefix)
);

export const getUserGroups = (state: RootState) => state.user.groups;

export const getIsEbayPilot = createSelector<RootState, string[], boolean>(
  getUserGroups,
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (groups) => groups && groups.includes("ebay")
);

export const getIsAdmin = createSelector<RootState, string[], boolean>(
  getUserGroups,
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (groups) => groups && groups.includes("admin")
);

export const getIsBillingCreditAdmin = createSelector<RootState, string[], boolean>(
  getUserGroups,
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (groups) => groups && groups.includes("billing-credit-admin")
);

export const getShowAdminFeatures = createSelector<RootState, boolean, boolean, boolean>(
  (state) => state.admin.showAdminFeatures,
  getIsAdmin,
  (showAdminFeatures, isAdmin) => isAdmin && showAdminFeatures
);

export const getUserAuthState = createSelector<RootState, UserReducerState, UserState>(
  (state) => state.user,
  (user) => user?.state
);

export const getSellerId = (state: RootState) => state.user.sellerId;

export const getIsOnWaitlist = (state: RootState) => state.user.isOnWaitlist;

export const getIsLinkedToFlexport = (state: RootState) => state.user.isLinkedToFlexport;

export const getIsPassEmailVerification = (state: RootState) => state.user.isPassEmailVerification;

export const getLogisticsAccountId = (state: RootState) => state.organization?.logisticsAccountId;

export const getIsInternationalShipping = (state: RootState) => state.user.isInternationalShippingEnabled;

export const getUserEmail = (state: RootState) => {
  return state.user.email;
};

export const getEffectiveUserEmail = (state: RootState) => {
  return state.cognito?.attributes?.["custom:impersonatedEmail"] || state.user.email;
};

export const selectIsOneNodeSupported = (state: RootState) => state.user.settings.isOneNodeSupported;

export const selectIsZoneBasedMerchant = (state: RootState) => {
  const { inventoryDistributionType } = state.user.settings;
  return (
    inventoryDistributionType === InventoryDistributionType.MULTI_NODE_ZBP ||
    inventoryDistributionType === InventoryDistributionType.ONE_NODE_ZBP
  );
};

export const selectOneNodeFc = (state: RootState) => state.user.settings.oneNodeFc;

export const selectUserBCLPreferences = (state: RootState) => ({
  defaultFormat: state.user.labelPreferences.boxContentsLabels.printSize,
  defaultLanguage: state.user.labelPreferences.boxContentsLabels.language,
});

export const selectUserPalletLabelPreferences = (state: RootState) => ({
  defaultPrintSize: state.user.labelPreferences.palletLabels.printSize,
  defaultLanguage: state.user.labelPreferences.palletLabels.language,
});

export const getIsMobile = (state: RootState) => state.user.isMobile;

export const selectIsOneNodeHubSupportedMerchant = createSelector(
  selectIsOneNodeSupported,
  selectOneNodeFc,
  (isOneNodeSupported: boolean | undefined, oneNodeFc: string | undefined) =>
    isOneNodeSupported && HUB_LOCATIONS.some((loc) => loc === oneNodeFc)
);
