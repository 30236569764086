import React from "react";
import { Box, ThemeProps } from "common/components/ui";
import { PACKING_OPTION_GROUP_DETAILS_MODAL_ID } from "../commons/FbaV3Constants";
import Modal from "common/components/Modal/Modal";
import { FormattedMessage } from "react-intl";
import { FlexDiv } from "common/components/FlexDiv";
import { PackingOptionsLabels } from "./PackingOptionsLabels";
import styled from "@emotion/styled";
import { PackingOption } from "@deliverr/replenishment-client";
import { PackingGroupDetails } from "./PackingGroupDetails";

const PackingGroupsDetailsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-right: ${theme.spacing.S4};
  max-height: 500px;
  overflow-y: auto;
`
);

const PackingGroupTitle = styled(FlexDiv)<ThemeProps>(
  ({ theme }) => `
  font-family: ${theme.font.family.TITLE};
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F4};
  padding-bottom: ${theme.spacing.S4};
`
);

export const PackingGroupsDetailsModal: React.FC<{
  onCloseClick: () => void;
  packingOption?: PackingOption;
}> = ({ onCloseClick, packingOption }) => {
  return (
    <Modal id={PACKING_OPTION_GROUP_DETAILS_MODAL_ID} onClose={onCloseClick} hasCloseButton size={"md"}>
      <Box paddingTop={"S7"} paddingBottom={"S7"} paddingLeft={"S7"} paddingRight={"S5"}>
        <PackingGroupTitle justifyContent="center">
          <FormattedMessage {...PackingOptionsLabels.packingGroupModalTitle} />
        </PackingGroupTitle>

        <PackingGroupsDetailsContainer>
          {packingOption?.packingGroups?.map((packingGroup, index) => (
            <PackingGroupDetails packingGroup={packingGroup} index={index + 1} key={index} />
          ))}
        </PackingGroupsDetailsContainer>
      </Box>
    </Modal>
  );
};
