import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTransferCreateUpdateKitComponentOrderItemUnits,
  transferCreateSetPrepType,
  transferCreateSetPrepTypes,
  transferCreateUpdateKitComponentOrderItemUnits,
} from "transfers/create/actions";
import { PrepOption } from "@deliverr/business-types";
import { FeatureName, useFeatureOn } from "common/Split";
import { selectHasKittedProducts } from "transfers/create/store/selectHasKittedProducts";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { useStepManager } from "common/components/StepContainer";
import { usePrepCostConfigs } from "prep/hooks";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { useMount } from "react-use";
import { isEmpty, isEqual } from "lodash";
import { isPrepSelected } from "prep/util/isPrepSelected";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";

export interface TransferSelectPrepPageProps {
  isNextDisabled: boolean;
  isNextStepLoading: boolean;
  shouldShowKittingService?: boolean;
  isKittingServiceDisabled?: boolean;
  isSecondaryButtonDisabled: boolean;
  selectedPrepTypes: SimpleSellerSelfServePrepType[];
  selectedKitting: SimpleSellerSelfServePrepType[];
  handleSkipPrepClick: () => void;
  totalEstimatedCost: number;
  handleSelectPrepType: ChangeEventHandler<HTMLInputElement>;
}

const defaultPrepOptions = [PrepOption.SHIPPING_ONLY];

export const useTransferSelectPrep = (): TransferSelectPrepPageProps => {
  const dispatch = useDispatch();
  const { stepManager } = useStepManager();
  const { getTotalPrepCost } = usePrepCostConfigs();

  const { prepOption, prepOptions } = useSelector(selectTransferCreate);
  const defaultHasKitting = prepOptions.includes(PrepOption.KITTING);
  const selectedPrepCombo = defaultHasKitting
    ? prepOptions.filter((option) => option !== PrepOption.KITTING)
    : prepOptions;
  const [selectedPrepTypes, setSelectedPrepTypes] = useState<PrepOption[]>(selectedPrepCombo);
  const [isKittingTileSelected, setIsKittingTileSelected] = useState<boolean>(defaultHasKitting);
  const [combinedPrepTypes, setCombinedPrepTypes] = useState<PrepOption[]>(prepOptions);

  const isPrepKittingOutboundsDTCEnabled: boolean = useFeatureOn(FeatureName.PrepKittingRSToDTCEnabled);
  const hasKittedProductSelected = useSelector(selectHasKittedProducts);
  const productDetails = useSelector(getProductChooserKittedDSKUDetails);
  const { destinationType, kitComponentOrderItems, selectedProducts } = useSelector(selectTransferCreate);

  const shouldShowKittingService: boolean =
    isPrepKittingOutboundsDTCEnabled && hasKittedProductSelected && destinationType === TransferCreationTypes.Ecom;

  const isNextStepLoading = useLoader(CreateTransferLoader.NextStepLoader);

  useEffect(() => {
    if (hasKittedProductSelected) {
      setIsKittingTileSelected(true);
    }
  }, [hasKittedProductSelected]);

  useMount(() => dispatch(resetTransferCreateUpdateKitComponentOrderItemUnits()));

  useEffect(() => {
    Object.keys(selectedProducts).forEach((kitChildDSKU) => {
      productDetails?.[kitChildDSKU]?.kitComponents?.forEach((component) => {
        if (kitComponentOrderItems?.find((item) => item.dsku === component.dsku)) {
          dispatch(
            transferCreateUpdateKitComponentOrderItemUnits(
              component.dsku,
              component.qty,
              selectedProducts?.[kitChildDSKU].qty ?? 0
            )
          );
          return;
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProducts, productDetails]); // Disable exhhaustive deps for kitComponentOrderItems

  useEffect(() => {
    if (isKittingTileSelected) {
      setCombinedPrepTypes((prepTypes) => {
        return prepTypes.includes(PrepOption.KITTING) ? prepTypes : [...prepTypes, PrepOption.KITTING];
      });
    } else {
      setCombinedPrepTypes((prepTypes) => prepTypes.filter((prepType) => prepType !== PrepOption.KITTING));
    }
  }, [isKittingTileSelected]);

  useEffect(() => {
    setCombinedPrepTypes((prepTypes) => {
      return prepTypes.includes(PrepOption.KITTING) ? [...selectedPrepTypes, PrepOption.KITTING] : selectedPrepTypes;
    });
  }, [selectedPrepTypes]);

  useEffect(() => {
    if (!isEqual(prepOptions, combinedPrepTypes)) {
      dispatch(transferCreateSetPrepTypes(combinedPrepTypes));
    }
  }, [combinedPrepTypes]);

  const handleSkipPrepClick = () => {
    dispatch(transferCreateSetPrepType(PrepOption.SHIPPING_ONLY));
    stepManager.next();
  };

  const handleSelectPrepType: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked: isChecked, value: simplePrepType } = event?.target;
    const updatedPrepTypes = isChecked ? [simplePrepType] : [];

    setSelectedPrepTypes(updatedPrepTypes as PrepOption[]);
    dispatch(transferCreateSetPrepType(isChecked ? (simplePrepType as PrepOption) : undefined));
    if (shouldShowKittingService) {
      dispatch(
        transferCreateSetPrepTypes(
          (isKittingTileSelected ? [...updatedPrepTypes, PrepOption.KITTING] : updatedPrepTypes) as PrepOption[]
        )
      );
    }
  };

  const totalEstimatedCost = getTotalPrepCost(combinedPrepTypes as SimpleSellerSelfServePrepType[]);
  const isNextDisabled =
    (shouldShowKittingService ? !isKittingTileSelected : isEmpty(combinedPrepTypes)) ||
    !isPrepSelected(combinedPrepTypes, prepOption);
  const isSecondaryButtonDisabled =
    isNextStepLoading ||
    (shouldShowKittingService
      ? isKittingTileSelected
      : !isEmpty(combinedPrepTypes) && !isEqual(combinedPrepTypes, defaultPrepOptions)) ||
    isPrepSelected(combinedPrepTypes, prepOption);

  return {
    isNextDisabled,
    isNextStepLoading,
    selectedPrepTypes: selectedPrepTypes as SimpleSellerSelfServePrepType[],
    selectedKitting: combinedPrepTypes as SimpleSellerSelfServePrepType[],
    isSecondaryButtonDisabled,
    shouldShowKittingService,
    handleSkipPrepClick,
    totalEstimatedCost,
    handleSelectPrepType,
  };
};
