import { InvalidOrderItemDto, InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { FeatureName, useFeatureOn } from "common/Split";
import { ProductCasePack } from "common/clients/product/ProductCasePack";
import { SelectedProductData } from "common/components/ProductChooser";
import { getItemProductAndInventoryDetails } from "common/wholesale-common/getItemProductAndInventoryDetails";
import { map, values } from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useAsync } from "react-use";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

interface UseInvalidCaseConfigResolutionProps {
  invalidItem: InvalidOrderItemDto;
  selectedProduct?: SelectedProductData;
  setSelectedProduct: (product: SelectedProductData | undefined) => void;
  setResolution: (resolution: InvalidOrderItemResolution | undefined) => void;
  selectedProductCasePack?: ProductCasePack;
}

export const useInvalidCaseConfigResolution = ({
  invalidItem,
  selectedProduct,
  setSelectedProduct,
  setResolution,
}: UseInvalidCaseConfigResolutionProps) => {
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { isEachPickingOrder } = useSelector(selectTransferCreate);

  const { loading: isSelectedProductLoading, value } = useAsync(async () => {
    if (invalidItem.requestedPackOf) {
      const product = await getItemProductAndInventoryDetails(
        [{ dsku: invalidItem.requestedPackOf, qty: 0 }],
        dispatch,
        isMultiCasePackEnabled,
        false
      );

      const productCasePackSkus = map(
        product.selectedProducts[invalidItem.requestedPackOf]?.productCasePacks,
        (pack) => pack.dsku
      );

      const productCasePacks = await getItemProductAndInventoryDetails(
        productCasePackSkus.map((dsku) => ({ dsku, qty: 0 })),
        dispatch,
        isMultiCasePackEnabled,
        false,
        isEachPickingOrder
      );

      if (invalidItem.resolvedDsku) {
        setResolution(InvalidOrderItemResolution.MODIFIED);
        setSelectedProduct(productCasePacks.selectedProducts[invalidItem.resolvedDsku]);
      }

      return {
        product: product.selectedProducts[invalidItem.requestedPackOf],
        productCasePacks: productCasePacks.selectedProducts,
      };
    } else {
      throw new Error("Invalid-case-config resolution requires a requestedPackOf attribute");
    }
  }, [invalidItem, dispatch, isMultiCasePackEnabled]);

  const setSelectedProductCasePack = (productCasePack: ProductCasePack | undefined) => {
    setResolution(InvalidOrderItemResolution.MODIFIED);
    setSelectedProduct({
      ...value!.product,
      ...productCasePack,
      caseQty: productCasePack?.quantity,
    });
  };

  const selectedProductCasePack: ProductCasePack | undefined = selectedProduct
    ? {
        ...selectedProduct,
        packOf: selectedProduct.packOf!,
        quantity: selectedProduct.caseQty ?? 0,
      }
    : undefined;

  const productCasePackOptions: ProductCasePack[] = values(value?.productCasePacks).map((product) => ({
    ...product,
    packOf: product.packOf!,
    quantity: product.caseQty ?? 0,
  }));

  return {
    product: value?.product,
    productCasePacks: productCasePackOptions,
    formatMessage,
    dispatch,
    isSelectedProductLoading,
    isMultiCasePackEnabled,
    setSelectedProductCasePack,
    selectedProductCasePack,
  };
};
