import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { replenishmentShipmentCreateLabels } from "../../common/replenishment/replenishmentShipmentCreate.labels";

export const PACKING_OPTIONS_POLLING_INTERVAL_MS = 5000;
export const PACKING_OPTIONS_FETCH_MAX_RETRIES = 10;

export const PLACEMENT_OPTIONS_POLLING_INTERVAL_MS = 8000;
export const PLACEMENT_OPTIONS_FETCH_MAX_RETRIES = 45;

export const TRANSPORTATION_OPTIONS_POLLING_INTERVAL_MS = 5000;
export const TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES = 40;

export const PLACEMENT_OPTION_CONFIRMATION_FAILURE_DELAY = 5000;

export const PACKING_OPTION_GROUP_DETAILS_MODAL_ID = "packingOptionGroupDetailsModal";

export enum FbaV3EntityName {
  PACKING_OPTION = "PACKING_OPTION",
  PLACEMENT_OPTION = "PLACEMENT_OPTION",
  TEMPORARY_SHIPMENT = "TEMPORARY_SHIPMENT",
  TRANSPORTATION_OPTION = "TRANSPORTATION_OPTION",
}

export enum FbaV3Loaders {
  FETCH_PACKING_OPTIONS = "FETCH_PACKING_OPTIONS",
  CONFIRM_PACKING_OPTION = "CONFIRM_PACKING_OPTION",

  FETCH_PLACEMENT_OPTIONS = "FETCH_PLACEMENT_OPTIONS",
  CONFIRM_PLACEMENT_OPTION = "CONFIRM_PLACEMENT_OPTION",

  FETCH_TRANSPORTATION_OPTIONS = "FETCH_TRANSPORTATION_OPTIONS",

  RETRY_OPERATION = "RETRY_OPERATION",
  GET_ORDER_OPERATIONS = "GET_ORDER_OPERATIONS",
}

export const EligibleShippingTypesForFbaV3: {
  [key in ShippingPartner]?: ShippingType[];
} = {
  [ShippingPartner.DELIVERR]: [ShippingType.LTL, ShippingType.PARCEL],
  [ShippingPartner.FBA]: [ShippingType.LTL, ShippingType.PARCEL],
};

export const FbaV3ShippingPartnerOptions = [
  {
    value: ShippingPartner.DELIVERR,
    label: <FormattedMessage {...replenishmentShipmentCreateLabels.steps.shipping.options.fbaWithOptions.deliverr} />,
  },
  {
    value: ShippingPartner.FBA,
    label: <FormattedMessage {...replenishmentShipmentCreateLabels.steps.shipping.options.fbaWithOptions.amazon} />,
  },
];
