import { FbaV3Loaders } from "./FbaV3Constants";
import { FetchOperationsRequest, Operation } from "@deliverr/replenishment-client";
import { FormattedMessage } from "react-intl";
import React from "react";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { replenishmentClient } from "Clients";
import { logError } from "Logger";
import { toast } from "common/components/ui";

export const fetchOperationsByOrderId =
  (fetchOperationsRequest: FetchOperationsRequest): SPThunkAction =>
  async (dispatch, getState): Promise<Operation<unknown>[]> => {
    dispatch(addLoader(FbaV3Loaders.GET_ORDER_OPERATIONS));

    try {
      return (await replenishmentClient.fetchOperations(fetchOperationsRequest)).value;
    } catch (err) {
      logError({ fn: "fetchOperationsByOrderId", fetchOperationsRequest }, err);

      toast.error(
        <FormattedMessage
          id="replenishments.fbav3.operations.fetch"
          defaultMessage="Failed to load operation details for order"
        />,
        {
          autoClose: 5000,
          toastId: "fetchOperationsByOrderId",
        }
      );

      throw err;
    } finally {
      dispatch(clearLoader(FbaV3Loaders.GET_ORDER_OPERATIONS));
    }
  };
