import { StyledCard, CardContainer } from "common/components/StepContainer";
import React, { FC, ReactElement, ReactNodeArray, CSSProperties, ComponentProps } from "react";
import { Box, Button, Stack, Text, Title } from "common/components/ui";
import { TextAlign, ThemeProps } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash/fp";

interface ButtonsProps {
  isDone?: boolean;
  onClickNext?: () => Promise<void>;
  onClickBack?: () => void;
  isNextDisabled?: boolean;
  isBackDisabled?: boolean;
  isLoading?: boolean;
  nextCaption?: string | ReactElement;
  backCaption?: string;
  hasBack?: boolean;
  secondaryButton?: string;
  onClickSecondary?: () => void;
  isSecondaryButtonDisabled?: boolean;
}

export interface StepCardProps extends ButtonsProps {
  "data-testid"?: string;
  title?: string;
  subtitle?: string | ReactElement | ReactNodeArray;
  footer?: ReactElement;
  header?: ReactElement;
  alignItems?: CSSProperties["alignItems"];
  headerMarginBottom?: ComponentProps<typeof Box>["marginBottom"];
}

const Header: FC<Pick<StepCardProps, "title" | "subtitle" | "header" | "alignItems" | "headerMarginBottom">> = ({
  title,
  subtitle,
  header,
  alignItems,
  headerMarginBottom = "S6",
}) => (
  <Box marginBottom={headerMarginBottom}>
    <Stack center={isEmpty(alignItems)} direction="VERTICAL" alignItems={alignItems}>
      {header}
      <Title as="h3" displayAs="h4" textAlign={TextAlign.center}>
        {title}
      </Title>
      {subtitle && (
        <Text as="div" textAlign={TextAlign.center} data-testid="step-header-subtitle">
          {subtitle}
        </Text>
      )}
    </Stack>
  </Box>
);

const SmallerFontSection = styled.div<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F1};
    line-height: ${theme.spacing.S4};
  `
);

const Footer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  text-align: center;

  z-index: 2;
  position: relative;
  background: ${theme.colors.NEUTRAL["00"]};
  padding: ${theme.spacing.S5} ${theme.spacing.S7};
  border-top: 1px solid ${theme.colors.NEUTRAL["80"]};
  box-shadow: 0 1px 2px rgba(0, 1, 0, 0.13);
`
);

const Buttons: FC<ButtonsProps & { heapIndicator: string }> = ({
  onClickBack,
  onClickNext,
  isNextDisabled,
  isBackDisabled,
  isLoading,
  hasBack,
  isDone,
  nextCaption,
  backCaption,
  secondaryButton,
  heapIndicator,
  onClickSecondary,
  isSecondaryButtonDisabled,
}) => (
  <>
    <div>
      {hasBack && (
        <Button secondary onClick={onClickBack} disabled={isBackDisabled}>
          {backCaption ?? (
            <FormattedMessage
              id="stepManager.buttons.previous"
              data-heapid={`previous-${heapIndicator}`}
              defaultMessage="Previous"
            />
          )}
        </Button>
      )}
    </div>
    <Stack direction="HORIZONTAL" gap="S2">
      {secondaryButton && (
        <Button
          secondary
          onClick={onClickSecondary}
          disabled={isSecondaryButtonDisabled}
          data-testid="step-card-secondary-button"
        >
          {secondaryButton}
        </Button>
      )}
      <Button
        loading={isLoading}
        onClick={async () => await onClickNext?.()}
        disabled={isNextDisabled}
        data-heapid={`${isDone ? "done" : "next"}-${heapIndicator}`}
        data-testid="step-button-next"
      >
        {nextCaption ??
          (isDone ? (
            <FormattedMessage id="stepManager.buttons.finish" defaultMessage="Done" />
          ) : (
            <FormattedMessage id="stepManager.buttons.next" defaultMessage="Next" />
          ))}
      </Button>
    </Stack>
  </>
);

export const StepCard: FC<StepCardProps> = ({
  title,
  subtitle,
  header,
  footer,
  alignItems,
  isDone,
  hasBack,
  isLoading,
  onClickBack,
  onClickNext,
  isNextDisabled,
  isBackDisabled,
  nextCaption,
  backCaption,
  "data-testid": dataTestId,
  secondaryButton,
  onClickSecondary,
  isSecondaryButtonDisabled,
  children,
  headerMarginBottom,
}) => (
  <CardContainer data-testid={dataTestId}>
    <StyledCard alignItems={alignItems}>
      {title && (
        <Header
          title={title}
          subtitle={subtitle}
          header={header}
          alignItems={alignItems}
          headerMarginBottom={headerMarginBottom}
        />
      )}
      {children}
      {footer && <SmallerFontSection>{footer}</SmallerFontSection>}
    </StyledCard>
    <Footer>
      <Buttons
        onClickBack={onClickBack}
        onClickNext={onClickNext}
        isNextDisabled={isNextDisabled}
        isBackDisabled={isBackDisabled}
        isLoading={isLoading}
        heapIndicator={dataTestId ?? title ?? ""}
        hasBack={hasBack}
        isDone={isDone}
        nextCaption={nextCaption}
        backCaption={backCaption}
        secondaryButton={secondaryButton}
        onClickSecondary={onClickSecondary}
        isSecondaryButtonDisabled={isSecondaryButtonDisabled}
      />
    </Footer>
  </CardContainer>
);
