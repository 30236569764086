import { PackingGroup, PackingGroupItem } from "@deliverr/replenishment-client";
import styled from "@emotion/styled";
import { Box, ThemeProps, Text } from "common/components/ui";
import { uniq } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { PackingOptionsLabels } from "./PackingOptionsLabels";
import { shipmentCreateLabels } from "common/wholesale-common/shipmentCreate.labels";
import { UnifiedProductsLoaderId } from "common/wholesale-common/getUnifiedProducts";
import WithLoader from "common/components/WithLoader";
import { FlexDiv } from "common/components/FlexDiv";
import { LightText } from "returns/create/step/ReturnsText";
import { ProductDetailData } from "inbounds/steps/ship/view/cards/ProductDetailData";

const PackingGroupContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  border: 1px solid ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.spacing.S1};
  padding: ${theme.spacing.S4};
  margin-bottom: ${theme.spacing.S4};
`
);

export const PackingGroupDetails: React.FC<{ packingGroup: PackingGroup; index: number }> = ({
  packingGroup,
  index,
}) => {
  const numOfCases = packingGroup.packingGroupItems.reduce(
    (acc, item) => acc + (item.totalUnits ?? 0) / (item.unitsPerPack ?? 1),
    0
  );
  const numOfUnits = packingGroup.packingGroupItems.reduce((acc, item) => acc + (item.totalUnits ?? 0), 0);
  const numOfSkus = uniq(packingGroup.packingGroupItems.map((item) => item.packOf)).length;

  const { unifiedProductResponseCache } = useSelector(selectTransferCreate);

  return (
    <PackingGroupContainer>
      <Box paddingTop={"S2"} paddingBottom={"S3"}>
        <Text bold size="bodyLarge" textAlign="center">
          <FormattedMessage
            {...PackingOptionsLabels.packingGroupTitle}
            values={{
              index,
            }}
          />
        </Text>
      </Box>

      <Text bold size="label">
        <FormattedMessage {...shipmentCreateLabels.steps.confirm.contentsTitle} />
      </Text>

      <Text>
        <FormattedMessage
          {...shipmentCreateLabels.steps.confirm.contentsDescription}
          values={{ br: " · ", numOfCases, numOfUnits, numOfSkus }}
        />
      </Text>

      <WithLoader
        name={UnifiedProductsLoaderId}
        loaderText={
          <Box paddingTop={"S3"} paddingBottom={"S3"}>
            <FormattedMessage {...PackingOptionsLabels.packingGroupItemLoading} />
          </Box>
        }
      >
        {packingGroup.packingGroupItems.map((item, itemIndex) => (
          <Box key={item.dsku} paddingTop={"S3"}>
            {unifiedProductResponseCache?.[item.dsku] && (
              <FlexDiv>
                <ProductDetailData
                  product={unifiedProductResponseCache?.[item.dsku]}
                  shouldShowPackInformation
                  showStorageProductAndCaseDskuForAdmin
                />
                <ProductQtyAndCaseCount item={item} />
              </FlexDiv>
            )}
          </Box>
        ))}
      </WithLoader>
    </PackingGroupContainer>
  );
};

const ProductQtyAndCaseCount: React.FC<{ item: PackingGroupItem }> = ({ item }) => {
  return (
    <Box>
      <Text bold size="body" textAlign="end">
        <FormattedMessage
          {...PackingOptionsLabels.unitQuantity}
          values={{
            numOfUnits: item.totalUnits ?? 0,
          }}
        />
      </Text>

      <LightText size="body" textAlign="end">
        <FormattedMessage
          {...PackingOptionsLabels.boxQuantity}
          values={{
            caseCount: (item.totalUnits ?? 0) / (item.unitsPerPack ?? 1),
          }}
        />
      </LightText>
    </Box>
  );
};
