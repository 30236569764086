import { DistributionChannel } from "@deliverr/replenishment-client";
import { sumBy } from "lodash/fp";
import { useSelector } from "react-redux";
import { getPlacementOptionsData } from "transfers/create/steps/fbaV3/commons/FbaV3Selectors";
import { mapTemporaryShipmentToShipment } from "transfers/create/steps/fbaV3/commons/FbaV3Utils";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const useReplenishmentShipmentSummary = () => {
  const {
    selectedProducts,
    originStorageWarehouse,
    destinationAddress,
    errorType,
    replenishmentOrder,
    destinationType,
  } = useSelector(selectTransferCreate);

  const { selectedPlacementOptionId, placementOptions } = useSelector(getPlacementOptionsData);

  const numOfSkus = Object.keys(selectedProducts).length;
  const numOfUnits = sumBy(({ qty }) => qty, Object.values(selectedProducts));
  const numOfCases = sumBy(({ qty, caseQty }) => qty / (caseQty ?? 1), Object.values(selectedProducts));

  const temporaryShipment = placementOptions?.find(({ id }) => id === selectedPlacementOptionId)?.shipmentList
    ?.temporaryShipments?.[0];

  const replenishmentShipment =
    replenishmentOrder?.distributionChannel === DistributionChannel.FBA_V3
      ? temporaryShipment
        ? mapTemporaryShipmentToShipment(temporaryShipment)
        : undefined
      : replenishmentOrder?.shipments?.length
      ? replenishmentOrder.shipments[0]
      : undefined;

  return {
    numOfCases,
    numOfSkus,
    numOfUnits,
    originStorageWarehouse,
    toAddress: replenishmentShipment?.destinationWarehouseAddress ?? destinationAddress,
    errorType,
    replenishmentOrder,
    destinationType,
  };
};
