import { defineMessages } from "react-intl";
import { SellerProblemType } from "@deliverr/legacy-inbound-client";

export const PROBLEM_TYPE_LABEL_MAP = defineMessages({
  [SellerProblemType.DAMAGED_PRODUCT]: {
    id: "problem.type.demagedProduct",
    defaultMessage: "Damaged product",
  },
  [SellerProblemType.MISSING_BARCODE]: {
    id: "problem.type.missingBarcode",
    defaultMessage: "Missing barcode",
  },
  [SellerProblemType.UNEXPECTED_SKU]: {
    id: "problem.type.unexpectedSku",
    defaultMessage: "Unexpected product",
  },
  [SellerProblemType.UNKNOWN_BARCODE]: {
    id: "problem.type.unknownBarcode",
    defaultMessage: "Unknown barcode",
  },
  [SellerProblemType.FLEXPORT_EXCEPTION]: {
    id: "problem.type.flexportException",
    defaultMessage: "Flexport freight exceptions",
  },
  [SellerProblemType.MISSING_BOXES]: {
    id: "problem.type.missingBoxes",
    defaultMessage: "Missing boxes",
  },
  [SellerProblemType.RELABELLED_BOXES]: {
    id: "problem.type.relabelledBoxes",
    defaultMessage: "Relabelled boxes",
  },
  [SellerProblemType.MISSING_CONTAINER_LABEL]: {
    id: "problem.type.missingContainerLabel",
    defaultMessage: "Missing CDSKU",
  },
  [SellerProblemType.ILLEGIBLE_LOT]: {
    id: "problem.type.illegibleLot",
    defaultMessage: "Illegible lot",
  },
  [SellerProblemType.ILLEGIBLE_EXPIRATION]: {
    id: "problem.type.illegibleExpiration",
    defaultMessage: "Illegible expiration date",
  },
  [SellerProblemType.EXPIRED_PRODUCT]: {
    id: "problem.type.expiredProduct",
    defaultMessage: "Expired Product",
  },
  [SellerProblemType.OTHER_NON_COMPLIANCE]: {
    id: "problem.type.otherNonCompliance",
    defaultMessage: "Other",
  },
  [SellerProblemType.SHORT_SHIP]: {
    id: "problem.type.shortShip",
    defaultMessage: "Short-shipped boxes",
  },
  [SellerProblemType.OVER_RECEIVED_UNITS]: {
    id: "problem.type.overReceivedUnits",
    defaultMessage: "Over-receive",
  },
});
