import { map, uniqBy } from "lodash";
import { SortDir } from "common/models";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { searchServiceFor } from "common/search/Factory";
import { ListType } from "common/list";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { escapeSpecialCharsOpenSearchQuery } from "common/StringUtils";

const reserveStorageOutboundsSearchService = searchServiceFor(ListType.ReserveStorageOutbounds);

export const fetchAddress = async (
  searchTerm: string,
  pageNum: number = 0
): Promise<{ uniqueAddresses: DeliverrAddress[]; totalPages: number }> => {
  const searchableKeys = ["city", "company", "country", "email", "name", "phone", "state", "street1", "street2", "zip"];
  const res = await reserveStorageOutboundsSearchService.execute({
    page: pageNum,
    searchTerm: escapeSpecialCharsOpenSearchQuery(searchTerm),
    sort: {
      fieldName: "updatedAt",
      direction: SortDir.DESC,
    },
    customizedOpenSearchFilters: [
      {
        bool: {
          should: [
            {
              nested: {
                path: "shipments.destinationAddress",
                query: {
                  query_string: {
                    query: `${searchableKeys
                      .map((val) => `shipments.destinationAddress.${val}:*${searchTerm}*`)
                      .join(" OR ")}`,
                    analyze_wildcard: true,
                  },
                },
                inner_hits: {
                  name: "validatedDestinationAddress",
                  size: 10, // To account for multiple addresses match in a single shipment.
                },
              },
            },
          ],
          must: {
            term: {
              distributionChannel: DistributionChannel.WHOLESALE,
            },
          },
          minimum_should_match: 1,
        },
      },
    ],
  });

  const addresses = map(
    res?.hits,
    (order) => order?.innerHits?.validatedDestinationAddress?.hits?.hits?.map((addr) => addr?._source) ?? []
  )
    .flat()
    .filter((address) => !!address);

  const totalPages = res?.response?.nbPages ?? 0;

  return {
    uniqueAddresses: uniqBy(
      addresses,
      (address) =>
        `${address?.street1}-${address?.street2}-${address?.city}-${address?.state}-${address?.zip}-${address?.phone}-${address?.email}`
    ),
    totalPages,
  };
};
