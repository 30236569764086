import { OutboundChargeType, OutboundEntity } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { WholesaleErrorType } from "common/wholesale-common/utils/WholesaleErrorTypes";
import { chain, entries, sumBy } from "lodash";
import { batch } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { replenishmentClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import {
  transferCreateSetError,
  transferCreateSetReplenishmentError,
  transferCreateSetReplenishmentOrderQuotes,
} from "transfers/create/actions";
import { ChargeRow } from "transfers/create/actions/replenishmentOrder/shipping/ChargeRow";
import { getPlacementOptionsData, getTransportationOptionsData } from "./FbaV3Selectors";
import { feeDescriptionMessages } from "transfers/create/actions/replenishmentOrder/shipping/feeDescriptionMessages";

export const getFbaV3OrderConfirmationEstimates =
  (orderId: string): SPThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(addLoader(CreateTransferLoader.FetchEstimatesLoader));
      dispatch(transferCreateSetError(WholesaleErrorType.NONE));
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
    });

    const { selectedPlacementOptionId } = getPlacementOptionsData(getState());
    const { selectedTransportationConfigurations } = getTransportationOptionsData(getState());

    const shipmentShippingPreferences = entries(selectedTransportationConfigurations[selectedPlacementOptionId!]).map(
      ([shipmentId, config]) => ({
        temporaryShipmentOptionId: Number(shipmentId),
        shippingType: config.shippingType!,
        shippingPartner: config.shippingPartner!,
      })
    );

    try {
      const amazonEstimates = (
        await replenishmentClient.getFbaOrderEstimates(orderId, {
          placementOptionId: selectedPlacementOptionId!,
          shipmentShippingPreferences,
        })
      ).value;

      const chargeBreakdowns = [...(amazonEstimates.orderChargeBreakdown ?? [])];

      for (const shipmentEstimate of amazonEstimates.shipmentChargeBreakdown) {
        chargeBreakdowns.push(...(shipmentEstimate.chargeBreakdown ?? []));
      }

      const chargeRows: ChargeRow[] = chain(chargeBreakdowns)
        .groupBy((breakdown) => breakdown.chargeType)
        .map((val, key) => {
          const isShippingFee = val[0].chargeType === OutboundChargeType.SHIPPING_FEE;
          const totalCost = sumBy(val, (chargesRecord) => chargesRecord.totalCost);

          const row: ChargeRow = {
            feeDescription: feeDescriptionMessages[key],
            chargeType: val[0].chargeType,
            unitCost: isShippingFee ? totalCost : val[0].unitCost,
            unitOfMeasure: isShippingFee ? OutboundEntity.ORDER : val[0].unitOfMeasure,
            unitCount: isShippingFee ? 1 : sumBy(val, (chargesRecord) => chargesRecord.unitCount),
            totalCost,
          };

          return row;
        })
        .value();

      batch(() => {
        dispatch(transferCreateSetReplenishmentOrderQuotes({ charges: chargeRows }));
        dispatch(clearLoader(CreateTransferLoader.FetchEstimatesLoader));
      });

      return true;
    } catch (err) {
      logError({ fn: "getFbaV3OrderConfirmationEstimates" }, err);

      batch(() => {
        dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
        dispatch(clearLoader(CreateTransferLoader.FetchEstimatesLoader));
      });

      return false;
    }
  };
