import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { EntityError, OperationStatus, ShippingPartner } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { replenishmentClient } from "Clients";
import { setTransportationOptions } from "./TransportationOptionActions";
import { augmentTransportationOptionsByShipmentId, getFlattenedEntityErrors } from "../../commons/FbaV3Utils";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";

export const getTransportationOptions =
  (placementOptionId: number, shippingPartner: ShippingPartner): SPThunkAction =>
  async (dispatch, getState) => {
    dispatch(addLoader(FbaV3Loaders.FETCH_TRANSPORTATION_OPTIONS));

    const { replenishmentOrder } = getState().transferCreate;

    try {
      const { value: transportationOptionsResponse } = await replenishmentClient.getTransportationOptions(
        replenishmentOrder!.orderId,
        placementOptionId,
        shippingPartner
      );

      if (transportationOptionsResponse.operationSummary.operationStatus === OperationStatus.FAILED) {
        dispatch(
          setTransportationOptions(shippingPartner, placementOptionId, {
            operationStatus: OperationStatus.FAILED,
            operationId: transportationOptionsResponse?.operationSummary?.operationId,
            errors: getFlattenedEntityErrors(transportationOptionsResponse.operationSummary?.operationErrors ?? []),
            transportationOptions: {},
          })
        );
      } else {
        dispatch(
          setTransportationOptions(shippingPartner, placementOptionId, {
            operationStatus: transportationOptionsResponse.operationSummary.operationStatus,
            operationId: transportationOptionsResponse.operationSummary.operationId,
            errors: [],
            transportationOptions: augmentTransportationOptionsByShipmentId(
              transportationOptionsResponse.transportationOptions,
              shippingPartner
            ),
          })
        );
      }
    } catch (err) {
      const error = err?.response?.data?.error ?? err;
      logError({ fn: "getTransportationOptions" }, err, "Failed to fetch transportation options");

      error instanceof EntityError
        ? setTransportationOptions(shippingPartner, placementOptionId, {
            operationStatus: OperationStatus.FAILED,
            operationId: undefined,
            errors: getFlattenedEntityErrors([error]),
            transportationOptions: {},
          })
        : dispatch(
            setTransportationOptions(shippingPartner, placementOptionId, {
              operationStatus: OperationStatus.FAILED,
              operationId: undefined,
              errors: [new EntityError({ message: error?.message, code: error?.code })],
              transportationOptions: {},
            })
          );
    } finally {
      dispatch(clearLoader(FbaV3Loaders.FETCH_TRANSPORTATION_OPTIONS));
    }
  };
