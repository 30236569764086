import { format } from "date-fns";
import { DistributionChannel, OrderResponse, ShippingPartner, Source } from "@deliverr/replenishment-client";
import { fromLooseDate } from "common/date/LooseDate";
import { sumBy } from "lodash";
import { FullCommonDate } from "common/date/DateFormat";

export enum SummaryLevel {
  SHIPMENT,
  ORDER,
}

function formatDateString(date: string) {
  return fromLooseDate(date).toLocaleString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    second: "2-digit",
  });
}

export function useReplenishmentOrderSummary(replenishmentOrder: OrderResponse) {
  const isMultiShipment = (replenishmentOrder?.shipments?.length ?? 0) > 1;
  const shipment = replenishmentOrder?.shipments?.[0];
  const isWholesaleOrder =
    replenishmentOrder?.distributionChannel === DistributionChannel.WHOLESALE ||
    replenishmentOrder?.source === Source.WHOLESALE_MIGRATION;
  const formattedShipByDate = replenishmentOrder?.shipBy && format(new Date(replenishmentOrder.shipBy), FullCommonDate);
  const isCollectShipment = shipment?.shippingPartner === ShippingPartner.COLLECT;
  const carrierAccount = shipment?.carrierAccount;

  return {
    numSkus: replenishmentOrder?.orderItems?.length ?? 0,
    numUnits: sumBy(replenishmentOrder?.orderItems, (item) => item.totalUnits ?? 0),
    submitTimestamp: formatDateString(String(replenishmentOrder?.createdAt)),
    poNumber: isWholesaleOrder ? replenishmentOrder?.externalId : undefined,
    replenishmentOrder,
    isMultiShipment,
    isWholesaleOrder,
    shipment,
    formattedShipByDate,
    carrierAccount,
    isCollectShipment,
  };
}
