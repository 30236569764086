import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";

export class PrepSearchService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries: any[] = [];

    if (request.searchTerm) {
      shouldQueries.push({
        query_string: {
          query: ["dpId", "sourceId", "wholesaleOrderId", "sellerId", "sourceType", "prepServices"]
            .map((f) => `${f}:*${request.searchTerm}*`)
            .join(" OR "),
          analyze_wildcard: true,
        },
      });
    }

    return {
      ...searchOptions,
      query: {
        bool: {
          should: shouldQueries,
          minimum_should_match: 1,
        },
      },
    };
  }
}
