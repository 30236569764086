import produce from "immer";
import { isEmpty } from "lodash/fp";
import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { WholesaleIngestionChannel, WholesaleListState } from "./WholesaleListState";
import { WholesaleListActionTypes } from "../actions/WholesaleListActionTypes";
import { ReplenishmentListStatuses } from "transfers/listV2/ReplenishmentListStatuses";
import { ReplenishmentOrderListTabFilter } from "transfers/listV2/store/ReplenishmentListState";

export const wholesaleListInitialState: WholesaleListState = {
  searchTerm: "",
  sort: {
    fieldName: "createdAt",
    direction: SortDir.DESC,
  },
  pageNum: 0,
  searchFilters: {
    statuses: [...ReplenishmentListStatuses],
    ingestionChannel: WholesaleIngestionChannel.ALL,
    tabFilter: ReplenishmentOrderListTabFilter.ALL_ORDERS,
  },
};

const reducers: ReducerDictionary<WholesaleListState> = {
  ...handleSimpleReducerUpdates([WholesaleListActionTypes.WHOLESALE_LIST_GET_ERROR]),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SEARCH_TERM]: (state, { searchTerm }) =>
    produce(state, (draft) => {
      draft.searchTerm = searchTerm;

      if (isEmpty(searchTerm)) {
        draft.pageItems = undefined;
      }

      draft.pageNum = 0;
    }),
  [WholesaleListActionTypes.WHOLESALE_LIST_GET_SUCCESS]: (state, { listData: { pageItems, nbPages, pageNum } }) =>
    produce(state, (draft) => {
      draft.pageItems = pageItems;
      draft.nbPages = nbPages;
      draft.pageNum = pageNum;
      draft.isError = false;
    }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_PAGE_NUM]: (state, { pageNum }) =>
    produce(state, (draft) => {
      draft.pageNum = pageNum;
      draft.isError = false;
    }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_STATUS_FILTERS]: (state, { statuses }) =>
    produce(state, (draft) => {
      draft.searchFilters.statuses = statuses;
      draft.pageNum = 0;
    }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS]: (state, { ingestionChannel }) =>
    produce(state, (draft) => {
      draft.searchFilters.ingestionChannel = ingestionChannel;
      draft.pageNum = 0;
    }),
  [WholesaleListActionTypes.WHOLESALE_LIST_SET_TAB_FILTER]: (state, { tabFilter }) =>
    produce(state, (draft) => {
      draft.searchFilters.tabFilter = tabFilter;
      draft.pageNum = 0;
    }),
};

export const wholesaleListReducer = createReducer<WholesaleListState>(wholesaleListInitialState, reducers);
