import { PlacementOptionStatus } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";

export const PlacementOptionsLabels = defineMessages({
  placementOptionName: {
    id: "fbaV3.placementOptions.option.name",
    defaultMessage: "Option {index}",
  },
  placementOptionStepTitle: {
    id: "fbaV3.placementOptions.step.title",
    defaultMessage: "Select shipping",
  },
  placementOptionSelectTitle: {
    id: "fbaV3.placementOptions.select.title",
    defaultMessage: "Select inbound placement service",
  },
  amazonPlacementOptionIdDisplay: {
    id: "fbaV3.packingOptions.amazonPlacementOptionId",
    defaultMessage: "FBA Placement Option ID: {placementOptionId}",
  },
  shipmentCount: {
    id: "fbaV3.placementOptions.shipmentCount",
    defaultMessage: "{shipmentCount, plural, one {# shipment} other {# shipments}}",
  },
  placementOptionShippingSelectTitle: {
    id: "fbaV3.placementOptions.select.shipping.title",
    defaultMessage: "Select shipping methods",
  },
  placementOptionConfirmationFailure: {
    id: "fbaV3.placementOptions.confirmation.failure",
    defaultMessage: "Failed to confirm placement option. Please try again",
  },
  statusLabels: defineMessages({
    [PlacementOptionStatus.ACCEPTED]: {
      id: "fbaV3.placementOptions.statusPill.accepted",
      defaultMessage: "Confirmed",
    },
    [PlacementOptionStatus.EXPIRED]: {
      id: "fbaV3.placementOptions.statusPill.expired",
      defaultMessage: "Expired",
    },
    [PlacementOptionStatus.OFFERED]: {
      id: "fbaV3.placementOptions.statusPill.offered",
      defaultMessage: "Offered",
    },
  }),
});
