import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OperationStatus,
  PlacementOptionDto,
  ShipmentResponse,
  ShippingPartner,
  ShippingType,
} from "@deliverr/replenishment-client";
import { usePolling } from "common/hooks/usePolling";
import { isAnyConflictingFBAV3EstimateSelected, isOperationLoading } from "../commons/FbaV3Utils";
import { retryFbaOperation } from "../commons/retryFbaOperation";
import {
  resetSelectedTransportationConfiguration,
  setSelectedTransportationConfiguration,
} from "./actions/TransportationOptionActions";
import { RootState } from "RootReducer";
import { getTransportationOptions } from "./actions/getTransportationOptions";
import {
  FbaV3EntityName,
  FbaV3Loaders,
  TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES,
  TRANSPORTATION_OPTIONS_POLLING_INTERVAL_MS,
} from "../commons/FbaV3Constants";
import { useLoader } from "common/components/WithLoader/useLoader";
import { ShipmentTransportationOption, TransportationOptionSummary } from "./state/TransportationOptionsState";

export const useTransportationOptions = (placementOption: PlacementOptionDto) => {
  const dispatch = useDispatch();
  const [selectedShipmentForDetailsView, setSelectedShipmentForDetailsView] = useState<ShipmentResponse | undefined>();

  const selectedTransportationConfigurations = useSelector(
    (state: RootState) =>
      state.fbaV3CreateState.transportationOptionsState.selectedTransportationConfigurations[placementOption.id]
  );

  const fbaEstimationSummary = useSelector(
    (state: RootState) =>
      state.fbaV3CreateState.transportationOptionsState.transportationEstimates[placementOption.id]?.[
        ShippingPartner.FBA
      ]
  );

  const flexportEstimationSummary = useSelector(
    (state: RootState) =>
      state.fbaV3CreateState.transportationOptionsState.transportationEstimates[placementOption.id]?.[
        ShippingPartner.DELIVERR
      ]
  );

  const shouldPollForFbaEstimates = useMemo(
    () => !!(fbaEstimationSummary?.operationStatus && isOperationLoading(fbaEstimationSummary.operationStatus)),
    [fbaEstimationSummary]
  );

  const shouldPollForFlexportEstimates = useMemo(
    () =>
      !!(
        flexportEstimationSummary?.operationStatus &&
        isOperationLoading(flexportEstimationSummary.operationStatus) &&
        fbaEstimationSummary?.operationStatus === OperationStatus.SUCCESS
      ),
    [flexportEstimationSummary, fbaEstimationSummary]
  );

  const { retries: flexportPollingRetries, resetStates: resetFlexportPollingStates } = usePolling(
    TRANSPORTATION_OPTIONS_POLLING_INTERVAL_MS,
    shouldPollForFlexportEstimates,
    () => dispatch(getTransportationOptions(placementOption.id, ShippingPartner.DELIVERR)),
    TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES,
    shouldPollForFlexportEstimates
  );

  const { retries: fbaPollingRetries, resetStates: resetFbaPollingStates } = usePolling(
    TRANSPORTATION_OPTIONS_POLLING_INTERVAL_MS,
    shouldPollForFbaEstimates,
    () => dispatch(getTransportationOptions(placementOption.id, ShippingPartner.FBA)),
    TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES
  );

  useEffect(() => {
    resetFlexportPollingStates();
    resetFbaPollingStates();
  }, [placementOption.id]);

  const restartFlexportPolling = useCallback(() => {
    dispatch(resetSelectedTransportationConfiguration(placementOption.id, ShippingPartner.DELIVERR));
    resetFlexportPollingStates();
  }, [dispatch, placementOption.id, resetFlexportPollingStates]);

  const restartFbaPolling = useCallback(() => {
    dispatch(resetSelectedTransportationConfiguration(placementOption.id, ShippingPartner.FBA));
    resetFbaPollingStates();
  }, [dispatch, placementOption.id, resetFbaPollingStates]);

  const handleEstimateSelection = useCallback(
    (shipmentId: number, shippingPartner?: ShippingPartner, shippingType?: ShippingType) => {
      // Allow unsetting shippingPartner on click of radio button
      if (
        selectedTransportationConfigurations?.[shipmentId]?.shippingPartner === shippingPartner &&
        selectedTransportationConfigurations?.[shipmentId]?.shippingType === shippingType
      ) {
        dispatch(
          setSelectedTransportationConfiguration(placementOption.id, shipmentId, {
            shippingPartner,
            shippingType: undefined,
          })
        );
      } else {
        dispatch(
          setSelectedTransportationConfiguration(placementOption.id, shipmentId, { shippingPartner, shippingType })
        );
      }
    },
    [dispatch, placementOption.id, selectedTransportationConfigurations]
  );

  const onRetryAfterEstimationErrorClick = useCallback(
    (shippingPartner: ShippingPartner) => {
      if (shippingPartner === ShippingPartner.FBA) {
        fbaEstimationSummary?.operationId
          ? dispatch(
              retryFbaOperation({
                operationId: fbaEstimationSummary?.operationId,
                postRetryAction: restartFbaPolling,
                entityName: FbaV3EntityName.TRANSPORTATION_OPTION,
              })
            )
          : restartFbaPolling();
      } else if (shippingPartner === ShippingPartner.DELIVERR) {
        if (fbaEstimationSummary?.operationStatus === OperationStatus.FAILED) {
          fbaEstimationSummary?.operationId
            ? dispatch(
                retryFbaOperation({
                  operationId: fbaEstimationSummary?.operationId,
                  postRetryAction: restartFbaPolling,
                  entityName: FbaV3EntityName.TRANSPORTATION_OPTION,
                })
              )
            : restartFbaPolling();
        } else {
          flexportEstimationSummary?.operationId
            ? dispatch(
                retryFbaOperation({
                  operationId: flexportEstimationSummary?.operationId,
                  postRetryAction: restartFlexportPolling,
                  entityName: FbaV3EntityName.TRANSPORTATION_OPTION,
                })
              )
            : restartFlexportPolling();
        }
      }
    },
    [
      dispatch,
      fbaEstimationSummary?.operationId,
      fbaEstimationSummary?.operationStatus,
      flexportEstimationSummary?.operationId,
      restartFbaPolling,
      restartFlexportPolling,
    ]
  );

  const onRetryAfterRetryLimitReachedClick = useCallback(
    (shippingPartner: ShippingPartner) => {
      if (shippingPartner === ShippingPartner.FBA) {
        restartFbaPolling();
      } else if (shippingPartner === ShippingPartner.DELIVERR) {
        restartFbaPolling();
        restartFlexportPolling();
      }
    },
    [restartFbaPolling, restartFlexportPolling]
  );

  const isRetryOperationLoading = useLoader(FbaV3Loaders.RETRY_OPERATION);

  const hasFbaRetryLimitReached = fbaPollingRetries >= TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES;
  const areFbaEstimatesLoading = shouldPollForFbaEstimates && !hasFbaRetryLimitReached;

  const hasFlexportRetryLimitReached =
    flexportPollingRetries >= TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES || hasFbaRetryLimitReached;
  const areFlexportEstimatesLoading =
    (shouldPollForFlexportEstimates && !hasFlexportRetryLimitReached) || areFbaEstimatesLoading;

  const transportationOptionSummaries: Record<
    ShippingPartner.DELIVERR | ShippingPartner.FBA,
    {
      isLoading: boolean;
      isRetryLimitReached: boolean;
      isRetryOperationLoading: boolean;
      hasFetchFailed: boolean;
      transportationOptions?: TransportationOptionSummary["transportationOptions"];
    }
  > = {
    [ShippingPartner.DELIVERR]: {
      isLoading: areFlexportEstimatesLoading,
      isRetryLimitReached: hasFlexportRetryLimitReached,
      isRetryOperationLoading,
      transportationOptions: flexportEstimationSummary?.transportationOptions,
      hasFetchFailed:
        fbaEstimationSummary?.operationStatus === OperationStatus.FAILED ||
        flexportEstimationSummary?.operationStatus === OperationStatus.FAILED,
    },
    [ShippingPartner.FBA]: {
      isLoading: areFbaEstimatesLoading,
      isRetryLimitReached: hasFbaRetryLimitReached,
      isRetryOperationLoading,
      transportationOptions: fbaEstimationSummary?.transportationOptions,
      hasFetchFailed: fbaEstimationSummary?.operationStatus === OperationStatus.FAILED,
    },
  };

  const isAnyConflictingEstimateSelected = (
    shippingPartner: ShippingPartner,
    estimate: ShipmentTransportationOption,
    shipmentId: number
  ) =>
    isAnyConflictingFBAV3EstimateSelected(selectedTransportationConfigurations, shippingPartner, estimate, shipmentId);

  return {
    selectedTransportationConfigurations,
    selectedShipmentForDetailsView,
    transportationOptionSummaries,
    setSelectedShipmentForDetailsView,
    handleEstimateSelection,
    onRetryAfterEstimationErrorClick,
    onRetryAfterRetryLimitReachedClick,
    isAnyConflictingEstimateSelected,
    isAnyEstimateLoading: areFbaEstimatesLoading || areFlexportEstimatesLoading,
  };
};
