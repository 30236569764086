import React from "react";
import { Notification } from "common/components/ui/Notification";
import { FbaV3EntityName } from "./FbaV3Constants";
import { FormattedMessage } from "react-intl";
import { FbaV3Labels } from "./FbaV3Labels";
import { Box, Text } from "common/components/ui";
import { ExternalLink } from "common/components/ExternalLink";
import { getHelpCenterNewRequestUrl } from "common/support/helpCenter";

interface FbaV3OptionExpiryMessageProps {
  showExpiryNotification: boolean;
  entityName: FbaV3EntityName.PACKING_OPTION | FbaV3EntityName.PLACEMENT_OPTION;
}

export const FbaV3OptionExpiryMessage = ({ showExpiryNotification, entityName }: FbaV3OptionExpiryMessageProps) => {
  return showExpiryNotification ? (
    <Box paddingBottom={"S6"}>
      <Notification
        appearance={"DANGER"}
        content={{
          title: <FormattedMessage {...FbaV3Labels[entityName].expiryTitle} />,
          description: (
            <FormattedMessage
              {...FbaV3Labels[entityName].expiryDescription}
              values={{
                a: (text: string) => (
                  <ExternalLink href={getHelpCenterNewRequestUrl()}>
                    <Text bold as="span">
                      {text}
                    </Text>
                  </ExternalLink>
                ),
              }}
            />
          ),
        }}
      />
    </Box>
  ) : null;
};
