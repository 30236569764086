import { Box, CheckableTileGroup, Text } from "common/components/ui";
import React from "react";
import { Direction } from "common/components/ui/shared";
import { isEmpty, isNil } from "lodash";
import { FbaV3LoadingAndErrorDisplay } from "../commons/FbaV3LoadingAndErrorDisplay";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { ManagedStep } from "common/components/StepContainer";
import { FbaV3OptionExpiryMessage } from "../commons/FbaV3OptionExpiryMessage";
import { PlacementOptionsLabels } from "./PlacementOptionsLabels";
import { usePlacementOptions } from "./usePlacementOptions";
import styled from "@emotion/styled";
import { PlacementOptionShipments } from "./PlacementOptionShipments";
import { FbaV3OptionsLearnMoreButton } from "../commons/FbaV3LearnMoreButton";

const StyledCheckableTileGroup = styled(CheckableTileGroup)(`
  & > label {
    width: 30%;
  }
`);

export const PlacementOptions: React.FC = () => {
  const {
    placementOptionTiles,
    isNextDisabled,
    placementOptionsErrors,
    selectedPlacementOptionId,
    arePlacementOptionsLoading,
    isPlacementOptionSelectDisabled,
    hasRetryLimitReached,
    shouldShowExpiryNotification,
    selectedPlacementOption,
    isRetryOperationLoading,
    onRetryAfterErrorClick,
    handlePlacementOptionChange,
    onRetryAfterRetryLimitReachedClick,
    onRetryPlacementOptionFetch,
    formatMessage,
  } = usePlacementOptions();

  return (
    <ManagedStep
      data-testid="fbav3-create-transfer-placement-option-select"
      title={
        !isEmpty(placementOptionTiles) ? formatMessage(PlacementOptionsLabels.placementOptionStepTitle) : undefined
      }
      isNextDisabled={isNextDisabled}
    >
      <FbaV3OptionExpiryMessage
        showExpiryNotification={shouldShowExpiryNotification}
        entityName={FbaV3EntityName.PLACEMENT_OPTION}
      />

      <FbaV3LoadingAndErrorDisplay
        operationErrors={placementOptionsErrors}
        entityName={FbaV3EntityName.PLACEMENT_OPTION}
        isOperationLoading={arePlacementOptionsLoading}
        onRetryAfterErrorClick={onRetryPlacementOptionFetch}
        onRetryAfterRetryLimitReachedClick={onRetryAfterRetryLimitReachedClick}
        hasRetryLimitReached={hasRetryLimitReached}
        isRetryOperationLoading={isRetryOperationLoading}
      />

      {!isEmpty(placementOptionTiles) && (
        <>
          <FbaV3OptionsLearnMoreButton entityName={FbaV3EntityName.PLACEMENT_OPTION} width={"94%"} />

          <Box paddingLeft={"S5"} paddingRight={"S4"}>
            <Box paddingTop={"S4"} paddingBottom={"S4"}>
              <Text bold size="bodyLarge">
                {formatMessage(PlacementOptionsLabels.placementOptionSelectTitle)}
              </Text>
            </Box>

            <StyledCheckableTileGroup
              options={placementOptionTiles}
              type="radio"
              currentValues={selectedPlacementOptionId ? [selectedPlacementOptionId] : []}
              direction={Direction.HORIZONTAL}
              disabled={isPlacementOptionSelectDisabled}
              onChange={handlePlacementOptionChange}
            />
          </Box>

          {!isNil(selectedPlacementOption) && (
            <PlacementOptionShipments
              placementOption={selectedPlacementOption}
              onRetryAfterErrorClick={onRetryAfterErrorClick}
              isRetryLoading={isRetryOperationLoading}
            />
          )}
        </>
      )}
    </ManagedStep>
  );
};
