import { Text, ThemeProps, IconV2, defaultTheme, Box } from "common/components/ui";
import React from "react";
import { EntityError } from "@deliverr/replenishment-client";
import { FbaV3EntityName } from "./FbaV3Constants";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { FbaV3Labels } from "./FbaV3Labels";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import { FbaV3RetryButton } from "./FbaV3RetryButton";
import { ReplenishmentOrderError } from "../../common/replenishment/errors/ReplenishmentOrderError";
import { ElevioArticleId } from "common/elevio";
import { ReplenishmentUnknownErrorCode } from "../../common/replenishment/errors/ReplenishmentErrorCodes";

const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S6};
  text-align: center;
  width: 80%;
`
);

const DescriptionContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S3};
  margin-bottom: ${theme.spacing.S3};
`
);

const TitleContainer = styled(Text)<ThemeProps>(
  ({ theme }) => `
  font-weight: bold;
  font-size: ${theme.font.size.F4};
  font-family: ${theme.font.family.TITLE};
  text-align: center;
`
);

export interface FbaV3LoadingAndErrorDisplayProps {
  operationErrors: EntityError[];
  entityName: FbaV3EntityName.PACKING_OPTION | FbaV3EntityName.PLACEMENT_OPTION;
  isOperationLoading: boolean;
  isRetryOperationLoading: boolean;
  hasRetryLimitReached: boolean;
  onRetryAfterErrorClick: () => void;
  onRetryAfterRetryLimitReachedClick: () => void;
}

export const FbaV3LoadingAndErrorDisplay: React.FC<FbaV3LoadingAndErrorDisplayProps> = ({
  operationErrors,
  entityName,
  hasRetryLimitReached,
  isOperationLoading,
  isRetryOperationLoading,
  onRetryAfterErrorClick,
  onRetryAfterRetryLimitReachedClick,
}) => {
  return (
    <>
      {isOperationLoading && (
        <>
          <LoadingSpinner size="4x" />

          <TextContainer>
            <TitleContainer>
              <FormattedMessage {...FbaV3Labels[entityName].loadingTitle} />
            </TitleContainer>

            <DescriptionContainer>
              <FormattedMessage {...FbaV3Labels[entityName].loadingDescription} />
            </DescriptionContainer>
          </TextContainer>
        </>
      )}

      {(hasRetryLimitReached || !isEmpty(operationErrors)) && (
        <>
          <IconV2 type="exclamation-triangle" color={defaultTheme.colors.RED[300]} size="4x" />

          <TextContainer>
            <TitleContainer>
              <FormattedMessage {...FbaV3Labels[entityName].fetchFailureTitle} />
            </TitleContainer>

            {hasRetryLimitReached ? (
              <>
                <DescriptionContainer>
                  <FormattedMessage {...FbaV3Labels[entityName].retryExhaustedDescription} values={{ br: <br /> }} />
                </DescriptionContainer>

                <FbaV3RetryButton
                  onRetryClick={onRetryAfterRetryLimitReachedClick}
                  entityName={FbaV3EntityName.PACKING_OPTION}
                  isRetryLoading={false}
                />
              </>
            ) : null}

            {!isEmpty(operationErrors) ? (
              <>
                <DescriptionContainer>
                  <FormattedMessage {...FbaV3Labels[entityName].fetchFailureDescription} values={{ br: <br /> }} />
                </DescriptionContainer>

                <Box paddingTop={"S3"} width={"100%"}>
                  <ReplenishmentOrderError
                    orderErrorDescriptions={operationErrors.map((error) => ({
                      orderErrorDescription: error.message,
                      errorCode: ReplenishmentUnknownErrorCode,
                      rawErrorMessage: error.code,
                    }))}
                    articleId={ElevioArticleId.Amazon}
                  />
                </Box>

                <FbaV3RetryButton
                  onRetryClick={onRetryAfterErrorClick}
                  entityName={FbaV3EntityName.PACKING_OPTION}
                  isRetryLoading={isRetryOperationLoading}
                />
              </>
            ) : null}
          </TextContainer>
        </>
      )}
    </>
  );
};
