import { Box, Notification } from "common/components/ui";
import { FbaV3EntityName } from "./FbaV3Constants";
import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { FbaV3Labels } from "./FbaV3Labels";
import { Responsive } from "common/components/ui/shared";

export const FbaV3OptionsLearnMoreButton: FC<{
  entityName: FbaV3EntityName.PACKING_OPTION | FbaV3EntityName.PLACEMENT_OPTION;
  width: Responsive<string | null> | undefined;
}> = ({ entityName, width }) => (
  <Box paddingTop={"S1"} paddingBottom={"S5"} width={width}>
    <Notification
      appearance="DEFAULT"
      content={{
        description: (
          <FormattedMessage
            {...FbaV3Labels[entityName].learnMore}
            values={{
              learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.PackingAndPlacementOptions} />,
            }}
          />
        ),
      }}
    />
  </Box>
);
