import React from "react";
import { FormattedMessage } from "react-intl";
import { FbaV3Labels } from "./FbaV3Labels";
import { FbaV3EntityName } from "./FbaV3Constants";
import { BorderlessButton } from "common/components/BorderlessButton";
import { Box, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { LoadingSpinner } from "common/components/LoadingSpinner";

const RetryButton = styled(BorderlessButton)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.BLUE[300]};
    font-weight: ${theme.font.weight.BOLD};
  `
);

const RetryButtonContainer = styled(Box)<{
  useFlex?: boolean;
}>(({ useFlex }) =>
  useFlex
    ? `
    display: flex;
    align-items: center;
  `
    : ``
);

export const FbaV3RetryButton: React.FC<{
  entityName: FbaV3EntityName;
  onRetryClick: () => void;
  isRetryLoading: boolean;
  useFlex?: boolean;
}> = ({ entityName, onRetryClick, isRetryLoading, useFlex }) => (
  <RetryButtonContainer useFlex={useFlex}>
    <Box paddingRight={"S1"} paddingLeft={"S1"}>
      {isRetryLoading && <LoadingSpinner size="medium" />}
    </Box>

    {!isRetryLoading && (
      <RetryButton onClick={onRetryClick}>
        <FormattedMessage {...FbaV3Labels[entityName].retryButtonDescription} />
      </RetryButton>
    )}
  </RetryButtonContainer>
);
