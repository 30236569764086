import { DistributionChannel } from "@deliverr/replenishment-client";
import { ReplenishmentIndexRow } from "./ReplenishmentIndexRow";
import { isEmpty } from "lodash";

export const UNKNOWN_DISTRIBUTION_CHANNEL = "UNKNOWN_DISTRIBUTION_CHANNEL";

export enum OpenSearchInvalidOrderItemStatus {
  ACTION_NEEDED = "ACTION_NEEDED",
}

export const ReplenishmentEcomDistributionChannels = [
  DistributionChannel.ECOM_VIA_CROSSDOCK,
  DistributionChannel.ECOM_DIRECT_TO_FC,
  DistributionChannel.ECOM_VIA_HUB,
];

export const ReplenishmentAmazonDistributionChannels = [
  DistributionChannel.FBA,
  DistributionChannel.FBA_MANUAL,
  DistributionChannel.FBA_V3,
];

export const ReplenishmentWalmartDistributionChannels = [DistributionChannel.WFS];

export const isFlexportEcomTransfer = (item: ReplenishmentIndexRow): boolean =>
  !isEmpty(item.distributionChannel) && ReplenishmentEcomDistributionChannels.includes(item.distributionChannel);

export const isAmazonTransfer = (item: ReplenishmentIndexRow): boolean =>
  !isEmpty(item.distributionChannel) && ReplenishmentAmazonDistributionChannels.includes(item.distributionChannel);

export const isWalmartTransfer = (item: ReplenishmentIndexRow): boolean =>
  !isEmpty(item.distributionChannel) && ReplenishmentWalmartDistributionChannels.includes(item.distributionChannel);

export const isMultiShipmentReplenishmentOrder = (item: ReplenishmentIndexRow): boolean =>
  (isEmpty(item.shipments) ? 0 : item.shipments!.length) > 1;
