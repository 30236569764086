import { PlacementOptionDto } from "@deliverr/replenishment-client";
import { Appearance, Box, Notification, Text } from "common/components/ui";
import React from "react";
import { PlacementOptionsLabels } from "./PlacementOptionsLabels";
import { FormattedMessage } from "react-intl";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import styled from "@emotion/styled";
import { FbaV3Labels } from "../commons/FbaV3Labels";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { FbaV3RetryButton } from "../commons/FbaV3RetryButton";
import { usePlacementOptionShipments } from "./usePlacementOptionShipments";
import { TransportationOptions } from "../transportationOptions/TransportationOptions";
import { LightText } from "returns/create/step/ReturnsText";

const FlexContainer = styled.div`
  display: flex;
`;

interface PlacementOptionShipmentsProps {
  placementOption: PlacementOptionDto;
  onRetryAfterErrorClick: (entityName: FbaV3EntityName, operationId?: number) => void;
  isRetryLoading: boolean;
}

export const PlacementOptionShipments: React.FC<PlacementOptionShipmentsProps> = ({
  placementOption,
  onRetryAfterErrorClick,
  isRetryLoading,
}) => {
  const { areShipmentsLoading, hasShipmentsLoadFailed, onRetryShipmentsFetch, temporaryShipmentOptions } =
    usePlacementOptionShipments(placementOption, onRetryAfterErrorClick);

  return (
    <Box width={"100%"} padding={"S5"}>
      <Box paddingTop={"S4"} paddingBottom={"S4"}>
        <Text bold size={"bodyLarge"}>
          <FormattedMessage {...PlacementOptionsLabels.placementOptionShippingSelectTitle} />
        </Text>
      </Box>

      {areShipmentsLoading && !hasShipmentsLoadFailed && (
        <Box paddingTop={"S1"} paddingBottom={"S4"}>
          <Notification
            appearance="INFO"
            content={{
              description: <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TEMPORARY_SHIPMENT].shipmentsLoadDelay} />,
            }}
          />
        </Box>
      )}

      <PlacementOptionsLoadingDisplay areShipmentsLoading={areShipmentsLoading} />

      <PlacementOptionsErrorDisplay
        hasShipmentsLoadFailed={hasShipmentsLoadFailed}
        onRetryShipmentsFetch={onRetryShipmentsFetch}
        isRetryLoading={isRetryLoading}
      />

      {!areShipmentsLoading && !hasShipmentsLoadFailed && (
        <>
          {temporaryShipmentOptions.length > 1 && (
            <Box paddingTop={"S2"} paddingBottom={"S2"}>
              <Notification
                appearance="WARNING"
                content={{
                  description: (
                    <FormattedMessage
                      {...FbaV3Labels[FbaV3EntityName.TRANSPORTATION_OPTION].transportationSelectionWarning}
                      values={{
                        br: () => <br />,
                      }}
                    />
                  ),
                }}
              />
            </Box>
          )}

          <TransportationOptions
            temporaryShipmentOptions={temporaryShipmentOptions}
            placementOption={placementOption}
          />
        </>
      )}
    </Box>
  );
};

const PlacementOptionsLoadingDisplay: React.FC<{ areShipmentsLoading: boolean }> = ({ areShipmentsLoading }) => {
  return areShipmentsLoading ? (
    <FlexContainer>
      <LightText>
        <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TEMPORARY_SHIPMENT].loadingDescription} />
      </LightText>

      <Box paddingLeft={"S1"} paddingRight={"S1"}>
        <LoadingSpinner size="medium" />
      </Box>
    </FlexContainer>
  ) : null;
};

const PlacementOptionsErrorDisplay: React.FC<{
  hasShipmentsLoadFailed: boolean;
  onRetryShipmentsFetch: () => void;
  isRetryLoading: boolean;
}> = ({ hasShipmentsLoadFailed, onRetryShipmentsFetch, isRetryLoading }) => {
  return hasShipmentsLoadFailed ? (
    <FlexContainer>
      <Text appearance={Appearance.DANGER}>
        <FormattedMessage {...FbaV3Labels[FbaV3EntityName.TEMPORARY_SHIPMENT].fetchFailureDescription} />
      </Text>

      <Box paddingLeft={"S1"}>
        <FbaV3RetryButton
          entityName={FbaV3EntityName.TEMPORARY_SHIPMENT}
          onRetryClick={onRetryShipmentsFetch}
          isRetryLoading={isRetryLoading}
          useFlex
        />
      </Box>
    </FlexContainer>
  ) : null;
};
