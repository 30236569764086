import { AdminState, adminInitialState, adminReducer } from "common/components/Admin/AdminReducer";
import { BillingReducerState, billingInitialState, billingReducer } from "billing/BillingReducer";
import { BladeState, bladeInitialState, bladeReducer } from "common/components/Blade/BladeReducer";
import { CognitoLegacyState, cognitoInitialState, cognitoReducer } from "common/user/CognitoReducer";
import {
  CostEstimatorModalState,
  costEstimatorInitialState,
  costEstimatorReducer,
} from "common/components/EstimatedCosts/CostEstimatorModalReducer";
import {
  ProductChooserState,
  productChooserInitialState,
  productChooserReducer,
} from "common/components/ProductChooser/ProductChooserReducer";
import { CreateShipmentState, createShipmentInitialState, createShipmentReducer } from "inbounds/createShipment/store";
import { DeliverrState, deliverrInitialState, deliverrReducer } from "common/deliverr/DeliverrReducer";
import { ErrorsState, errorsReducer } from "./common/errors/ErrorsReducer";
import { FastTagFlowsState, fastTagFlowsInitialState, fastTagFlowsReducer } from "tags/FastTagFlows";
import { FastTagsState, fastTagsInitialState, fastTagsReducer } from "tags/FastTagsReducer";
import {
  FlexportUserState,
  flexportUserInitialState,
  flexportUserReducer,
} from "common/flexportUser/FlexportUserReducer";
import { FreightQuoteState, freightQuoteInitialState } from "FreightQuote/store/FreightQuoteState";
import { FreightState, freightInitialState, freightReducer } from "freight/store/FreightReducer";
import { GoogleState, googleInitialState, googleReducer } from "tags/google/GoogleReducer";
import { InboundState, inboundInitialState, inboundReducer } from "inbounds/InboundReducer";
import {
  InventoryDetailState,
  inventoryDetailInitialState,
  inventoryDetailReducer,
} from "inventory/detail/InventoryDetailReducer";
import {
  InventoryListState,
  inventoryListInitialState,
  inventoryListReducer,
} from "inventory/list/InventoryListReducer";
import { LoadingState, loadingInitialState, loadingReducer } from "common/components/WithLoader/LoadingReducer";
import { MediaState, mediaInitialState, mediaReducer } from "media/MediaReducers";
import { ModalState, modalInitialState, modalReducer } from "common/components/Modal/ModalReducer";
import {
  NavBarProgressState,
  navBarProgressInitialState,
  navBarProgressReducer,
} from "common/components/NavBarProgress/NavBarProgressReducer";
import { NewOrderState, newOrderInitialState, newOrderReducer } from "order/new/NewOrderReducer";
import {
  NonComplianceListState,
  nonComplianceListInitialState,
  nonComplianceListReducer,
} from "inbounds/non-compliance/list/NonComplianceListReducer";
import {
  NonComplianceState,
  nonComplianceInitialState,
  nonComplianceReducer,
} from "inbounds/non-compliance/NonComplianceReducer";
import {
  NotificationsState,
  notificationsInitialState,
  notificationsReducer,
} from "notifications/NotificationsReducer";
import { OnboardingState, onboardingInitialState, onboardingReducer } from "onboarding/OnboardingReducer";
import { OrderDetailState, orderDetailInitialState, orderDetailReducer } from "order/detail/OrderDetailReducer";
import { OrderListState, orderListInitialState, orderListReducer } from "order/listV2/OrderListReducer";
import {
  OrderReturnsListState,
  ReturnsCreateState,
  ReturnsDetailState,
  ReturnsLabelState,
  ReturnsListStateV2,
  ReturnsOnboardState,
  ReturnsSellerState,
  orderReturnsListInitialState,
  orderReturnsListReducer,
  returnsCreateInitialState,
  returnsCreateReducer,
  returnsDetailInitialState,
  returnsDetailReducer,
  returnsLabelInitialState,
  returnsLabelReducer,
  returnsListInitialStateV2,
  returnsListReducerV2,
  returnsOnboardInitialState,
  returnsOnboardReducer,
  returnsSellerInitialState,
  returnsSellerReducer,
} from "./returns";
import { OrganizationState, organizationInitialState, organizationReducer } from "organization/OrganizationReducer";
import {
  PaginationState,
  paginationInitialState,
  paginationReducer,
} from "common/components/Pagination/PaginationReducer";
import {
  ParcelDetailState,
  parcelDetailInitialState,
  parcelDetailsReducer,
} from "parcel/screens/details/ParcelDetailReducer";
import { ParcelListState, parcelListInitialState, parcelListReducer } from "parcel/screens/list/ParcelListReducer";
import { PrepDetailsState, prepDetailsInitialState, prepDetailsReducer } from "prep/store/PrepDetailsReducer";
import { PrepListState, prepListInitialState, prepListReducer } from "./prep/store/PrepListReducer";
import { PrepStoreState, prepStoreInitialState, prepStoreReducer } from "prep/store";
import {
  ProductComplianceState,
  productComplianceInitialState,
  productComplianceReducer,
} from "inbounds/steps/compliance/ProductComplianceReducer";
import { ReportsState, reportsInitialState, reportsReducer } from "report/ReportsReducer";
import {
  ReturnsInspectionConfigState,
  returnsInspectionConfigInitialState,
} from "returns/policy/store/ReturnsPolicyReducer";
import {
  ReturnsRmtState,
  returnsInitialRmtState as returnsRmtInitalState,
  returnsRmtReducer,
} from "returns/rmt/store/RmtReducer";
import { SalesChannelsState, salesChannelsInitialState, salesChannelsReducer } from "channels/ChannelsReducers";
import { SettingsState, initialSettingsData, settingsReducer } from "settings/SettingsReducers";
import { ShopifyState, shopifyInitialState } from "tags/shopify/ShopifyState";
import { SmbShipmentState, smbShipmentInitialState, smbShipmentReducer } from "inbounds/ShipmentDetails/store";
import {
  StorageInboundCreateState,
  StorageInboundDetailState,
  storageInboundCreateInitialState,
  storageInboundCreateReducer,
  storageInboundDetailInitialState,
  storageInboundDetailReducer,
} from "./storage/inbounds/detail";
import { UserReducerState, userInitialState, userReducer } from "common/user/UserReducer";
import {
  WholesaleOrderDetailState,
  wholesaleOrderDetailInitialState,
  wholesaleOrderDetailReducer,
} from "wholesale/detail/store";
import { WishState, wishInitialState, wishReducer } from "tags/wish/WishReducer";
import {
  fbaIntegrationInitialState,
  fbaIntegrationReducer,
} from "transfers/fbaIntegration/store/FbaIntegrationReducer";
import { transferCreateInitialState, transferCreateReducer } from "./transfers/create/store/TransferCreateReducer";
import {
  transferShipmentDetailReducer,
  transferShipmentInitialState,
} from "transfers/detail/store/TransferShipmentDetailReducer";
import { wholesaleListInitialState, wholesaleListReducer } from "wholesale/list/store/WholesaleListReducer";
import { FbaIntegrationState } from "transfers/fbaIntegration/store/FbaIntegrationState";
import { ISplitState } from "@splitsoftware/splitio-redux/types/types";
import { InboundListState } from "inbounds/list/InboundListState";
import { ReplenishmentOrderDetailsState } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsState";
import { SPLIT_INITIAL_STATE } from "common/Split/splitInitialState";
import { TransferCreateState } from "./transfers/create/store";
import { TransferShipmentDetailState } from "transfers/detail/store/TransferShipmentDetailState";
import { WholesaleListState } from "wholesale/list/store/WholesaleListState";
import { combineReducers } from "redux";
import { freightQuoteReducers } from "FreightQuote/store/FreightQuoteReducer";
import { inboundListInitialState } from "inbounds/list/inboundListInitialState";
import { inboundListReducer } from "inbounds/list/InboundListReducer";
import { intlReducer } from "react-intl-redux";
import { messages } from "common/IntlMessages";
import { replenishmentOrderDetailsInitialState } from "./transfers/detail/store/replenishment/ReplenishmentOrderDetailsReducer";
import { replenishmentOrderDetailsReducer } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsReducer";
import { returnsInspectionConfigReducer } from "./returns/policy/store/ReturnsPolicyReducer";
import { shopifyReducer } from "tags/shopify/reducers/shopifyReducer";
import { splitReducer } from "@splitsoftware/splitio-redux";
import {
  ReplenishmentNonComplianceState,
  replenishmentNonComplianceInitialState,
  replenishmentNonComplianceReducer,
} from "transfers/nonCompliance/ReplenishmentNonComplianceStateReducer";
import { ReplenishmentListState } from "transfers/listV2/store/ReplenishmentListState";
import {
  replenishmentListInitialState,
  replenishmentListReducer,
} from "transfers/listV2/store/ReplenishmentListReducer";
import {
  fbaV3CreateInitialState,
  FbaV3CreateState,
  fbaV3CreateStateReducer,
} from "transfers/create/steps/fbaV3/state/FbaV3CreateState";

export interface RootState {
  billing: BillingReducerState;
  blade: BladeState;
  channels: SalesChannelsState;
  cognito?: Partial<CognitoLegacyState>;
  deliverr: DeliverrState;
  errors?: ErrorsState;
  google: GoogleState;
  intl: any;
  inventoryList: InventoryListState;
  inventoryDetail: InventoryDetailState;
  loading: LoadingState;
  modal: ModalState;
  nonCompliance: NonComplianceState;
  nonComplianceList: NonComplianceListState;
  notifications: NotificationsState;
  onboarding: OnboardingState;
  orderListV2: OrderListState;
  orderDetail: OrderDetailState;
  orderNew: NewOrderState;
  orderReturns: OrderReturnsListState;
  organization: OrganizationState;
  pagination: PaginationState;
  parcelDetails: ParcelDetailState;
  parcelList: ParcelListState;
  prepCreate: PrepStoreState;
  prepList: PrepListState;
  prepDetails: PrepDetailsState;
  progress: NavBarProgressState;
  replenishmentList: ReplenishmentListState;
  replenishmentOrderDetailsState: ReplenishmentOrderDetailsState;
  replenishmentNonComplianceState: ReplenishmentNonComplianceState;
  reports: ReportsState;
  returnsCreate: ReturnsCreateState;
  returnsLabel: ReturnsLabelState;
  returnsDetail: ReturnsDetailState;
  returnsListV2: ReturnsListStateV2;
  returnsSeller: ReturnsSellerState;
  returnsOnboard: ReturnsOnboardState;
  returnsRmt: ReturnsRmtState;
  returnsInspectionConfig: ReturnsInspectionConfigState;
  settings: SettingsState;
  inbound: InboundState;
  inboundList: InboundListState;
  productCompliance: ProductComplianceState;
  productChooser: ProductChooserState;
  user: UserReducerState; // Inconsistent name to avoid collision with 'common/user/UserState'
  admin: AdminState;
  costEstimatorModal: CostEstimatorModalState;
  tags: FastTagsState;
  fastTagFlows: FastTagFlowsState;
  shopify: ShopifyState;
  media: MediaState;
  wish: WishState;
  storageInboundDetail: StorageInboundDetailState;
  storageInboundCreate: StorageInboundCreateState;
  transferShipmentDetail: TransferShipmentDetailState;
  transferCreate: TransferCreateState;
  fbaV3CreateState: FbaV3CreateState;
  freightQuote: FreightQuoteState;
  wholesaleList: WholesaleListState;
  wholesaleOrderDetail: WholesaleOrderDetailState;
  fbaIntegration: FbaIntegrationState;
  freight: FreightState;
  splitIo: ISplitState;
  createShipment: CreateShipmentState;
  smbShipment: SmbShipmentState;
  flexportUser: FlexportUserState;
}

export const initialState: RootState = {
  billing: billingInitialState,
  blade: bladeInitialState,
  channels: salesChannelsInitialState,
  cognito: cognitoInitialState,
  deliverr: deliverrInitialState,
  fastTagFlows: fastTagFlowsInitialState,
  google: googleInitialState,
  inbound: inboundInitialState,
  inboundList: inboundListInitialState,
  productCompliance: productComplianceInitialState,
  productChooser: productChooserInitialState,
  inventoryList: inventoryListInitialState,
  inventoryDetail: inventoryDetailInitialState,
  loading: loadingInitialState,
  media: mediaInitialState,
  modal: modalInitialState,
  nonCompliance: nonComplianceInitialState,
  nonComplianceList: nonComplianceListInitialState,
  notifications: notificationsInitialState,
  onboarding: onboardingInitialState,
  orderDetail: orderDetailInitialState,
  orderListV2: orderListInitialState,
  orderNew: newOrderInitialState,
  orderReturns: orderReturnsListInitialState,
  pagination: paginationInitialState,
  parcelDetails: parcelDetailInitialState,
  parcelList: parcelListInitialState,
  prepCreate: prepStoreInitialState,
  prepList: prepListInitialState,
  prepDetails: prepDetailsInitialState,
  progress: navBarProgressInitialState,
  replenishmentOrderDetailsState: replenishmentOrderDetailsInitialState,
  replenishmentNonComplianceState: replenishmentNonComplianceInitialState,
  replenishmentList: replenishmentListInitialState,
  reports: reportsInitialState,
  returnsCreate: returnsCreateInitialState,
  returnsLabel: returnsLabelInitialState,
  returnsSeller: returnsSellerInitialState,
  returnsDetail: returnsDetailInitialState,
  returnsListV2: returnsListInitialStateV2,
  returnsOnboard: returnsOnboardInitialState,
  returnsRmt: returnsRmtInitalState,
  returnsInspectionConfig: returnsInspectionConfigInitialState,
  settings: initialSettingsData,
  tags: fastTagsInitialState,
  user: userInitialState,
  organization: organizationInitialState,
  admin: adminInitialState,
  costEstimatorModal: costEstimatorInitialState,
  intl: {
    defaultLocale: "en-us",
    locale: navigator.language,
    messages,
  },
  shopify: shopifyInitialState,
  wish: wishInitialState,
  storageInboundDetail: storageInboundDetailInitialState,
  storageInboundCreate: storageInboundCreateInitialState,
  transferShipmentDetail: transferShipmentInitialState,
  transferCreate: transferCreateInitialState,
  freightQuote: freightQuoteInitialState,
  wholesaleList: wholesaleListInitialState,
  wholesaleOrderDetail: wholesaleOrderDetailInitialState,
  fbaIntegration: fbaIntegrationInitialState,
  freight: freightInitialState,
  splitIo: SPLIT_INITIAL_STATE,
  createShipment: createShipmentInitialState,
  smbShipment: smbShipmentInitialState,
  flexportUser: flexportUserInitialState,
  fbaV3CreateState: fbaV3CreateInitialState,
};

export const rootReducer = combineReducers<RootState>({
  billing: billingReducer,
  blade: bladeReducer,
  channels: salesChannelsReducer,
  cognito: cognitoReducer,
  deliverr: deliverrReducer,
  errors: errorsReducer,
  fastTagFlows: fastTagFlowsReducer,
  google: googleReducer,
  inbound: inboundReducer,
  inboundList: inboundListReducer,
  productCompliance: productComplianceReducer,
  productChooser: productChooserReducer,
  intl: intlReducer,
  inventoryList: inventoryListReducer,
  inventoryDetail: inventoryDetailReducer,
  loading: loadingReducer,
  media: mediaReducer,
  modal: modalReducer,
  nonCompliance: nonComplianceReducer,
  nonComplianceList: nonComplianceListReducer,
  notifications: notificationsReducer,
  onboarding: onboardingReducer,
  orderDetail: orderDetailReducer,
  orderListV2: orderListReducer,
  orderNew: newOrderReducer,
  orderReturns: orderReturnsListReducer,
  pagination: paginationReducer,
  parcelDetails: parcelDetailsReducer,
  parcelList: parcelListReducer,
  prepCreate: prepStoreReducer,
  prepList: prepListReducer,
  prepDetails: prepDetailsReducer,
  progress: navBarProgressReducer,
  replenishmentList: replenishmentListReducer,
  replenishmentOrderDetailsState: replenishmentOrderDetailsReducer,
  replenishmentNonComplianceState: replenishmentNonComplianceReducer,
  reports: reportsReducer,
  returnsCreate: returnsCreateReducer,
  returnsLabel: returnsLabelReducer,
  returnsDetail: returnsDetailReducer,
  returnsListV2: returnsListReducerV2,
  returnsSeller: returnsSellerReducer,
  returnsOnboard: returnsOnboardReducer,
  returnsRmt: returnsRmtReducer,
  returnsInspectionConfig: returnsInspectionConfigReducer,
  settings: settingsReducer,
  tags: fastTagsReducer,
  user: userReducer,
  organization: organizationReducer,
  admin: adminReducer,
  costEstimatorModal: costEstimatorReducer,
  shopify: shopifyReducer,
  wish: wishReducer,
  storageInboundDetail: storageInboundDetailReducer,
  storageInboundCreate: storageInboundCreateReducer,
  transferShipmentDetail: transferShipmentDetailReducer,
  transferCreate: transferCreateReducer,
  freightQuote: freightQuoteReducers,
  wholesaleList: wholesaleListReducer,
  wholesaleOrderDetail: wholesaleOrderDetailReducer,
  fbaIntegration: fbaIntegrationReducer,
  freight: freightReducer,
  splitIo: splitReducer,
  createShipment: createShipmentReducer,
  smbShipment: smbShipmentReducer,
  flexportUser: flexportUserReducer,
  fbaV3CreateState: fbaV3CreateStateReducer,
});
